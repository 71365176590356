import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { Support } from "../../components/apiAccess/thread/searchSupporterDataAccess";
import css from "./comp_OpenItems.module.css";
import { ModeSecond } from "../../components/commonTypes/types";
import { Confirm } from "../../components/dialogConfirm/types";

const MyAssistOpenItems = (props: any) => {
  const { list, setModeSecond, setConfirm } = props;

  // タイトルがタップされたとき
  const handlerOnClickTitle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const mode: ModeSecond = {
      code: "DETAIL",
      selectedId: Number(e.currentTarget.dataset.recordid),
      fromCode: "LIST",
      fromSelectedId: Number(e.currentTarget.dataset.recordid),
      option: e.currentTarget.dataset.numberpeoples,
    };
    setModeSecond(mode);
  };

  // 「申し込んだありが隊」がタップされたとき
  const handlerOnClickOffer = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.recordid?.split("_")?.[0];
    const mode: ModeSecond = {
      code: "OFFER",
      selectedId: Number(selectedId),
      fromCode: "LIST",
      fromSelectedId: Number(selectedId),
    };
    setModeSecond(mode);
  };

  // 「登録を取りやめる」がタップされたとき
  const handlerOnClickCancel = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const cnf: Confirm = {
      isVisible: true,
      type: "REGIST_CANCEL",
      answer: "UNDONE",
      recordId: Number(e.currentTarget.dataset.recordid),
    };
    setConfirm(cnf);
  };

  return (
    <>
      <div className={css.boardPanel_container}>
        {list &&
          list.result &&
          list.result.length > 0 &&
          list.result[0].title !== "" &&
          list.result.map((data: Support) => {
            return (
              <div className={css.boardPanel_wrap} key={data.id}>
                <div
                  className={`${css.boardPanel_title} ${css.list} ${css.clickable}`}
                  onClick={handlerOnClickTitle}
                  data-recordid={data.id}
                  data-numberpeoples={data.number_peoples}
                >
                  {data.title}
                  <div className={css.gfs_arrow_position}>
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </div>
                </div>
                <div className={css.boardPanel_2column}>
                  <span className={css["boardPanel_date-label"]}>日時</span>
                  <div className={css.boardPanel_date}>
                    <span>{data.supporter_date}</span>
                    <div>
                      <span>{data.begin_time}</span>
                      <span>〜</span>
                      <span>{data.end_time}</span>
                    </div>
                  </div>
                </div>
                <hr className={css.boardPanel_divider} />
                <div
                  className={`${css.boardPanel_item} ${css.clickable}`}
                  onClick={handlerOnClickOffer}
                  data-recordid={data.id}
                >
                  <div className={css.boardPanel_status}>
                    <div className={css["boardPanel_status-msg"]}>
                      申し込んだありが隊
                    </div>
                    <div
                      className={`${
                        data.number_peoples > 0
                          ? css["boardPanel_status-numtrue"]
                          : css["boardPanel_status-numzero"]
                      }`}
                    >
                      {data.number_peoples}
                      <span className={css.text_12}>名</span>
                    </div>
                  </div>
                </div>
                <hr className={css.boardPanel_divider} />
                <div
                  className={`${css.boardPanel_item} ${css.clickable}`}
                  onClick={handlerOnClickCancel}
                  data-recordid={data.id}
                >
                  <div className={css["boardPanel_action-cancel"]}>
                    登録を取りやめる
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MyAssistOpenItems;
