import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { UserInfoProvider } from "./tsx/views/components/providers/userInfoProvider";

import "./style.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <UserInfoProvider>
      <App />
    </UserInfoProvider>
  </React.StrictMode>
);
