const GetApiUrl = () => {

    let hostname: string = window.location.hostname;

    if (process.env.NODE_ENV === 'development') {
        hostname = process.env.REACT_APP_DEV_HOST!;
    }

    const api_url =
        process.env.REACT_APP_URL_SCHEME
        + "://" + hostname
        + "/" + process.env.REACT_APP_API_DIR
        + "/" + process.env.REACT_APP_API_VER;

    return api_url;
}

export default GetApiUrl;