import React, { Fragment, memo, useContext } from "react";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import css from "./MyLifeLog.module.css";

const LifeLog = memo((props: any) => {
  const { join, indexDate } = props;

  // ログインユーザ情報
  const { userInfo, setUserInfo } = useContext(UserInfoContext);

  // console.dir(join);
  return (
    <>
      {join &&
        join.length > 0 &&
        join.map((dt: any, indexJoin: number) => {
          return (
            <Fragment key={String(indexDate) + String(indexJoin)}>
              <>
                {indexJoin > 0 && <hr className={css.lifelog_item_hr} />}
                <div className={css.lifelog_item}>
                  <dl>
                    <dt>
                      <span
                        className={`${css.log_tag} ${
                          dt.event_id === "2"
                            ? css.tag_bg_eye
                            : css.tag_bg_event
                        }`}
                      >
                        {dt.event_id === "2" ? "助け合い" : "イベント"}
                      </span>
                      {dt.event_id !== "2" && dt.event_name}
                    </dt>
                    <dd>
                      {userInfo?.id === dt.from_account_id
                        ? dt.to_nickname
                        : dt.from_nickname}
                      <span>{dt.description}</span>
                    </dd>
                  </dl>
                </div>
              </>
            </Fragment>
          );
        })}
    </>
  );
});

export default LifeLog;
