import React, { useContext, useEffect, useState } from "react";
import OpenList from "./comp_OpenList";

import { useLocation } from "react-router-dom";
import { initialConfirm } from "../../components/dialogConfirm/initials";
import type { ModeFirst, ModeSecond } from "../../components/commonTypes/types";
import type { Confirm } from "../../components/dialogConfirm/types";
import MatchList from "./comp_MatchList";
import CloseList from "./comp_CloseList";

import DialogConfirm from "../../components/dialogConfirm/DialogConfirm";
import Assist from "../../components/assistDetail/Assist";
import {
  ConditionCancelThanks,
  useCancelThanks,
} from "../../components/apiAccess/thread/useCancelThanks";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import css from "./OthersAssistMe.module.css";

const OthersAssistMe = () => {
  console.log("called OthersAssistMe ");

  const location = useLocation();

  const [modeFirst, setModeFirst] = useState<ModeFirst>(
    location.state.modeFirst
  );
  const [modeSecond, setModeSecond] = useState<ModeSecond>({
    code: "LIST",
    selectedId: 0,
    fromCode: "LIST",
    fromSelectedId: 0,
  });
  const [confirm, setConfirm] = useState<Confirm>(initialConfirm);
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const {
    resultCancelThanksRegist,
    sendCancelThanksRegist,
    isLoadingCancelThanksRegist,
    isErrorCancelThanksRegist,
  } = useCancelThanks();

  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // ありが隊申し込みのキャンセル結果を受けたとき
  useEffect(() => {
    if (resultCancelThanksRegist === undefined) return;
    if (resultCancelThanksRegist.result_code === "0") {
      setConfirm(initialConfirm);
      console.log("ありが隊申し込みキャンセルの結果");
      console.dir(resultCancelThanksRegist.message);

      if (modeSecond.code === "DETAIL") {
        setModeSecond((prev) => ({ ...prev, code: "LIST" }));
      } else if (modeSecond.code === "LIST") {
        setForceUpdate((prev) => prev + 1);
      }
    } else if (resultCancelThanksRegist.result_code === "1") {
      console.log(resultCancelThanksRegist.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultCancelThanksRegist.message);
    }
  }, [resultCancelThanksRegist]);

  // ダイアログの「YES/NO」を受けたとき
  useEffect(() => {
    if (confirm.answer === "YES") {
      if (modeFirst.code === "OPEN") {
        const condition: ConditionCancelThanks = {
          id: confirm.recordId,
          user_id: userInfo?.id!,
        };
        sendCancelThanksRegist(condition);
      }
    } else if (confirm.answer === "NO") {
      setConfirm(initialConfirm);
    }
  }, [confirm]);

  return (
    <>
      <div className={css.coopbbs_back}>
        {modeFirst.code === "OPEN" && modeSecond.code === "LIST" && (
          <OpenList
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
            forceUpdate={forceUpdate}
            setConfirm={setConfirm}
          />
        )}
        {modeFirst.code === "MATCH" && modeSecond.code === "LIST" && (
          <MatchList
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
          />
        )}
        {modeFirst.code === "CLOSE" && modeSecond.code === "LIST" && (
          <CloseList
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
          />
        )}
        {modeSecond.code === "DETAIL" && (
          <Assist
            userId={userInfo?.id}
            selectedId={modeSecond.selectedId}
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
            setConfirm={setConfirm}
          />
        )}
      </div>
      {confirm.isVisible && (
        <DialogConfirm confirm={confirm} setConfirm={setConfirm} />
      )}
    </>
  );
};

export default OthersAssistMe;
