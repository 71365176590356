import React, { useContext } from "react";
import { UserInfoContext } from "../../components/providers/userInfoProvider";

function Component_PersonalName() {
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);
  return <div>{userInfo?.nickname}</div>;
}

export default Component_PersonalName;
