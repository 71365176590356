import { createContext, useState } from "react";
import { UserInfoContextType, UserInfoType } from "./types";

export const UserInfoContext = createContext<UserInfoContextType>({
  userInfo: null,
  setUserInfo: (userInfo) => {},
});

export const UserInfoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};
