import { Loader } from "@googlemaps/js-api-loader";

import css from "./DestRoute.module.css";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  PlacePointType,
  PlaceType,
  RouteType,
  SelectedPointType,
} from "./types";
import Map from "./Map";
import Search, { reverseGeocoding } from "./Search";
import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;
const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"],
});

const ZOOM_DEFAULT: number = 13;
const ZOOM_TARGET: number = 19;

const initRoute = {
  start: {
    name: "",
    addr: "",
    lat: 0,
    lng: 0,
    placeId: "",
    searchWord: "",
  },
  arrival: {
    name: "",
    addr: "",
    lat: 0,
    lng: 0,
    placeId: "",
    searchWord: "",
  },
};

interface DestRouteProps {
  supporterData: SupporterSetData;
  setSupporterData: Dispatch<SetStateAction<SupporterSetData>>;
  setInputMode: Dispatch<SetStateAction<string>>;
}

// 行き先ルート・目的地
const DestRoute = memo((props: DestRouteProps) => {
  const { supporterData, setSupporterData, setInputMode } = props;
  // Google Map API のスクリプトの読み込み判定値
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  // Google Map API のサービス保持
  const [service, setService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);

  const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = useState<number | null | undefined>(ZOOM_DEFAULT);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 36.439055,
    lng: 139.11599,
  });

  //住所検索に設定する値
  const [value, setValue] = useState<string>("");
  //インプットタグに設定する値
  const [valueDeparture, setValueDeparture] = useState<string>("");
  const [valueArrival, setValueArrival] = useState<string>("");

  //検索文字列
  const [searchWord, setSearchWord] = useState<string>("");
  // 候補検索からの選択場所を保持
  const [selectedPlace, setSelectedPlace] = useState<PlaceType>();
  // 地図にマーカーを置いて良い場合にtrue
  const [isMarker, setIsMarker] = useState<boolean>(false);

  // 出発／到着／経由の位置情報を保持
  const [route, setRoute] = useState<RouteType>(initRoute);
  // 最後にアクティブになったインプットタグで 出発／到着／経由モードを保持
  const [selectedPoint, setSelectedPoint] = useState<SelectedPointType>();
  // 編集モードの住所再検索
  const [isResearch, setIsResearch] = useState<boolean>(false);

  // 出発のDOM参照
  const refDeparture = useRef<HTMLInputElement>(null!);
  // 到着のDOM参照
  const refArrival = useRef<HTMLInputElement>(null!);

  // 初回設定
  useEffect(() => {
    loader
      .load()
      .then(() => {
        setService(new google.maps.places.AutocompleteService());
        setIsLoaded(true);

        console.log("script is loaded.");
      })
      .catch((e) => {
        console.dir(e);
      });

    if (
      supporterData.route_from !== undefined &&
      supporterData.route_from !== null &&
      supporterData.route_from.length > 0
    ) {
      // refDeparture.current.value = supporterData.route_from;
      let tempRoute = route;
      tempRoute.start.searchWord = supporterData.route_from;
      setSelectedPoint({
        point: "start",
      });
      setRoute(tempRoute);
      setSearchWord(supporterData.route_from);
      setValue(supporterData.route_from);
      setIsResearch(true);
    }
    // if (
    //   supporterData.route_to !== undefined &&
    //   supporterData.route_to !== null &&
    //   supporterData.route_to.length > 0
    // ) {
    //   refDeparture.current.value = supporterData.route_to;
    // }
  }, []);

  // 検索文字列が変更されたとき
  useEffect(() => {
    console.log(`searchWord: ${searchWord}`);
  }, [searchWord]);

  // 出発／到着／経由モードが変更されたとき
  useEffect(() => {
    console.log(`selectedPoint:${selectedPoint?.point}`);
  }, [selectedPoint]);

  // 地図の中心値が変わったとき
  useEffect(() => {
    if (center && isMarker) {
      reverseGeocoding({ center, setValue });
    }
  }, [center]);

  useEffect(() => {
    if (value === undefined || value === null) return;

    if (selectedPoint?.point === "start") {
      setValueDeparture(value);
    } else {
      setValueArrival(value);
    }
  }, [value]);

  // 候補検索から場所を選んだとき
  useEffect(() => {
    if (selectedPlace && selectedPlace.name && selectedPoint) {
      if (selectedPoint.point === "start") {
        setRoute((prev) => ({ ...prev, start: selectedPlace }));
        setValueDeparture(selectedPlace.addr);
      } else if (selectedPoint.point === "arrival") {
        setRoute((prev) => ({ ...prev, arrival: selectedPlace }));
        setValueArrival(selectedPlace.addr);
      }

      if (zoom! < ZOOM_TARGET) {
        setZoom(ZOOM_TARGET);
      }
      setIsMarker(true);

      console.log("selectedPlace is changed.");
      console.dir(selectedPlace);
      const retreiveCenter: google.maps.LatLngLiteral = {
        lat: selectedPlace.lat!,
        lng: selectedPlace.lng!,
      };
      setCenter(retreiveCenter);
    } else {
      setIsMarker(false);
    }
  }, [selectedPlace]);

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    console.log("onIdle");

    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());

    if (
      supporterData.route_from !== undefined &&
      supporterData.route_from !== null &&
      supporterData.route_from.length > 0
    ) {
      refDeparture.current.value = supporterData.route_from;
    }
  };

  // 出発インプットタグへの入力変化時
  const handleInputChangeDeparture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(
      `handleInputChangeDeparture id:${e.target.id}, value:${e.target.value}`
    );
    e.stopPropagation();

    const id: string[] = e.target.id.split("-");
    let tempRoute = route;
    if (id[0] === "start") {
      tempRoute.start.searchWord = e.target.value;
    } else if (id[0] === "arrival") {
      tempRoute.arrival.searchWord = e.target.value;
    }
    setRoute(tempRoute);
    setSearchWord(e.target.value);

    setValueDeparture(e.target.value);
  };

  const handleInputChangeArrival = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(
      `handleInputChangeArrival id:${e.target.id}, value:${e.target.value}`
    );
    e.stopPropagation();

    let tempRoute = route;
    tempRoute.arrival.searchWord = e.target.value;

    setRoute(tempRoute);
    setSearchWord(e.target.value);

    setValueArrival(e.target.value);
  };

  // 出発インプットタグがアクティブになったとき
  const handleInputFocus = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const ids = e.target.id.split("-");
      setSelectedPoint({
        // point: ids[0] as PlacePointType,
        point: "start" as PlacePointType,
      });
    },
    []
  );

  // 到着インプットタグがアクティブになったとき
  const handleInputFocusArrival = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // const ids = e.target.id.split("-");
      setSelectedPoint({
        point: "arrival" as PlacePointType,
      });
    },
    []
  );

  const handlerOnClickNext = (e: React.MouseEvent<HTMLDivElement>) => {
    setSupporterData((prev: SupporterSetData) => ({
      ...prev,
      route_from: refDeparture.current.value,
      route_to: refArrival.current.value,
    }));
    setInputMode("助け隊登録");
  };

  return (
    <>
      <article className={`${css.content_all} ${css.event_back}`}>
        <div className={`${css.content_inner} ${css.article_margin}`}>
          {/* <div className={`${css.pageTitle} ${css["pageTitle_color-regist"]}`}>
          <a href="" className={css.backArrow}>
            <img src="../../../../img/backArrow.svg" width="23" alt="" />
          </a>
          行き先・ルート
        </div> */}
          <div className={css.registInputboxWrap}>
            <div className={css["registRoutenaviMap_input-wrap"]}>
              <div className={css["registTextbox_title-wrap"]}>
                <div className={css.requireText}>
                  店名・名所・住所などを入力
                </div>
              </div>
              <div
                id="pac-container-departure"
                className={`${css.registRoutenaviMap_input} ${css.departure}`}
              >
                <input
                  id="pac-input-departure"
                  type="text"
                  placeholder="場所を入力"
                  onFocus={handleInputFocus}
                  value={valueDeparture}
                  onChange={handleInputChangeDeparture}
                  ref={refDeparture}
                />
              </div>
              <div
                id="pac-container-arrival"
                className={`${css.registRoutenaviMap_input} ${css.arrival}`}
              >
                <input
                  id="pac-input-arrival"
                  type="text"
                  onFocus={handleInputFocusArrival}
                  value={valueArrival}
                  onChange={handleInputChangeArrival}
                  ref={refArrival}
                />
              </div>
            </div>
            <div id="map" className={css.registRoutenaviMap}>
              <Map
                center={center}
                onClick={onClick}
                onIdle={onIdle}
                zoom={zoom}
                isLoaded={isLoaded}
                isMarker={isMarker}
                style={{ height: "100%", width: "100%" }}
              >
                {/* {clicks.map((latLng, i) => (
            <Marker key={i} position={latLng} />
          ))} */}
              </Map>
            </div>
          </div>
          <div className={css.mg_t_50}>
            <div className={`${css.coopbbsButton_wrap} ${css.column}`}>
              <div
                className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
                onClick={handlerOnClickNext}
              >
                つぎへ
              </div>
            </div>
          </div>
        </div>
      </article>
      <div className={css.searchResult}>
        <Search
          searchWord={searchWord}
          service={service}
          setSelectedPlace={setSelectedPlace}
          apiKey={GOOGLE_MAPS_API_KEY}
          isResearch={isResearch}
          setIsResearch={setIsResearch}
        />
      </div>
    </>
  );
});

export default DestRoute;
