import axios from "axios";
import { useState } from "react";
import GetApiUrl from "../getApiUrl";

// チャット送信APIアクセス
export const useSendMessage = () => {
    const [resultSendMessage, setResultSendMessage] = useState<ResultData | undefined>();
    const [isLoadingSendMessage, setIsLoadingSendMessage] = useState<boolean>(false);
    const [isErrorSendMessage, setIsErrorSendMessage] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const sendMessage = async (props: ConditionSendMessage) => {
        console.dir(props);
        setIsLoadingSendMessage(true);
        setIsErrorSendMessage(false);

        assistSvr.post("/chat/sendMessage", {
            ...props
        }).then((response) => {
            console.dir(response.data);
            setResultSendMessage(response.data);
        }).catch((e) => {
            setIsErrorSendMessage(true);
            process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
        }).finally(() => {
            setIsLoadingSendMessage(false);
        })
    };

    return { resultSendMessage, sendMessage, isLoadingSendMessage, isErrorSendMessage };
};

// チャット送信API
export type ConditionSendMessage = {
    user_id: string;
    id: number;
    send_datetime: string;
    send_message: string;
}

export type ResultData = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
};
