export const DummyData_smpl = [
  {
    smpl_title: "デジタル系",
    title: "iPhone・iPadの使い方や設定を教えます。",
    detail:
      "こんにちは。iPhoneやiPadの初期設定や、機種変更の際のデータ移行方法、お子様でも安心して利用できるようにするペアレンタルコントロールの設定などを助け隊です！。駅前のTSUTAYAカフェでコーヒーでも飲みながらお伝えできればと思います。",
    area_sentense:
      "TSUTAYA BOOKSTORE アクエル前橋、スターバックスだと前橋元総社店か、けやきウォーク前橋店などのカフェ。",
    area_choice: "",
    date: "2023年04月26日(金)",
    time_from: "10:45",
    time_to: "17:30",
    category: "デジタル",
    alliliation: "個人",
    corporatename: "",
    corporatename_kana: "",
    root_from: "",
    waypoint: "",
    root_to: "",
  },
  {
    smpl_title: "スポーツ系",
    title: "自転車の乗り方伝授します！",
    detail:
      "初めまして。保育園、幼稚園のお子様から小学生までのお子様に、自転車に乗れるようになるお手伝いをできればと思っています。\n初めは自転車を支えてあげたり、押しながら走ったりと、教える方も割と体力が必要ですよね。私はヤンチャな男の子2人のパパで、少し前に2人とも自転車に乗れる様になりましたが、結構な重労働だと思い、助けが必要な家族がいるのではないかな？と思い登録してみました。\n手足のプロテクターはありますので、お貸し致します。\n自転車とヘルメットはご用意ください。\nでは、お気軽にご連絡ください。",
    area_sentense: "車での移動なので、前橋市内でしたらどこでも伺えます。",
    area_choice: "",
    date: "2023年04年26日（金）",
    time_from: "10:45",
    time_to: "17:30",
    category: "スポーツ",
    alliliation: "個人",
    corporatename: "",
    corporatename_kana: "",
    root_from: "",
    waypoint: "",
    root_to: "",
  },
  {
    smpl_title: "ペット系",
    title: "里親を探します。",
    detail:
      "猫ちゃん、ワンちゃんが大好きなワンニャンランドです。\n猫ちゃんを拾ったけどどうしたらいいかわからない、ワンちゃんが出産したけど、貰い手が見つからず困っている。年寄りになった猫ちゃんやワンちゃんの面倒を見るのが思っていた以上に大変、引越し先がペットNGな場所だった、など色々な事情があると思います。\nそんな時はご連絡ください。新しい里親を責任を持って探します。一時預かりもできますので、ご相談ください。",
    area_sentense:
      "TSUTAYA BOOKSTORE アクエル前橋、スターバックスだと前橋元総社店か、けやきウォーク前橋店などのカフェ。",
    area_choice: "",
    date: "2023年04月26日（金）",
    time_from: "10:45",
    time_to: "17:30",
    category: "ペット",
    alliliation: "法人",
    corporatename: "ワンニャン株式会社",
    corporatename_kana: "わんにゃんかぶしきがいしゃ",
    root_from: "",
    waypoint: "",
    root_to: "",
  },
  {
    smpl_title: "物の譲渡系",
    title: "雑誌PENのデザイン特集５冊を譲ります。",
    detail:
      "私はPENという雑誌が好きで特に文房具特集などの回はワクワクしながら読む方です。過去に購入したデザイン関係のPENはなかなか捨てられず取ってあるのですが、もし欲しい方がいましたら譲りたいな、と思い登録しました。状態は良いと思いますが、5年前の雑誌なので、もしかしたらノリが剥がれているなど劣化があるかもしれません。\n以下詳細です。\n\n20XX年X月X日\nクリエイティブ・ディレクター36人の仕掛け\n\n20XX年X月X日\nグラフィックの天才達\n\n20XX年X月X日\nヒットの秘密\n\n20XX年X月X日\nやっぱり楽しい文字のデザイン\n\n20XX年X月X日\n江戸デザイン学",
    area_sentense: "譲渡の仕方は手渡しか、郵送を考えています。",
    area_choice: "",
    date: "2023年04月26日（金）",
    time_from: "10:45",
    time_to: "17:30",
    category: "譲ります",
    alliliation: "個人",
    corporatename: "",
    corporatename_kana: "",
    root_from: "",
    waypoint: "",
    root_to: "",
  },
  {
    smpl_title: "コミュニケーション",
    title: "1日10分お話しできます。",
    detail:
      "初めまして。私は以前塞ぎ込みがちだったのですが、ある人のお陰で今は人と会話をするのがとても好きになり、心も穏やかになった気がします。\nこの度私も誰かと10分でもお話をすることで、その人の気持ちが少しでも晴れたらいいなと思い登録してみました。お話しした内容はもちろん口外しませんので安心してください。\nよろしくお願いします。",
    area_sentense: "前橋市全般で活動しています。",
    area_choice: "",
    date: "2023年○○月○○日(水)",
    time_from: "10:45",
    time_to: "17:30",
    category: "コミュニケーション",
    alliliation: "個人",
    corporatename: "",
    corporatename_kana: "",
    root_from: "",
    waypoint: "",
    root_to: "",
  },
];
