import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useGetTown = () => {
    const [resultGetTown, setResultGetTown] = useState<ResultGetTown>();
    const [isLoadingTown, setIsLoadingTown] = useState<boolean>(false);
    const [isErrorGetTown, setIsErrorGetTown] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const getTown = useCallback(async () => {
        setIsLoadingTown(true);
        setIsErrorGetTown(false);

        assistSvr
            .get(`/master/getTown`)
            .then((response) => {
                setResultGetTown(response.data);
            })
            .catch((e) => {
                setIsErrorGetTown(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsLoadingTown(false);
            });
    }, []);
    return { resultGetTown, getTown, isLoadingTown, isErrorGetTown };
};

export type Town = {
    headchar: string;
    townname: string;
    townname_rubi: string;
};

export type ResultGetTown = {
    result_code: string;
    message: string;
    result: Town[];
}
