import React from "react";
import { useNavigate } from "react-router-dom";
import css from "./Tutorial.module.css";
import { ReactComponent as BackArrow } from "../../../../img/backArrow.svg";
import NextIcon from "../../../../img/iconClickable.svg";
import request2_1 from "../../../../img/tutorialImage_request2-1.jpg";
import support3_1 from "../../../../img/tutorialImage_support3-1.jpg";
import support3_2 from "../../../../img/tutorialImage_support3-2.jpg";
import support4_1 from "../../../../img/tutorialImage_support4-1.jpg";
import imageRequest from "../../../../img/tutorialSectionImage_request.svg";
import imageSupport from "../../../../img/tutorialSectionImage_support.svg";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Tutorial() {
  const navigate = useNavigate();
  const mainMenu = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
  };
  const assistRegist = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "assistRegist", {
      state: {
        modeRegist: "REGIST",
      },
    });
  };
  const assistRegist_example = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "assistRegist", {
      state: {
        modeRegist: "SAMPLE",
      },
    });
  };
  const othersAssistList = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistList");
  };

  return (
    <>
      <div className={`${css.content_inner} ${css.article_margin}`}>
        <div className={`${css.pageTitle} ${css["pageTitle_color-undefined"]}`}>
          <div onClick={mainMenu} className={css.backArrow}>
            <BackArrow />
          </div>
          使い方
        </div>
        <div className={css.coopbbs_block_wrap}>
          <div className={css["tutorial_anchorButton-wrap"]}>
            <div>
              <a href="#support" className={css.tutorial_anchorButton}>
                人や街を助けたい方（助け隊）
              </a>
            </div>
            <div>
              <a href="#request" className={css.tutorial_anchorButton}>
                助けてもらいたい方（ありが隊）
              </a>
            </div>
          </div>
          <div className={css.tutorial_section}>
            <div
              className={`${css["tutorialBlock-wrap"]} ${css.support}`}
              id="support"
            >
              <div className={css.tutorialBlock_sectionImage}>
                <img src={imageSupport} width="120" height="120" alt="" />
              </div>
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>
                  1. 「助け隊」を登録する
                </div>
                <div className={css.gfs_tutorial_text}>
                  自分がお手伝いできること、人に教えてあげられる内容などをまずは登録してみましょう。
                </div>
                <div
                  className={`${css.boardPanel_title} ${css.tutorial} ${css.icon_regist}`}
                  onClick={assistRegist}
                >
                  助け隊を登録する
                  <div className={css.gfs_tutorial_click_icon}>
                    {/* <img src={NextIcon} width="20" height="20" alt="" /> */}
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </div>
                </div>
                <div
                  className={`${css.boardPanel_title} ${css.tutorial} ${css.icon_registEx}`}
                  onClick={assistRegist_example}
                >
                  登録例
                  <div className={css.gfs_tutorial_click_icon}>
                    {/* <img src={NextIcon} width="20" height="20" alt="" /> */}
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </div>
                </div>
                <p className={css.gfs_tutorial_text}>
                  登録された内容は「みんなの助け隊一覧」に表示され、めぶくIDを持っているユーザーが閲覧できるようになります。
                </p>
                <div
                  className={`${css.boardPanel_title} ${css.tutorial} ${css.icon_supporter}`}
                  onClick={othersAssistList}
                >
                  みんなの助け隊一覧
                  <div className={css.gfs_tutorial_click_icon}>
                    {/* <img src={NextIcon} width="20" height="20" alt="" /> */}
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </div>
                </div>
              </div>
              <hr className={`${css.divider} ${css.dashed}`} />
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>
                  2. 「ありが隊」からの連絡を待つ
                </div>
                <div className={css.gfs_tutorial_text}>
                  あなたの登録を見て、助けて欲しい！と思った方（ありが隊）から連絡が来るのを待ちます。
                  <div>[アドバイス]</div>
                  なかなか「ありが隊」から連絡が来ない場合は、内容と詳細や活動エリアなどを見直して修正してみましょう。
                </div>
              </div>
              <hr className={`${css.divider} ${css.dashed}`} />
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>
                  3. 助け合いを成立させる
                </div>
                <div className={css.gfs_tutorial_text}>
                  ありが隊から申し込みがきたら、「この人を助ける」または「今回はお断りする」を選びます。「この人を助ける」を選択した場合、助け合いが成立となり、このありが隊とチャットができるようになりますので、詳細を詰めてください。
                </div>
                <div className={css.tutorialImageList}>
                  <div className={css.tutorialImage}>
                    <img src={support3_1} alt="" />
                  </div>
                  <div className={css.tutorialImage}>
                    <img src={support3_2} alt="" />
                  </div>
                </div>
              </div>
              <hr className={`${css.divider} ${css.dashed}`} />
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>
                  4. 助け終わったらJoinする
                </div>
                <div className={css.gfs_tutorial_text}>
                  助け合いが終わったら、パスポートページのQRコードを「ありが隊」に読み込んでもらい「助け合いJoin」を獲得します。（QRの読み込みは必ず対面で行ってください。）
                </div>
                <div className={css.tutorialImageList}>
                  <div className={css.tutorialImage}>
                    <img src={support4_1} alt="" />
                  </div>
                </div>
                <p className={`${css.mg_t_20} ${css.gfs_tutorial_text}`}>
                  「助け合いJoin」の数に応じていい事があるかもしれません！
                </p>
              </div>
              <hr className={`${css.divider} ${css.dashed}`} />
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>5. 終了する</div>
                <div className={css.gfs_tutorial_text}>
                  「助け合いJoin」獲得後チャット画面より「終了する」をタップしありが隊とのやり取りを終了します。
                </div>
              </div>
            </div>
            <div
              className={`${css["tutorialBlock-wrap"]} ${css.request}`}
              id="request"
            >
              <div className={css.tutorialBlock_sectionImage}>
                <img src={imageRequest} width="120" height="120" alt="" />
              </div>
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>
                  1. 助けてくれる内容を見てみる
                </div>
                <div className={css.gfs_tutorial_text}>
                  「みんなの助け隊一覧」から助けてほしい内容があるか見てみましょう。
                  <div>
                    ※助けてくれる、助け隊は、めぶくIDを持っているユーザーです。
                  </div>
                </div>
                <div
                  className={`${css.boardPanel_title} ${css.tutorial} ${css.icon_supporter}`}
                  onClick={othersAssistList}
                >
                  みんなの助け隊一覧
                  <div className={css.gfs_tutorial_click_icon}>
                    {/* <img src={NextIcon} width="20" height="20" alt="" /> */}
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </div>
                </div>
              </div>
              <hr className={`${css.divider} ${css.dashed}`} />
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>
                  2. 「助け隊」に申し込む
                </div>
                <div className={css.gfs_tutorial_text}>
                  助けてほしい内容が見つかったら、お申し込みボタンを押してみましょう。
                  <div>[アドバイス]</div>
                  お申し込みをする際、助け隊への質問、助けてほしい理由、内容、場所、時間帯などをコメント欄に記入する良いでしょう。
                </div>
                <div className={css.tutorialImageList}>
                  <div className={css.tutorialImage}>
                    <img src={request2_1} alt="" />
                  </div>
                </div>
              </div>
              <hr className={`${css.divider} ${css.dashed}`} />
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>3. 詳細を詰める</div>
                <div className={css.gfs_tutorial_text}>
                  助け隊から連絡が来たら、チャットができるようになりますので、詳細を詰めてください。
                </div>
                <div className={css.tutorialImageList}>
                  <div className={css.tutorialImage}>
                    <img src={support3_2} alt="" />
                  </div>
                </div>
              </div>
              <hr className={`${css.divider} ${css.dashed}`} />
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>
                  4. 助けてもらったらJoinする
                </div>
                <div className={css.gfs_tutorial_text}>
                  助け合いが終わったら、パスポートページのQRコードを「ありが隊」に読み込んでもらい「助け合いJoin」を獲得します。（QRの読み込みは必ず対面で行ってください。）
                </div>
                <div className={css.tutorialImageList}>
                  <div className={css.tutorialImage}>
                    <img src={support4_1} alt="" />
                  </div>
                </div>
                <p className={`${css.mg_t_20} ${css.gfs_tutorial_text}`}>
                  「助け合いJoin」の数に応じていい事があるかもしれません！
                </p>
              </div>
              <hr className={`${css.divider} ${css.dashed}`} />
              <div className={css.tutorialBlock}>
                <div className={css.tutorialBlock_title}>5. 終了する</div>
                <div className={css.gfs_tutorial_text}>
                  「助け合いJoin」獲得後チャット画面より「終了する」をタップしありが隊とのやり取りを終了します。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tutorial;
