import { Confirm, ConfirmContents } from "./types";

export const initialConfirm: Confirm = {
    isVisible: false,
    type: "REFUSAL",
    answer: "UNDONE",
    recordId: 0,
};

export const initialContents: ConfirmContents = {
    id: 0,
    title: "",
    message: "",
    yes: "",
    no: "",
};