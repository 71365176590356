import React, { memo } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import css from "./comp_PartsActivityArea.module.css";
import { ReactComponent as IconClickable } from "../../../../img/iconClickable.svg";

// 活動エリア
const PartsActivityArea = memo((props: any) => {
  // 助け隊登録データ
  const { supporterData } = props;
  const { area_choice, area_sentense } = supporterData;

  if (area_choice.length > 0) {
    return (
      <Card>
        <Card.Header className={css.gfs_card_title}>
          <div className={`${css.registPanel_title} ${css.regist}`}>
            活動エリア<span className={css.require}>（必須）</span>
            <div className={css.gfs_right_pointer}>
              <IconClickable style={{ width: "20px", height: "15px" }} />
            </div>
          </div>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <div className={css.gfs_inputedText}>{area_choice.join("、")}</div>
            <div className={css.gfs_inputedText}>{area_sentense}</div>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    );
  } else {
    return (
      <div className={css.gfs_title_button}>
        <div className={`${css.registPanel_title} ${css.regist}`}>
          活動エリア<span className={css.require}>（必須）</span>
          <div className={css.gfs_right_pointer}>
            <IconClickable style={{ width: "20px", height: "15px" }} />
          </div>
        </div>
      </div>
    );
  }
});

export default PartsActivityArea;
