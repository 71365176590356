import axios from "axios";
import { useCallback, useState } from "react";
import GetApiUrl from "../getApiUrl";

// ありが隊リクエスト
export const useRequestThanks = () => {
    const [resultRequest, setResultRequest] = useState<any>();
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [isErrorRequest, setIsErrorRequest] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const sendRequest = useCallback(async (props: ConditionRequest) => {
        console.dir(props);
        setIsLoadingRequest(true);
        setIsErrorRequest(false);

        assistSvr.post("/thread/requestThanks", {
            ...props
        }).then((response) => {
            console.dir(response.data);
            setResultRequest(response.data);
        }).catch((e) => {
            setIsErrorRequest(true);
            process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
        }).finally(() => {
            setIsLoadingRequest(false);
        })
    }, []);

    return { resultRequest, sendRequest, isLoadingRequest, isErrorRequest };
};

// ありが隊申し込み（助け隊登録に対する共助リクエスト）
export type ConditionRequest = {
    user_id: string;  // ログインユーザのめぶくID
    id: number;       // 助け隊登録ID
    comment: string;  // ありが隊として申し込む際のコメント
}
