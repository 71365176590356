import type {
  ModeSecond,
  SupporterListWithVisible,
  SupporterWithVisible,
} from "../../components/commonTypes/types";
import css from "./comp_CloseItems.module.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconIntroduction from "../../../../img/icon_introduction.svg";

const OthersAssistMeCloseItems = (props: any) => {
  const {
    supporterList,
    setSupporterList,
    modeFirst,
    setModeFirst,
    modeSecond,
    setModeSecond,
  } = props;

  // タイトルがタップされたとき
  const handlerOnClickTitle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.recordid?.split("_")?.[0];
    const mode: ModeSecond = {
      code: "DETAIL",
      selectedId: Number(selectedId),
      fromCode: "LIST",
      fromSelectedId: Number(e.currentTarget.dataset.recordid),
    };
    setModeSecond(mode);
  };

  // 「助け隊の紹介文」がタップされたとき
  const handlerOnClickIntro = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const indexStr: string = e.currentTarget.dataset.index!;
    if (indexStr) {
      const idx = Number(indexStr);
      const copyList = supporterList.result.slice(
        0,
        supporterList.result.length
      );

      copyList.map((value: SupporterWithVisible, index: number) => {
        if (index === idx) {
          value.isVisibleIntro = !value.isVisibleIntro;
        }
      });
      setSupporterList((prev: SupporterListWithVisible) => ({
        ...prev,
        list: copyList,
      }));
    }
  };

  return (
    <div className={css.boardPanel_container}>
      <>
        {supporterList &&
          supporterList.result &&
          supporterList.result.length > 0 &&
          supporterList.result.map(
            (card: SupporterWithVisible, index: number) => {
              return (
                <div className={css.boardPanel_wrap} key={card.id}>
                  <>
                    <div
                      className={`${css.boardPanel_title} ${css.close} ${css.clickable}`}
                      onClick={handlerOnClickTitle}
                      data-recordid={card.id}
                    >
                      {card.title}
                      <div className={css.gfs_arrow_position}>
                        <FontAwesomeIcon icon={faAngleRight} size="xl" />
                      </div>
                    </div>
                    <div className={css.boardPanel_2column}>
                      <span className={css["boardPanel_date-label"]}>日時</span>
                      <div className={css.boardPanel_date}>
                        <span>{card.supporter_date}</span>
                        <span>
                          {card.begin_time}〜{card.end_time}
                        </span>
                      </div>
                    </div>
                    <hr className={css.boardPanel_divider} />
                    <div className={css.boardPanel_profileAction}>
                      <div className={css.boardPanel_nameProfile}>
                        {/* <div className={css.boardPanel_nameIcon}>
                      <img
                        src={"data:image/jpg;base64," + card.image}
                        width="50"
                        height="50"
                        alt=""
                      />
                    </div> */}
                        <span>{card.nickname}</span>
                      </div>
                      <div
                        className={`${css.boardPanel_accordionDetail} ${css.close}`}
                      >
                        <div
                          className={`${css.boardPanel_accordionLabel} ${css["js-accordion"]}`}
                          data-index={index}
                          onClick={handlerOnClickIntro}
                        >
                          <img src={iconIntroduction} width="30" alt="" />
                          <span>ありが隊の詳細</span>
                        </div>
                        <div
                          className={`${
                            css["boardPanel_accordionDetail-text"]
                          } ${card.isVisibleIntro ? css.open : ""}`}
                        >
                          {" "}
                          <div
                            className={css["boardPanel_accordionDetail-item"]}
                          >
                            <div>{card.introduction}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              );
            }
          )}
      </>
    </div>
  );
};

export default OthersAssistMeCloseItems;
