import React from "react";
import { useQRCode } from "next-qrcode";

function QrCodeMakerFaci(props: any) {
  const { code } = props;
  const { Image } = useQRCode();

  return (
    <Image
      text={code}
      options={{
        type: "image/jpeg",
        quality: 0.3,
        level: "M",
        margin: 4,
        scale: 1,
        width: 150,
        color: {
          dark: "#000000",
          light: "#FFFFFF",
        },
      }}
    />
  );
}

export default QrCodeMakerFaci;
