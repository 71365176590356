import React from "react";
import css from "./AssistFaq.module.css";
import { DummyData_faq } from "./DummyData_faq";

const faqData = DummyData_faq;

function AssistFaq() {
  return (
    <div>
      {faqData.map((faqSample: any, index: number) => {
        return (
          <div className={css.registFaq_wrap} key={index}>
            <div className={css.registFaq_title}>{faqSample.faq_title}</div>
            <div className={css.registFaq_text}>{faqSample.faq_text}</div>
          </div>
        );
      })}
    </div>
  );
}

export default AssistFaq;
