import { useEffect, useRef, useState } from "react";
import { initialContents } from "./initials";
import { AugumentType, Confirm, ConfirmAnswer, ConfirmContents } from "./types";

import css from "./DialogConfirm.module.css";

const DialogConfirm: React.FC<AugumentType> = ({ confirm, setConfirm }) => {
  const [contents, setContents] = useState<ConfirmContents>(initialContents);
  const refNo = useRef<HTMLButtonElement>(null!);
  const refYes = useRef<HTMLButtonElement>(null!);

  useEffect(() => {
    if (confirm.type === "REFUSAL") {
      setContents({
        id: 1,
        title: "今回はお断りしますか？",
        message:
          "お申し込みしたありが隊に対して、今回はやむを得ない事情でお断りしたことを連絡します。",
        yes: "お断りする",
        no: "キャンセル",
      });
    } else if (confirm.type === "REGIST_CANCEL") {
      setContents({
        id: 2,
        title: "この登録を取りやめますか？",
        message:
          "登録を取りやめた場合、お申し込みしたありが隊に対して、今回はやむを得ない事情でお断りしたことを連絡します。\n取りやめた登録は、「クローズ」タブからいつでも確認できます。",
        yes: "はい",
        no: "キャンセル",
      });
    } else if (confirm.type === "SUPPORT") {
      setContents({
        id: 3,
        title: "この「ありが隊」を助けますか？",
        message:
          "助ける場合、「成立」からこのありが隊とのチャットができるようになります。\nまた、他にお申し込みしたありが隊がいる場合は、今回はやむを得ない事情でお断りしたことを連絡します。",
        yes: "助ける",
        no: "キャンセル",
      });
    } else if (confirm.type === "CHAT_CLOSE_1") {
      setContents({
        id: 4,
        title: "クローズしますか？",
        message:
          "クローズすると、相手とのチャットが終了します。クローズ後も、登録内容や相手のプロフィールについては、「クローズ」タブからいつでも確認できます",
        yes: "クローズする",
        no: "キャンセル",
      });
    } else if (confirm.type === "CHAT_CLOSE_2") {
      setContents({
        id: 4,
        title: "終了しますか?",
        message:
          "クローズすると、相手とのチャットが終了します。\nクローズ後も、登録内容や相手のプロフィールについては、「クローズ」タブからいつでも確認できます",
        yes: "終了する",
        no: "キャンセル",
      });
    } else if (confirm.type === "OFFER_CANCEL") {
      setContents({
        id: 5,
        title: "このお申込みを取りやめますか？",
        message: "",
        yes: "はい",
        no: "キャンセル",
      });
    } else if (confirm.type === "REGIST_ID") {
      setContents({
        id: 6,
        title: "",
        message:
          "本サービスはめぶく仮IDではご利用頂くことができません。めぶくIDのご登録をお願い致します。",
        yes: "発行する",
        no: "後で",
      });
    } else if (confirm.type === "REQUEST") {
      setContents({
        id: 6,
        title: "お申込みをしますか？",
        message: "",
        yes: "はい",
        no: "キャンセル",
      });
    }
  }, []);

  const disableButtons = () => {
    refNo.current.disabled = true;
    refYes.current.disabled = true;
  };

  const handlerOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    disableButtons();

    const yesNoClass = e.currentTarget.dataset.yesnoclass;
    setConfirm((prev: Confirm) => ({
      ...prev,
      answer: yesNoClass as ConfirmAnswer,
    }));
  };

  return (
    <>
      {contents.id > 0 && (
        <section id="modalArea" className={css.modalArea}>
          <div className={css.modalWrapper}>
            <div className={css.modalContents}>
              <h5 className={css.dialog_title}>{contents.title}</h5>
              <p className={css.dialog_message}>{contents.message}</p>
              <div className={css.dialog_button}>
                <button
                  onClick={handlerOnClick}
                  ref={refNo}
                  data-yesnoclass="NO"
                >
                  {contents.no}
                </button>
                <button
                  onClick={handlerOnClick}
                  ref={refYes}
                  data-yesnoclass="YES"
                >
                  {contents.yes}
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DialogConfirm;
