import React, { memo } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import css from "./comp_PartsDateTime.module.css";
import { ReactComponent as IconClickable } from "../../../../img/iconClickable.svg";
import dayOfWeekStrJP from "../../components/dateUtil/Method_dayOfWeekStrJP";

// 日時
const PartsDateTime = memo((props: any) => {
  // 助け隊登録データ
  const { supporterData } = props;
  const { supporter_date, begin_time, end_time } = supporterData;

  if (supporter_date) {
    return (
      <Card>
        <Card.Header className={css.gfs_card_title}>
          <div className={`${css.registPanel_title} ${css.regist}`}>
            日時<span className={css.require}>（必須）</span>
            <div className={css.gfs_right_pointer}>
              <IconClickable style={{ width: "20px", height: "15px" }} />
            </div>
          </div>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item className={css.gfs_inputedText}>
            {supporter_date + "（" + dayOfWeekStrJP(supporter_date) + "）"}
            {begin_time}〜{end_time}
          </ListGroup.Item>
        </ListGroup>
      </Card>
    );
  } else {
    return (
      <div className={css.gfs_title_button}>
        <div className={`${css.registPanel_title} ${css.regist}`}>
          日時<span className={css.require}>（必須）</span>
          <div className={css.gfs_right_pointer}>
            <IconClickable style={{ width: "20px", height: "15px" }} />
          </div>
        </div>
      </div>
    );
  }
});

export default PartsDateTime;
