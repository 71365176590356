import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backArrow from "../../../../img/backArrow.svg";
import chatinputSend from "../../../../img/chatinput_send.svg";
import DialogConfirm from "../../components/dialogConfirm/DialogConfirm";
import { initialConfirm } from "../../components/dialogConfirm/initials";
import type {
  Confirm,
  ConfirmType,
} from "../../components/dialogConfirm/types";
import css from "./Chat.module.css";
import ChatMassage from "./comp_ChatMassage";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import type {
  ChatList,
  ConditionGetMessageAll,
} from "../../components/apiAccess/chat/getMessageAllDataAccess";
import {
  getMessageAll,
  initialChatList,
} from "../../components/apiAccess/chat/getMessageAllDataAccess";
import { useSendMessage } from "../../components/apiAccess/chat/useSendMessage";
import {
  ConditionComplete,
  useSupportComplete,
} from "../../components/apiAccess/thread/useSupportComplete";
import { ModeClass } from "../../components/commonTypes/types";

// チャット
const Chat = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  const {
    resultSendMessage,
    sendMessage,
    isLoadingSendMessage,
    isErrorSendMessage,
  } = useSendMessage();

  const [recordId] = useState<number>(location.state.recordId as number);
  const [myId] = useState<string>(location.state.myId as string);
  const [yourId] = useState<string>(location.state.yourId as string);
  const [yourNickName] = useState<string>(
    location.state.yourNickName as string
  );
  const [fromPage] = useState<string>(location.state.fromPage as string);
  const [chatList, setChatlist] = useState<ChatList>(initialChatList);
  const [confirm, setConfirm] = useState<Confirm>(initialConfirm);
  // 読み込むページの有無
  const refHasMore = useRef<boolean>(false);
  // データ取得中フラグ
  const refIsLoading = useRef<boolean>(false);

  const refChatHeader = useRef<HTMLDivElement>(null!);
  const refChatArea = useRef<HTMLDivElement>(null!);
  const refInput = useRef<HTMLTextAreaElement>(null!);
  const refScrollEnd = useRef<HTMLDivElement>(null!);
  const refBottom = useRef<HTMLDivElement>(null!);

  const refHeader = useRef<HTMLDivElement>(null!);
  const refChatBody = useRef<HTMLDivElement>(null!);
  const refFooter = useRef<HTMLDivElement>(null!);

  // 現在のページ
  const refPage = useRef<number>(1);
  // const [page, setPage] = useState<number>(1);
  const refScrollTarget = useRef<number>(0);

  const [timerId, setTimerId] = useState<number>(0);

  // 未入力メッセージ表示／非表示
  const [isVisivleNoWord, setIsVisibleNoWord] = useState<boolean>(false);
  // 文字数超過メッセージ表示／非表示
  const [isVisivleOverWord, setIsVisibleOverWord] = useState<boolean>(false);
  // 不適切単語メッセージ表示／非表示
  const [isVisivleSensitiveWord, setIsVisibleSensitiveWord] =
    useState<boolean>(false);
  // ウィンドウサイズを取得
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  const {
    resultSendComplete,
    sendComplete,
    isSendingComplete,
    isErrorSendComplete,
  } = useSupportComplete();

  // 初回レンダリング実行時
  useEffect(() => {
    console.log(`初回レンダリング実行 チャット myId=${myId} yourId=${yourId}`);

    const cnd: ConditionGetMessageAll = {
      user_id: myId,
      id: recordId,
      page: refPage.current,
    };
    fetch(cnd);

    calcHeight();

    document.addEventListener("input", adjInputHeight);

    const element = document.querySelector("#chatBody");
    element?.addEventListener("scroll", positionUp);

    return (): void => {
      document.removeEventListener("input", adjInputHeight);
      element?.removeEventListener("scroll", positionUp);
    };
  }, []);

  // チャット一覧エリアの高さを設定する
  const calcHeight = () => {
    const headerHeight: number = refHeader.current.clientHeight;
    console.log("headerHeight:" + headerHeight);

    const footerHeight: number = refFooter.current.clientHeight;
    console.log("footerHeight:" + footerHeight);

    // const windowHeight = window.innerHeight;
    console.log("windowHeight:" + windowHeight);

    const chatBodyHeight = windowHeight - headerHeight - footerHeight;
    console.log("chatBodyHeight:" + chatBodyHeight);

    refChatBody.current.style.height = `${chatBodyHeight}px`;
  };

  // 入力行に応じて入力エリアの高さを広げる
  const adjInputHeight = () => {
    refInput.current.style.height = "auto";
    refInput.current.style.height = `${refInput.current.scrollHeight}px`;

    console.log("clientHeight=" + refInput.current.clientHeight);
    console.log("scrollHeight = " + refInput.current.scrollHeight);
    console.log("offsetHeight = " + refInput.current.offsetHeight);
    console.log("rows = " + refInput.current.rows);

    setTimeout(calcHeight, 300);
    setTimeout(scrollToBottom, 300);
  };

  // 戻るがタップされたとき
  const handlerOnClickBackArrow = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    clearInterval(timerId);

    let cls: ModeClass = "ASSIST";
    if (fromPage.includes("othersAssistMe")) {
      cls = "REQUEST";
    }

    navigate(fromPage, {
      state: {
        modeFirst: {
          class: cls,
          code: "MATCH",
        },
        modeSecond: {
          code: "LIST",
        },
      },
    });
  };

  const formatDate = (date: Date) => {
    const y = date.getFullYear();
    const m = ("00" + (date.getMonth() + 1)).slice(-2);
    const d = ("00" + date.getDate()).slice(-2);
    const h = ("00" + date.getHours()).slice(-2);
    const mm = ("00" + date.getMinutes()).slice(-2);
    const s = ("00" + date.getSeconds()).slice(-2);

    const dt = y + "-" + m + "-" + d + " " + h + ":" + mm + ":" + s;

    return dt;
  };

  // 文字数チェック
  const checkInputStrings = (str: string) => {
    // 文字未入力
    if (str.length === 0 || str.trim().length === 0) {
      setIsVisibleOverWord(false);
      setIsVisibleNoWord(true);
      setTimeout(calcHeight, 100);
      setTimeout(scrollToBottom, 100);
      return false;
    } else {
      setIsVisibleNoWord(false);
    }

    // 文字数超過
    if (str.length > 500) {
      setIsVisibleOverWord(true);
      setTimeout(calcHeight, 100);
      setTimeout(scrollToBottom, 100);
      return false;
    } else {
      setIsVisibleOverWord(false);
    }

    return true;
  };

  // 送信がタップされたとき
  const handlerOnClickSend = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const message = refInput.current.value;
    console.log("message:" + message);

    setIsVisibleSensitiveWord(false);
    // 文字数チェック
    if (!checkInputStrings(message)) {
      return;
    }

    sendMessage({
      user_id: userInfo?.id!,
      id: recordId,
      send_datetime: formatDate(new Date()),
      send_message: message,
    });
  };

  // 送信結果を受信したとき
  useEffect(() => {
    if (resultSendMessage === undefined) return;
    if (resultSendMessage.result_code === "0") {
      console.log("チャットメッセージ送信成功");
      refInput.current.value = "";
      setTimeout(calcHeight, 100);
      setTimeout(scrollToBottom, 100);
      adjInputHeight();

      const cnd: ConditionGetMessageAll = {
        user_id: myId,
        id: recordId,
        page: 1,
      };
      fetch(cnd);
    } else if (resultSendMessage.result_code === "1") {
      console.log(resultSendMessage.result_code);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSendMessage.message);
    } else if (resultSendMessage.result_code === "2") {
      console.log(resultSendMessage.result_code);
      setIsVisibleSensitiveWord(true);
      setTimeout(calcHeight, 100);
      setTimeout(scrollToBottom, 100);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSendMessage.message);
    }
  }, [resultSendMessage]);

  // 「クローズする」がタップされたとき
  const handlerOnClickClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    let type: ConfirmType = "CHAT_CLOSE_1";
    if (fromPage.includes("othersAssistMe")) {
      type = "CHAT_CLOSE_2";
    }

    const cnf: Confirm = {
      isVisible: true,
      type: type,
      answer: "UNDONE",
      recordId: recordId,
    };
    setConfirm(cnf);
  };

  // 「終了しますか？／クローズしますか？」ダイアログの「YES/NO」を受けたとき
  useEffect(() => {
    if (confirm.answer === "UNDONE") {
      return;
    } else if (confirm.answer === "YES") {
      clearInterval(timerId);

      const condition: ConditionComplete = {
        user_id: userInfo?.id!,
        id: recordId,
      };
      sendComplete(condition);
    } else if (confirm.answer === "NO") {
      setConfirm(initialConfirm);
    }
  }, [confirm]);

  useEffect(() => {
    if (resultSendComplete === undefined) {
      return;
    } else if (resultSendComplete.result_code === "0") {
      setConfirm(initialConfirm);

      let cls: ModeClass = "ASSIST";
      if (fromPage.includes("othersAssistMe")) {
        cls = "REQUEST";
      }
      navigate(fromPage, {
        state: {
          modeFirst: {
            class: cls,
            code: "CLOSE",
          },
        },
      });
    } else if (resultSendComplete.result_code === "1") {
      setConfirm(initialConfirm);
      console.log(resultSendComplete.result_code);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSendComplete.message);
    }
  }, [resultSendComplete]);

  // 「Joinする」がタップされたとき
  const handlerOnClickJoin = () => {
    clearInterval(timerId);

    if (userInfo !== null) {
      const query =
        `?id_kind=${userInfo.id_kind}` +
        `&id=${userInfo.id}` +
        `&nickname=${userInfo.nickname}` +
        `&mail=${userInfo.mail}` +
        `&version=${userInfo.version}` +
        `&client_id=${userInfo.client_id}`;

      navigate(process.env.REACT_APP_PATH_PREFIX + "passport" + query, {
        state: {
          join: "助け合い",
        },
      });
    }
  };

  // チャットエリアを最下部にスクロール
  const scrollToBottom = () => {
    // @ts-ignore
    document.getElementById("bottom").scrollIntoView();
  };

  // チャットメッセージ一覧取得
  const fetch = (cnd: ConditionGetMessageAll) => {
    refIsLoading.current = true;

    let page: number = 1;

    getMessageAll(cnd)
      .then((response) => {
        if (
          response.data.result_code === "0" &&
          response.data.get <= response.data.page_total
        ) {
          if (response.data.get === 1) {
            // データを入れ替える
            setChatlist({
              ...response.data,
              result: response.data.result.sort(function (a: any, b: any) {
                return Number(a.message_no) - Number(b.message_no);
              }),
            });
          } else {
            page = response.data.get;

            // 取得後のスクロール位置合わせの値をセット
            refScrollTarget.current = (response.data.get - 1) * 10;

            // 取得済みデータと結合し、ソートする
            setChatlist((prev: ChatList) => ({
              ...prev,
              result: prev.result
                .concat(response.data.result)
                .sort(function (a, b) {
                  return Number(a.message_no) - Number(b.message_no);
                }),
              get: response.data.get,
            }));
          }

          if (response.data.get < response.data.page_total) {
            refHasMore.current = true;
          } else {
            refHasMore.current = false;
          }
          refIsLoading.current = false;
        }
      })
      .catch((e) => {
        console.dir(e);
        process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
      })
      .finally(() => {
        setTimeout(function () {
          if (page === 1) {
            console.log("最下に移動");
            setTimeout(scrollToBottom, 100);
          } else {
            console.log("スクロール保持位置に移動");
            // @ts-ignore
            document.getElementById("scrollTarget").scrollIntoView();
          }
        }, 100);
      });
  };

  // 最上部へのスクロール検出
  const positionUp = () => {
    const topLimit = refScrollEnd.current.offsetTop;
    const scrollPosition = refScrollEnd.current.getBoundingClientRect().top;

    if (topLimit - scrollPosition < 1) {
      console.log("最上部へのスクロール検出");
      console.log("hasMore = " + refHasMore.current);
      console.log("isLoading = " + refIsLoading.current);

      if (!refHasMore.current || refIsLoading.current) return;
      if (userInfo === undefined || userInfo === null) {
        return;
      }
      console.log("最上部へのスクロール検出 A");

      refPage.current++;
      const cnd: ConditionGetMessageAll = {
        user_id: myId,
        id: recordId,
        page: refPage.current,
      };
      fetch(cnd);
    }
  };

  return (
    <>
      <article className={css.content_all}>
        {/* ヘッダー */}
        <div className={css.header} ref={refHeader}>
          <div
            className={`${css.chatHeader} ${css.gfs_set_top}`}
            ref={refChatHeader}
          >
            <span className={css.backArrow} onClick={handlerOnClickBackArrow}>
              <img src={backArrow} width="23" alt="" />
            </span>
            <div className={css.chat_nameProfile}>
              <span>{yourNickName}</span>
            </div>
          </div>
          <div className={`${css.coopbbsButton_wrap} ${css.gfs_div_center}`}>
            <button
              className={`${css.coopbbsButton} ${css.color_pink} ${css.size_m} ${css.gfs_no_border}`}
              onClick={handlerOnClickClose}
            >
              クローズする
            </button>
          </div>
        </div>

        {/* ボディー */}
        <div className={css.chat_body} ref={refChatBody} id="chatBody">
          <div className={css.chatArea} ref={refChatArea} id="chatArea">
            <div id="scrollEnd" ref={refScrollEnd}></div>
            <ChatMassage
              chatList={chatList.result}
              myId={myId}
              yourId={yourId}
              scrollTarget={refScrollTarget.current}
            />
          </div>
          <div id="bottom" ref={refBottom}></div>
        </div>

        {/* フッター（インプット・ボックス）*/}
        <div className={css.footer} ref={refFooter}>
          <div className={css.err_msg}>
            {isVisivleNoWord && <span>1文字以上入力してください。</span>}
            {isVisivleOverWord && <span>500文字以内で入力してください。</span>}
            {isVisivleSensitiveWord && (
              <span>不適切な単語が含まれています。</span>
            )}
          </div>
          <div className={`${css["chatInput-wrap"]} ${css.gfs_set_bottom}`}>
            <textarea
              id="js-chatInput"
              rows={1}
              cols={30}
              className={css.chatInput}
              placeholder=""
              ref={refInput}
            ></textarea>
            <div className={css.chatInput_send} onClick={handlerOnClickSend}>
              <img src={chatinputSend} alt="" />
            </div>
          </div>
        </div>
      </article>

      {/* ダイアログ */}
      {confirm.isVisible && (
        <DialogConfirm confirm={confirm} setConfirm={setConfirm} />
      )}
    </>
  );
});

export default Chat;
