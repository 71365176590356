import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./tsx/views/pages/home/Home";

import Assistance from "./tsx/views/pages/assistance/Assistance";
import IntroRegist from "./tsx/views/pages/introRegist/IntroRegist";
import IntroViewEdit from "./tsx/views/pages/introViewEdit/IntroViewEdit";
import IntroMebukuEye from "./tsx/views/pages/introMebukuEye/IntroMebukuEye";

import MainMenu from "./tsx/views/pages/mainMenu/MainMenu";

import AssistRegist from "./tsx/views/pages/assistRegist/AssistRegist";
import AssistSmpl from "./tsx/views/pages/assistSmpl/AssistSmpl";
import AssistFaq from "./tsx/views/pages/assistFaq/AssistFaq";
import Tutorial from "./tsx/views/pages/tutorial/Tutorial";

import OthersAssistList from "./tsx/views/pages/othersAssistList/OthersAssistList";
import OthersAssist from "./tsx/views/pages/othersAssist/OthersAssist";
import MyAssistList from "./tsx/views/pages/myAssistList/MyAssistList";
import OthersAssistMe from "./tsx/views/pages/othersAssistMe/OthersAssistMe";
import Chat from "./tsx/views/pages/chat/Chat";

import Event from "./tsx/views/pages/event/Event";
import Passport from "./tsx/views/pages/passport/Passport";
import MyLifeLog from "./tsx/views/pages/myLifeLog/MyLifeLog";
import ScrollToTop from "./tsx/views/components/scroll/scrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {process.env.REACT_APP_PATH_PREFIX === "../" ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/event" element={<Event />} />
              <Route path="/passport" element={<Passport />} />
              <Route path="/myLifeLog" element={<MyLifeLog />} />

              <Route path="/assistance" element={<Assistance />} />
              <Route path="/mainMenu" element={<MainMenu />} />
              <Route path="/introRegist" element={<IntroRegist />} />
              <Route path="/introViewEdit" element={<IntroViewEdit />} />
              <Route path="/introMebukuEye" element={<IntroMebukuEye />} />

              <Route path="/assistRegist" element={<AssistRegist />} />
              <Route path="/assistSmpl" element={<AssistSmpl />} />
              <Route path="/assistFaq" element={<AssistFaq />} />
              <Route path="/tutorial" element={<Tutorial />} />

              <Route path="/othersAssistList" element={<OthersAssistList />} />
              <Route path="/othersAssist" element={<OthersAssist />} />
              <Route path="/myAssistList" element={<MyAssistList />} />
              <Route path="/othersAssistMe" element={<OthersAssistMe />} />
              <Route path="/chat" element={<Chat />} />
            </>
          ) : (
            <>
              <Route path="/mf" element={<Home />} />
              <Route path="/mf/event" element={<Event />} />
              <Route path="/mf/passport" element={<Passport />} />
              <Route path="/mf/myLifeLog" element={<MyLifeLog />} />

              <Route path="/mf/assistance" element={<Assistance />} />
              <Route path="/mf/mainMenu" element={<MainMenu />} />
              <Route path="/mf/introRegist" element={<IntroRegist />} />
              <Route path="/mf/introViewEdit" element={<IntroViewEdit />} />
              <Route path="/mf/introMebukuEye" element={<IntroMebukuEye />} />

              <Route path="/mf/assistRegist" element={<AssistRegist />} />
              <Route path="/mf/assistSmpl" element={<AssistSmpl />} />
              <Route path="/mf/assistFaq" element={<AssistFaq />} />
              <Route path="/mf/tutorial" element={<Tutorial />} />

              <Route
                path="/mf/othersAssistList"
                element={<OthersAssistList />}
              />
              <Route path="/mf/othersAssist" element={<OthersAssist />} />
              <Route path="/mf/myAssistList" element={<MyAssistList />} />
              <Route path="/mf/othersAssistMe" element={<OthersAssistMe />} />
              <Route path="/mf/chat" element={<Chat />} />
            </>
          )}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
