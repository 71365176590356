import { useNavigate } from "react-router-dom";

import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import css from "./comp_SupportItems.module.css";
import type { Support } from "../../components/apiAccess/thread/searchSupporterDataAccess";

// リスト表示
const SupportItems = (props: any) => {
  const { list } = props;

  // 「みんなの助け隊詳細」画面への遷移設定
  const navigate = useNavigate();
  const handlerOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssist", {
      state: { id: Number(e.currentTarget.dataset.recordid) },
    });
  };

  return (
    <>
      {list &&
        list.length > 0 &&
        list[0].id !== "" &&
        list.map((support: Support, index: number) => {
          return (
            <div className={css.boardPanel_wrap} key={support.id}>
              <div
                className={`${css.boardPanel_title} ${css.everyones} ${css.clickable}`}
                onClick={handlerOnClick}
                data-recordid={support.id}
              >
                {support.title}
                <div className={css.gfs_arrow_position}>
                  <FontAwesomeIcon icon={faAngleRight} size="xl" />
                </div>
              </div>
              <div className={css.boardPanel_2column}>
                <span className={css["boardPanel_date-label"]}>日時</span>
                <div className={css.boardPanel_date}>
                  <span>{support.supporter_date}</span>
                  <span>
                    {support.begin_time}〜{support.end_time}
                  </span>
                </div>
              </div>
              <hr className={css.boardPanel_divider} />
              <div className={css.boardPanel_item}>
                <div className={css.boardPanel_description}>
                  {support.description}
                </div>
              </div>
              <hr className={css.boardPanel_divider} />
              <div className={css.boardPanel_item}>
                <div>
                  <span className={css["boardPanel_label-s"]}>活動エリア</span>
                  {support.area_sentense}
                  <br />
                  {support.area_choice.join("、")}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SupportItems;
