import css from "./comp_CloseItems.module.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconRequesterDetail from "../../../../img/icon_requesterDetail.svg";
import type {
  ModeSecond,
  SupporterListWithVisible,
  SupporterWithVisible,
} from "../../components/commonTypes/types";

const MyAssistCloseItems = (props: any) => {
  const {
    supporterList,
    setSupporterList,
    modeFirst,
    setModeFirst,
    modeSecond,
    setModeSecond,
  } = props;

  // タイトルがタップされたとき
  const handlerOnClickTitle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.recordid?.split("_")?.[0];
    const mode: ModeSecond = {
      code: "DETAIL",
      selectedId: Number(selectedId),
      fromCode: "LIST",
      fromSelectedId: Number(e.currentTarget.dataset.recordid),
    };
    setModeSecond(mode);
  };

  // 「ありが隊の詳細」がタップされたとき
  const handlerOnClickAccordion = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const indexStr: string = e.currentTarget.dataset.index!;
    if (indexStr) {
      const idx = Number(indexStr);
      const copyList = supporterList.result.slice(
        0,
        supporterList.result.length
      );

      copyList.map((value: SupporterWithVisible, index: number) => {
        if (index === idx) {
          value.isVisible = !value.isVisible;
        }
      });
      setSupporterList((prev: SupporterListWithVisible) => ({
        ...prev,
        list: copyList,
      }));
    }
  };

  return (
    <>
      <div className={css.boardPanel_container}>
        {supporterList &&
          supporterList.result.length > 0 &&
          supporterList.result[0].title !== "" &&
          supporterList.result.map(
            (data: SupporterWithVisible, index: number) => {
              return (
                <div
                  className={css.boardPanel_wrap}
                  key={data.id + data.request_datetime}
                >
                  <div
                    className={`${css.boardPanel_title} ${css.close} ${css.clickable}`}
                    onClick={handlerOnClickTitle}
                    data-recordid={data.id}
                  >
                    {data.title}
                    <div className={css.gfs_arrow_position}>
                      <FontAwesomeIcon icon={faAngleRight} size="xl" />
                    </div>
                  </div>
                  <div className={css.boardPanel_2column}>
                    <span className={css["boardPanel_date-label"]}>日時</span>
                    <div className={css.boardPanel_date}>
                      <span>{data.supporter_date}</span>
                      <div>
                        <span>{data.begin_time}</span>
                        <span>〜</span>
                        <span>{data.end_time}</span>
                      </div>
                    </div>
                  </div>
                  {data.supporter_id && (
                    <>
                      <hr className={css.boardPanel_divider} />
                      <div className={css.boardPanel_profileAction}>
                        <div className={css.boardPanel_nameProfile}>
                          {/* <div className={css.boardPanel_nameIcon}></div> */}
                          <span>{data.nickname}</span>
                        </div>
                        <div
                          className={`${css.boardPanel_accordionDetail} ${css.close}`}
                        >
                          <div
                            className={`${css.boardPanel_accordionLabel} ${
                              css["js-accordion"]
                            }} ${data.isVisible === true ? css.open : ""}`}
                            data-index={index}
                            onClick={handlerOnClickAccordion}
                          >
                            <img src={iconRequesterDetail} width="30" alt="" />
                            <span>ありが隊の詳細</span>
                          </div>
                          <div
                            className={`${
                              css["boardPanel_accordionDetail-text"]
                            } ${data.isVisible === true ? css.open : ""}`}
                          >
                            <div
                              className={css["boardPanel_accordionDetail-item"]}
                            >
                              <div className="text_14">{data.introduction}</div>
                            </div>
                            <hr
                              className={css["boardPanel_accordion-divider"]}
                            />
                            <div
                              className={css["boardPanel_accordionDetail-item"]}
                            >
                              <span
                                className={
                                  css["boardPanel_accordionDetail-sublabel"]
                                }
                              >
                                助け隊へのコメント
                              </span>
                              <div>{data.comment}</div>
                              <span
                                className={
                                  css["boardPanel_accordionDetail-sendDate"]
                                }
                              >
                                送信日
                                <br />
                                {data.request_datetime}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            }
          )}
      </div>
    </>
  );
};

export default MyAssistCloseItems;
