import axios from "axios";
import { useState } from "react";
import GetApiUrl from "../getApiUrl";

// ありが隊辞退APIアクセス
export const useDeclineThanks = () => {
    const [resultDeclineThanks, setResultDeclineThanks] = useState<ResultData | undefined>();
    const [isLoadingDeclineThanks, setIsLoadingDeclineThanks] = useState<boolean>(false);
    const [isErrorDeclineThanks, setIsErrorDeclineThanks] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const sendDeclineThanks = async (props: ConditionDeclineThanks) => {
        console.dir(props);
        setIsLoadingDeclineThanks(true);
        setIsErrorDeclineThanks(false);

        assistSvr.post("/thread/declineThanks", {
            ...props
        }).then((response) => {
            console.dir(response.data);
            setResultDeclineThanks(response.data);
        }).catch((e) => {
            setIsErrorDeclineThanks(true);
            process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
        }).finally(() => {
            setIsLoadingDeclineThanks(false);
        })
    };

    return { resultDeclineThanks, sendDeclineThanks, isLoadingDeclineThanks, isErrorDeclineThanks };
};

// ありが隊辞退API取得条件
export type ConditionDeclineThanks = {
    id: number;                 // 助け隊登録ID
    user_id: string;            // ログインユーザのめぶくID
    request_user_id: string;    // 自分が登録した助け隊に共助をリクエストしてきたユーザーのめぶくID
}

export type ResultData = {
    result_code: string;    // 処理結果コード
    message: string;        // エラーメッセージ
};
