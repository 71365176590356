import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const refPassportId = useRef<HTMLInputElement>(null);
  const refLifeLogId = useRef<HTMLInputElement>(null);
  const refEventId = useRef<HTMLInputElement>(null);

  // めぶく（passport, lifelog, event）のみを表示する場合は true
  const mebuku_only: boolean = false;

  const prefix = "..";
  // const prefix = "../mf";

  const passportQueryParam: string = `/passport?id_kind=1&id=GFS-nihei-0123&nickname=nickname_nihei&mail=n.nihei@e-gfs.co.jp&version=2.0&client_id=CL002`;
  const lifeLogQueryParam: string =
    "/myLifeLog?id_kind=1&id=GFS-nihei-0123&nickname=nickname_nihei&mail=n.nihei@e-gfs.co.jp&version=2.0&client_id=CL002";
  const eventQueryParam: string =
    "/event?id_kind=1&id=GFS-nihei-0123&nickname=nickname_nihei&mail=n.nihei@e-gfs.co.jp&version=2.0&client_id=CL002";

  const homeView = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "");
  };

  const assistance1 = () => {
    const queryParam = readInputParam();
    navigate(process.env.REACT_APP_PATH_PREFIX + "assistance" + queryParam);
  };

  const initialProfileInput = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "initialProfileInput");
  };

  const introViewEdit = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "introViewEdit");
  };

  const mainMenu = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
  };

  const assistRegist = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "assistRegist");
  };

  const assistSmpl = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "assistSmpl");
  };

  const assistFaq = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "assistFaq");
  };

  const loginEvent = () => {
    const queryParam = readInputParam();
    navigate(process.env.REACT_APP_PATH_PREFIX + "event" + queryParam);
  };

  const loginPassport = () => {
    const queryParam = readInputParam();
    navigate(process.env.REACT_APP_PATH_PREFIX + "passport" + queryParam);
  };

  const loginMyLifeLog = () => {
    const queryParam = readInputParam();
    navigate(process.env.REACT_APP_PATH_PREFIX + "myLifeLog" + queryParam);
  };

  const othersAssistList = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistList");
  };

  const myAssistListOpen = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "myAssistList", {
      state: {
        modeFirst: {
          class: "ASSIST",
          code: "OPEN",
        },
      },
    });
  };

  const myAssistListMatch = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "myAssistList", {
      state: {
        modeFirst: {
          class: "ASSIST",
          code: "MATCH",
        },
      },
    });
  };

  const othersAssistMe = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistMe", {
      state: {
        modeFirst: {
          class: "REQUEST",
          code: "OPEN",
        },
      },
    });
  };

  const othersAssistMeMatch = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistMe", {
      state: {
        modeFirst: {
          class: "REQUEST",
          code: "MATCH",
        },
      },
    });
  };

  const cameraTest = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "testCamera");
  };

  const w = "120px";
  const refIdKind = useRef<HTMLInputElement>(null!);
  const refId = useRef<HTMLInputElement>(null!);
  const refNickname = useRef<HTMLInputElement>(null!);
  const refMail = useRef<HTMLInputElement>(null!);
  const refVersion = useRef<HTMLInputElement>(null!);
  const refClientId = useRef<HTMLInputElement>(null!);

  const handlerOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    if (e.target.value === "GFSnihei0123") {
      refIdKind.current.value = "1";
      refId.current.value = "GFSnihei0123";
      refNickname.current.value = "ニックネーム.GFS-nihei-0123";
      refMail.current.value = "";
      refVersion.current.value = "2.0";
      refClientId.current.value = "CL002";
    } else if (e.target.value === "GFSbot0000") {
      refIdKind.current.value = "1";
      refId.current.value = "GFSbot0000";
      refNickname.current.value = "ニックネーム.GFS-bot-0000";
      refMail.current.value = "";
      refVersion.current.value = "2.0";
      refClientId.current.value = "CL002";
    } else if (e.target.value === "GFSbbbb0123") {
      refIdKind.current.value = "1";
      refId.current.value = "GFSbbbb0123";
      refNickname.current.value = "ニックネーム.GFS-bbbb-0123";
      refMail.current.value = "";
      refVersion.current.value = "2.0";
      refClientId.current.value = "aaaa";
    } else if (e.target.value === "GFStestA01") {
      refIdKind.current.value = "1";
      refId.current.value = "GFStestA01";
      refNickname.current.value = "ニックネーム.GFS-test-A01";
      refMail.current.value = "n.nihei@e-gfs.co.jp";
      refVersion.current.value = "2.0.1";
      refClientId.current.value = "CL001";
    } else if (e.target.value === "GFStestA02") {
      refIdKind.current.value = "1";
      refId.current.value = "GFStestA02";
      refNickname.current.value = "ニックネーム.GFS-test-A02";
      refMail.current.value = "nob.nihei@me.com";
      refVersion.current.value = "2.0.1";
      refClientId.current.value = "CL001";
    } else if (e.target.value === "GFStestA03") {
      refIdKind.current.value = "1";
      refId.current.value = "GFStestA03";
      refNickname.current.value = "ニックネーム.GFS-test-A03";
      refMail.current.value = "nob.nihei@gmail.com";
      refVersion.current.value = "2.0.1";
      refClientId.current.value = "CL001";
    } else if (e.target.value === "JCItest0001") {
      refIdKind.current.value = "1";
      refId.current.value = "JCItest0001";
      refNickname.current.value = "めぶく　太郎";
      refMail.current.value = "xxx@xxx.xx.jp";
      refVersion.current.value = "2.0";
      refClientId.current.value = "CL001";
    } else if (e.target.value === "JCItest0002") {
      refIdKind.current.value = "1";
      refId.current.value = "JCItest0002";
      refNickname.current.value = "日本　次郎";
      refMail.current.value = "xxx@xxx.xx.jp";
      refVersion.current.value = "2.0";
      refClientId.current.value = "CL002";
    }
  };

  const readInputParam = (): string => {
    const id_kind: string = refIdKind.current.value;
    const id: string = refId.current.value;
    const nickname: string = refNickname.current.value;
    const mail = refMail.current.value;
    const version = refVersion.current.value;
    const client_id = refClientId.current.value;

    const qParam: string = `?id_kind=${id_kind}&id=${id}&nickname=${nickname}&mail=${mail}&version=${version}&client_id=${client_id}`;
    return qParam;
  };

  //id_kind=1&id=GFS-nihei-0123&nickname=nickname_nihei&mail=n.nihei@e-gfs.co.jp&version=2.0&client_id=CL002";
  return (
    <div style={{ padding: "20px" }}>
      <div>
        登録済みユーザから選ぶか入力欄に入力してページへのリンクボタンをクリックしてください
      </div>
      <br />
      <div>
        <label>登録済みユーザID：</label>
        <select onChange={handlerOnChange}>
          <option value="">未選択</option>
          <option value="GFSnihei0123">GFSnihei0123</option>
          <option value="GFSbot0000">GFSbot0000</option>
          <option value="GFSbbbb0123">GFSbbbb0123</option>
          <option value="JCItest0001">めぶく　太郎</option>
          <option value="JCItest0002">日本　次郎</option>
          <option value="GFStestA01">GFStestA01</option>
          <option value="GFStestA02">GFStestA02</option>
          <option value="GFStestA03">GFStestA03</option>
        </select>
      </div>
      <br />
      <div>
        <label style={{ width: w }}>ID種別:</label>
        <input type="text" ref={refIdKind} />
        <br />
        <label style={{ width: w }}>ユーザーID:</label>
        <input type="text" ref={refId} />
        <br />
        <label style={{ width: w }}>ニックネーム:</label>
        <input type="text" ref={refNickname} />
        <br />
        <label style={{ width: w }}>メールアドレス:</label>
        <input type="text" ref={refMail} />
        <br />
        <label style={{ width: w }}>めぶくアプリのバージョン:</label>
        <input type="text" ref={refVersion} />
        <br />
        <label style={{ width: w }}>クライアントID:</label>
        <input type="text" ref={refClientId} />
      </div>

      {!mebuku_only && (
        <>
          {/* <h1>Home</h1> */}
          <br />
          {/* <Button variant="primary" type="submit" onClick={homeView}>
            Home画面
          </Button>
          <br />
          <br /> */}
          <Button
            variant="success"
            type="submit"
            onClick={assistance1}
            style={{ textAlign: "left" }}
          >
            助け合い掲示板
          </Button>
          <br />
          <br />
          {/* <Button
            variant="success"
            type="submit"
            onClick={assistance2}
            style={{ textAlign: "left" }}
          >
            助け合い掲示板
            <br /> (登録ユーザー プロフィールなし)
          </Button>
          <br />
          <br /> 
          <Button
            variant="success"
            type="submit"
            onClick={assistance4}
            style={{ textAlign: "left" }}
          >
            助け合い掲示板
            <br /> (未登録ユーザー)
          </Button>
          <br />
          <br /> */}
          {/* <Button variant="success" type="submit" onClick={initialProfileInput}>
            紹介文登録
          </Button>
          <br />
          <br />
          <Button variant="success" type="submit" onClick={introViewEdit}>
            自己紹介の確認編集
          </Button>
          <br />
          <br />
          <Button variant="success" type="submit" onClick={mainMenu}>
            メインメニュー
          </Button>
          <br />
          <br />
          <Button variant="danger" type="submit" onClick={assistRegist}>
            助け隊を登録する/登録
          </Button>
          <br />
          <br />
          <Button variant="danger" type="submit" onClick={assistSmpl}>
            登録例
          </Button>
          <br />
          <br />
          <Button variant="danger" type="submit" onClick={assistFaq}>
            FAQ
          </Button>
          <br />
          <br />
          <Button variant="warning" type="submit" onClick={othersAssistList}>
            みんなの助け隊一覧・検索
          </Button>
          <br />
          <br />
          <Button variant="warning" type="submit" onClick={myAssistListOpen}>
            自分の助け隊一覧
          </Button>
          <br />
          <br />
          <Button variant="warning" type="submit" onClick={othersAssistMe}>
            自分のありが隊一覧
          </Button>
          <br />
          <br /> */}
        </>
      )}

      <Button variant="primary" type="submit" onClick={loginPassport}>
        パスポート
      </Button>
      <br />
      <br />
      <Button variant="primary" type="submit" onClick={loginMyLifeLog}>
        ライフログページ
      </Button>
      <br />
      <br />
      <Button variant="primary" type="submit" onClick={loginEvent}>
        イベントページ
      </Button>
    </div>
  );
}

export default Home;
