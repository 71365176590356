import css from "./comp_MatchItems.module.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconChat from "../../../../img/icon_chat.svg";
import iconComment from "../../../../img/icon_comment.svg";
import iconIntroduction from "../../../../img/icon_introduction.svg";
import type {
  ModeSecond,
  SupporterListWithVisible,
  SupporterWithVisible,
} from "../../components/commonTypes/types";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const OthersAssistMeMatchItems = (props: any) => {
  const {
    supporterList,
    setSupporterList,
    modeFirst,
    setModeFirst,
    modeSecond,
    setModeSecond,
  } = props;

  const navigate = useNavigate();
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // タイトルがタップされたとき
  const handlerOnClickTitle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.recordid?.split("_")?.[0];
    const mode: ModeSecond = {
      code: "DETAIL",
      selectedId: Number(selectedId),
      fromCode: "LIST",
      fromSelectedId: Number(e.currentTarget.dataset.recordid),
    };
    setModeSecond(mode);
  };

  // 「チャットする」がタップされたとき
  const handlerOnClickChat = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.selectedid?.split("_")?.[0];
    navigate(process.env.REACT_APP_PATH_PREFIX + "chat", {
      state: {
        myId: userInfo?.id,
        recordId: Number(selectedId),
        yourId: e.currentTarget.dataset.yourid,
        yourNickName: e.currentTarget.dataset.yournickname,
        // fromPage: "../othersAssistMe",
        fromPage: process.env.REACT_APP_PATH_PREFIX + "othersAssistMe",
      },
    });
  };

  // 「助け隊の紹介文」がタップされたとき
  const handlerOnClickIntro = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const indexStr: string = e.currentTarget.dataset.index!;
    if (indexStr) {
      const idx = Number(indexStr);
      const copyList = supporterList.result.slice(
        0,
        supporterList.result.length
      );

      copyList.map((value: SupporterWithVisible, index: number) => {
        if (index === idx) {
          value.isVisibleIntro = !value.isVisibleIntro;
        }
      });

      setSupporterList((prev: SupporterListWithVisible) => ({
        ...prev,
        list: copyList,
      }));
    }
  };

  // 「助け隊へ送ったコメント」がタップされたとき
  const handlerOnClickComment = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const indexStr: string = e.currentTarget.dataset.index!;
    if (indexStr) {
      const idx = Number(indexStr);
      const copyList = supporterList.result.slice(
        0,
        supporterList.result.length
      );

      copyList.map((value: SupporterWithVisible, index: number) => {
        if (index === idx) {
          value.isVisibleComment = !value.isVisibleComment;
        }
      });
      setSupporterList((prev: SupporterWithVisible) => ({
        ...prev,
        list: copyList,
      }));
    }
  };

  return (
    <div className={css.boardPanel_container}>
      <>
        {supporterList &&
          supporterList.result &&
          supporterList.result.length > 0 &&
          supporterList.result.map(
            (data: SupporterWithVisible, index: number) => {
              return (
                <div className={css.boardPanel_wrap} key={data.id}>
                  <div
                    className={`${css.boardPanel_title} ${css.myrequest} ${css.clickable}`}
                    onClick={handlerOnClickTitle}
                    data-recordid={data.id}
                  >
                    {data.title}
                    <div className={css.gfs_arrow_position}>
                      <FontAwesomeIcon icon={faAngleRight} size="xl" />
                    </div>
                  </div>
                  <div className={css.boardPanel_2column}>
                    <span className={css["boardPanel_date-label"]}>日時</span>
                    <div className={css.boardPanel_date}>
                      <span>{data.supporter_date}</span>
                      <span>
                        {data.begin_time}〜{data.end_time}
                      </span>
                    </div>
                  </div>
                  <hr className={css.boardPanel_divider} />
                  <div className={css.boardPanel_profileAction}>
                    <div className={css.boardPanel_nameProfile}>
                      {/* <div className={css.boardPanel_nameIcon}>
                        <img
                          src={"data:image/jpg;base64," + data.image}
                          width="50"
                          height="50"
                          alt=""
                        />
                      </div> */}
                      <span>{data.nickname}</span>
                    </div>
                    <div
                      className={css.boardPanel_action}
                      onClick={handlerOnClickChat}
                      data-yourid={data.supporter_id}
                      data-selectedid={data.id}
                      data-yournickname={data.nickname}
                    >
                      <div className={css.boardPanel_actionLabel}>
                        <img src={iconChat} width="30" alt="" />
                        <span>チャットする</span>
                        {Number(data.chat_unread) === 0 ? (
                          ""
                        ) : (
                          <span className={css["boardPanel_actionChat-unread"]}>
                            未読
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${css.boardPanel_accordionDetail} ${css.myrequest}`}
                    >
                      <div
                        className={`${css.boardPanel_accordionLabel} ${css["js-accordion"]}`}
                        data-index={index}
                        onClick={handlerOnClickIntro}
                      >
                        <img src={iconIntroduction} width="30" alt="" />
                        <span>助け隊の紹介文</span>
                      </div>
                      <div
                        className={`${css["boardPanel_accordionDetail-text"]} ${
                          data.isVisibleIntro ? css.open : ""
                        }`}
                      >
                        <div className={css["boardPanel_accordionDetail-item"]}>
                          <div>{data.introduction}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${css.boardPanel_accordionDetail} ${css.myrequest}`}
                    >
                      <div
                        className={`${css.boardPanel_accordionLabel} ${css["js-accordion"]}`}
                        data-index={index}
                        onClick={handlerOnClickComment}
                      >
                        <img src={iconComment} width="30" alt="" />
                        <span>助け隊へ送ったコメント</span>
                      </div>
                      <div
                        className={`${css["boardPanel_accordionDetail-text"]} ${
                          data.isVisibleComment ? css.open : ""
                        }`}
                      >
                        <div className={css["boardPanel_accordionDetail-item"]}>
                          <div>{data.comment}</div>
                          <span
                            className={
                              css["boardPanel_accordionDetail-sendDate"]
                            }
                          >
                            送信日
                            <br />
                            {data.request_datetime}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <hr className={css.boardPanel_divider} />
                  <div className={css.boardPanel_item}>
                    <a href="" className={css["boardPanel_action-cancel"]}>
                      申し込みを取りやめる
                    </a>
                  </div> */}
                </div>
              );
            }
          )}
      </>
    </div>
  );
};

export default OthersAssistMeMatchItems;
