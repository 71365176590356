import React from "react";

const getToday = (props: any) => {
  var y = props.ymd.getFullYear();
  var m = ("00" + (props.ymd.getMonth() + 1)).slice(-2);
  var d = ("00" + props.ymd.getDate()).slice(-2);
  return y + "-" + m + "-" + d;
};

export default getToday;
