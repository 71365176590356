import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useExecuteActivate = () => {
    const [resultActivate, setActivate] = useState<ResultActivate | undefined>();
    const [isSendingActivate, setIsSendingActivate] = useState<boolean>(false);
    const [isErrorActivate, setIsErrorActivate] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const executeActivate = useCallback(async (props: ConditionActivate) => {
        console.dir(props);
        setIsSendingActivate(true);
        setIsErrorActivate(false);

        assistSvr
            .post(`/event/executeActivate`, {
                ...props
            })
            .then((response) => {
                console.dir(response.data);
                setActivate({
                    ...response.data,
                    event_id: props.event_id
                });
            })
            .catch((e) => {
                setIsErrorActivate(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsSendingActivate(false);
            });
    }, []);
    return { resultActivate, executeActivate, isSendingActivate, isErrorActivate };
};

export type ConditionActivate = {
    account_id: string;         // アカウントID
    activation_cd: string;      // アクティベーションコード
    event_id: string;           // イベントID
};

export type ResultActivate = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
    event_id: string;
};
