import React from "react";
import bookfes from "../../../../img/EventPic.png";
import assist from "../../../../img/EventPicAssist.png";
import test01 from "../../../../img/EventPicTest01.png";
import test02 from "../../../../img/EventPicTest02.png";
import test03 from "../../../../img/EventPicTest03.png";
import test04 from "../../../../img/EventPicTest04.png";

export const EventPicture = (props: any) => {
  const { name } = props;

  let pict = assist;
  if (name === "EventPic.png") {
    pict = bookfes;
  } else if (name === "EventPicAssist.png") {
    pict = assist;
  } else if (name === "EventPicTest01.png") {
    pict = test01;
  } else if (name === "EventPicTest02.png") {
    pict = test02;
  } else if (name === "EventPicTest03.png") {
    pict = test03;
  } else if (name === "EventPicTest04.png") {
    pict = test04;
  }

  return <img src={pict} alt="picture" width="145" height="80" />;
};
