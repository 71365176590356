import React, { useEffect, useContext } from "react";
import css from "./MainMenu.module.css";
import { useNavigate } from "react-router-dom";
import PersonalName from "./comp_PersonalName";
import { ReactComponent as TopmenuIconRegist } from "../../../../img/topmenuIcon_regist.svg";
import { ReactComponent as TopmenuIconEverysupport } from "../../../../img/topmenuIcon_everysupport.svg";
import { ReactComponent as TopmenuIconMysupportApply } from "../../../../img/topmenuIcon_mysupportApply.svg";
import { ReactComponent as TopmenuIconMyrequestChat } from "../../../../img/topmenuIcon_myrequestChat.svg";
import { ReactComponent as TopmenuIconMysupportChat } from "../../../../img/topmenuIcon_mysupportChat.svg";
import { ReactComponent as IconClickablePink } from "../../../../img/iconClickable_pink.svg";
import mebukueyeAppIcon from "../../../../img/mebukueyeAppIcon.png"
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import { useGetStatus } from "../../components/apiAccess/profile/useGetStatus";

function MainMenu() {
  const navigate = useNavigate();

  // 総合ステータス取得のカスタムフック
  const { resultGetStatus, getStatus, isLoadingStatus, isErrorGetStatus } =
    useGetStatus();

  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // 「助け隊を登録する」への遷移が選択されたとき
  const assistRegist = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "assistRegist", {
      state: {
        modeRegist: "REGIST",
      },
    });
  };

  // 「みんなの助け隊一覧・検索」への遷移が選択されたとき
  const othersAssistList = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistList");
  };

  // 「自分の助け隊一覧」への遷移が選択されたとき
  const myAssistList = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "myAssistList", {
      state: {
        modeFirst: {
          class: "ASSIST",
          code: "OPEN",
        },
      },
    });
  };

  // 「自分のありが隊一覧」への遷移が選択されたとき
  const othersAssistMe = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistMe", {
      state: {
        modeFirst: {
          class: "REQUEST",
          code: "OPEN",
        },
      },
    });
  };

  // 「自己紹介の確認・編集」への遷移が選択されたとき
  const introViewEdit = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "introViewEdit");
  };

  // 「使い方」への遷移が選択されたとき
  const tutorial = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "tutorial");
  };

  // ログインユーザ情報が確認できたとき
  useEffect(() => {
    if (userInfo === undefined || userInfo === null) return;

    // 総合ステータスを取得する
    const userId = userInfo?.id!;
    getStatus(userId);
  }, [userInfo]);

  // 自分の助け隊に対する「共助リクエスト未読」がタップされたとき
  const handleOnClickUnreadRequest = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    // 自分の助け隊一覧 オープン
    navigate(process.env.REACT_APP_PATH_PREFIX + "myAssistList", {
      state: {
        modeFirst: {
          class: "ASSIST",
          code: "OPEN",
        },
      },
    });
  };

  // 自分の助け隊の「チャット未読」がタップされたとき
  const handleOnClickUnreadChatMySupport = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    // 自分の助け隊一覧 成立
    navigate(process.env.REACT_APP_PATH_PREFIX + "myAssistList", {
      state: {
        modeFirst: {
          class: "ASSIST",
          code: "MATCH",
        },
      },
    });
  };

  // 自分のありが隊の「チャット未読」がタップされたとき
  const handleOnClickUnreadChatMyThanks = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    // 自分のありが隊 成立
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistMe", {
      state: {
        modeFirst: {
          class: "REQUEST",
          code: "MATCH",
        },
      },
    });
  };

  const introMebukuEye = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "introMebukuEye");
  };

  return (
    <div>
      <div>
        <body>
          <article className={`${css.content_all} ${css.event_back}`}>
            <div className={`${css.coopbbs_block_wrap} ${css.topCoop}`}>
              <div className="requester_nameProfile">
                <div className={css["coopbbsButton-topmenu-name"]}>
                  <span>
                    <PersonalName />
                  </span>
                  <div className={css["coopbbsButton-topmenu-wrap"]}>
                    <div
                      className={`${css["coopbbsButton-topmenu"]} ${css.size_s}`}
                      onClick={introViewEdit}
                    >
                      自己紹介の確認
                      <svg className={css.gfs_clickable_icon}>
                        <IconClickablePink />
                      </svg>
                    </div>
                    <div
                      className={`${css["coopbbsButton-topmenu"]} ${css.size_s}`}
                      onClick={tutorial}
                    >
                      使い方
                      <svg className={css.gfs_clickable_icon}>
                        <IconClickablePink />
                      </svg>
                    </div>
                    {/* <div
                      className={`${css["coopbbsButton-topmenu"]} ${css.size_s}`}
                      onClick={() => {
                        window.open("https://www.tskai.net/", "_blank");
                      }}
                    >
                      公式サイト
                      <svg className={css.gfs_clickable_icon}>
                        <IconClickablePink />
                      </svg>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className={css.topCoop_menu_wrap}>
                <div className={`${css.topCoop_menu_flex} ${css.first}`}>
                  <div
                    className={`${css.topmenu} ${css.first}`}
                    style={{ borderColor: "#FFA5C8" }}
                  >
                    <div className={css.topmenu_blocks}>
                      <TopmenuIconRegist className={css.menuFirst_icon} />
                      <div
                        className={`${css.topmenu_title} ${css.first}`}
                        style={{ color: "#FFA5C8" }}
                      >
                        助け隊を
                        <br />
                        登録する
                      </div>
                      <div className={css.menuFirst_desc}>
                        「助け隊」は1件づつ登録できます。
                        登録の上限数はありません。
                      </div>
                    </div>
                    <div
                      className={css.topmenu_arrow}
                      style={{ backgroundColor: "#FFA5C8" }}
                      onClick={assistRegist}
                    ></div>
                  </div>
                  <div
                    className={`${css.topmenu} ${css.first}`}
                    style={{ borderColor: "#FFA3A3" }}
                  >
                    <div className={css.topmenu_blocks}>
                      <TopmenuIconEverysupport className={css.menuFirst_icon} />
                      <div
                        className={`${css.topmenu_title} ${css.first}`}
                        style={{ color: "#FFA3A3" }}
                      >
                        みんなの
                        <br />
                        助け隊一覧
                      </div>
                      <div className={css.menuFirst_desc}>
                        良い案件を見つけたら「ありが隊」として申し込んでみましょう。
                      </div>
                    </div>
                    <div
                      className={css.topmenu_arrow}
                      style={{ backgroundColor: "#FDA3A3" }}
                      onClick={othersAssistList}
                    ></div>
                  </div>
                </div>
                <div className={`${css.topCoop_menu_flex} ${css.second}`}>
                  <div
                    className={`${css.topmenu} ${css.second}`}
                    style={{ borderColor: "#FDC08A" }}
                  >
                    <div className={css.topmenu_blocks}>
                      <div
                        className={`${css.topmenu_title} ${css.second}`}
                        style={{ color: "#FCB16F" }}
                      >
                        自分の
                        <br />
                        助け隊
                      </div>
                      <div className={css.menuSecond_action_wrap}>
                        <div
                          className={css.noticeActionIcon}
                          onClick={handleOnClickUnreadRequest}
                        >
                          <TopmenuIconMysupportApply />
                          <div
                            className={`${
                              resultGetStatus?.result[0]
                                .new_assist_request_count === "0"
                                ? css["boardPanel_status-numzero"]
                                : css["boardPanel_status-numtrue"]
                            } ${!resultGetStatus ? css.make_transparent : ""}`}
                          >
                            {
                              resultGetStatus?.result[0]
                                .new_assist_request_count
                            }
                            <span className={css.text_12}>名</span>
                          </div>
                        </div>
                        <div
                          className={css.noticeActionIcon}
                          onClick={handleOnClickUnreadChatMySupport}
                        >
                          <TopmenuIconMysupportChat />
                          <div
                            className={`${
                              resultGetStatus?.result[0]
                                .new_assist_request_chat_count === "0"
                                ? css["boardPanel_status-numzero"]
                                : css["boardPanel_status-numtrue"]
                            } ${!resultGetStatus ? css.make_transparent : ""}`}
                          >
                            {
                              resultGetStatus?.result[0]
                                .new_assist_request_chat_count
                            }
                            <span className={css.text_12}>名</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={css.topmenu_arrow}
                      style={{ backgroundColor: "#FDC08A" }}
                      onClick={myAssistList}
                    ></div>
                  </div>
                  <div
                    className={`${css.topmenu} ${css.second}`}
                    style={{ borderColor: "#F1B5FF" }}
                  >
                    <div className={css.topmenu_blocks}>
                      <div
                        className={`${css.topmenu_title} ${css.second}`}
                        style={{ color: "#E4ABF2" }}
                      >
                        自分の
                        <br />
                        ありが隊
                      </div>
                      <div className={css.menuSecond_action_wrap}>
                        <div
                          className={css.noticeActionIcon}
                          onClick={handleOnClickUnreadChatMyThanks}
                        >
                          <TopmenuIconMyrequestChat />
                          <div
                            className={`${
                              resultGetStatus?.result[0]
                                .new_my_help_chat_count === "0"
                                ? css["boardPanel_status-numzero"]
                                : css["boardPanel_status-numtrue"]
                            } ${!resultGetStatus ? css.make_transparent : ""}`}
                          >
                            {resultGetStatus?.result[0].new_my_help_chat_count}
                            <span className={css.text_12}>名</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={css.topmenu_arrow}
                      style={{ backgroundColor: "#F1B5FF" }}
                      onClick={othersAssistMe}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className={`${css.pd_tb_10} ${css.pd_lr_30}`}>
              <div
                onClick={introMebukuEye}
                className={`${css.topCoop_otherInfo} ${css.mg_b_30}`}
              >
                <img
                  src={mebukueyeAppIcon}
                  alt="めぶくeye"
                  width="50"
                />
                <span className={`${css.text_14} ${css.line_h_ss}`}>
                  「めぶくEye」で行なわれた
                  <br />
                  手助け状況を紹介します
                </span>
                <div className={css.topmenu_arrowInfo}></div>
              </div>
            </div>
          </article>
        </body>
      </div>
    </div>
  );
}

export default MainMenu;
