export const DummyData_faq = [
  {
    faq_title: '"助け隊"として登録できる活動はどんなものがありますか',
    faq_text: (
      <div>
        <p>
          　活動の制限は設けておりません。しかし、初めて会う人を助けるので、初めは個室や家など密室になる空間で助ける活動ではなく、カフェや公園、スーパーマーケット、モールなどで助けられる活動内容を登録するのが良いです。
        </p>
        <p>
          　まずは自分が得意なこと、好きなこと、共有したいこと、教えたいことなどを是非登録してみてください。
        </p>
      </div>
    ),
  },
  {
    faq_title: "「タイトル」にはどんなことを記載すればいいでしょうか",
    faq_text: (
      <div>
        <p>
          　活動内容をシンプルに単刀直入に記載してください。シンプルだと読みやすく目に留まりやすいです。
        </p>
      </div>
    ),
  },
  {
    faq_title: "「内容と詳細」にはどこまで詳しく書いたらいいでしょうか？",
    faq_text: (
      <div>
        <p>
          　どんな活動内容なのかが伝わる程度の内容を書いてください。登録例を参照いただくとイメージが掴みやすかと思います。「ありが隊」が見つかった後は、その方とチャットができるようになりますので、より詳しい内容を詰めることができます。
        </p>
      </div>
    ),
  },
  {
    faq_title: "エラーが出てしまい登録が完了できません。",
    faq_text: (
      <div>
        <p>
          　エラーになった部分に原因が表示されますので、原因を解決してください。
        </p>
        <p>
          　「タイトル」と「内容と詳細」には文字数制限があります。「タイトル」は30文字、「内容と詳細」は500文字です。
        </p>
        <p>
          　また相手の気分を害するような不適切な文字が含まれている場合もエラーとなります。
        </p>
      </div>
    ),
  },
  {
    faq_title: "登録した内容は後から変更・削除できますか？",
    faq_text: (
      <div>
        <p>　はい、できます。</p>
        <p>
          　登録した内容は助け合い掲示板TOPの「自分の助け隊一覧」で確認、変更、削除できます。
        </p>
      </div>
    ),
  },
  {
    faq_title:
      "「同時に助けられる人数」ですが、例えば朝9時から12時までに、個別に3人助けられる場合はどうすればいいでしょうか。",
    faq_text: (
      <div>
        <p>
          　お手数ですが、個別の場合はその回数分、"助け隊"の登録をお願いします。
        </p>
        <p>
          　カフェにて家庭教師を9時から12時まで、同時に3名教えることができる場合は、登録は1件で、「同時に助けられる人数」は3名となります。
        </p>
      </div>
    ),
  },
];
