import React, { memo } from "react";
import css from "./ErrorModal.module.css";

const ErrorModalOverWordCountTwoHundred = memo((props: any) => {
  const closeModal = () => {
    props.setShowModalOverWord(false);
  };

  const contents = {
    title: "文字数制限を超えています。200文字以内で入力してください。",
    message: "",
  };

  return (
    <>
      {props.showFlag && (
        <section id="modalArea" className={css.modalArea}>
          <div className={css.modalWrapper}>
            <div className={css.modalContents}>
              <h5 className={css.dialog_title}>{contents.title}</h5>
              <p className={css.dialog_message}>{contents.message}</p>
            </div>
            <div className={css.modalButtons}>
              <button className={css.dialog_button} onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default ErrorModalOverWordCountTwoHundred;
