import React from "react";
import css from "./ErrorModal.module.css";

const ErrorModalHiraganaCheckPersonOrJuridical = (props: any) => {
  const closeModal = () => {
    props.setShowModalSensitiveWord(false);
  };

  const contents = {
    title: "以下をご確認ください。",
    target: "・法人名（ふりがな）",
    message: "ひらがな以外が入力されています。",
  };

  return (
    <>
      {props.showFlag && (
        <section id="modalArea" className={css.modalArea}>
          <div className={css.modalWrapper}>
            <div className={css.modalContents}>
              <h5 className={css.dialog_title}>{contents.title}</h5>
              <p className={css.dialog_message}>{contents.target}</p>
              <p className={css.dialog_message}>{contents.message}</p>
            </div>
            <div className={css.modalButtons}>
              <button className={css.dialog_button} onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ErrorModalHiraganaCheckPersonOrJuridical;
