import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useGetPassport = () => {
    const [responseData, setResponseData] = useState<ResultData | undefined>();
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
        responseType: "json",
        headers: {
            "Content-Type": "application/json",
        },
    });

    const getPassport = useCallback(async (props: SearchCondition) => {
        console.dir(props);
        const {
            user_id, event_id
        } = props;

        setIsSending(true);
        setIsError(false);

        await assistSvr
            .get(`/passport/getPassport?user_id=${user_id}&event_id=${event_id}`)
            .then((response) => {
                console.dir(response.data);
                // API呼び出しエラー
                if (response.status !== 200) {
                    throw new Error("API接続エラー");
                } else {
                    setResponseData(response.data);
                }
            })
            .catch((e) => {
                setIsError(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsSending(false);
            });
    }, []);
    return { responseData, getPassport, isSending, isError };
};

export type SearchCondition = {
    user_id: string;        // めぶくID
    event_id: string;       // イベントID
};

export type ResultData = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
    event_id: string;       // イベントID
    passport: string;       // パスポートデータ（QRコード用文字列）
};
