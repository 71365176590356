import React, { memo } from "react";
import css from "./comp_TabMenu.module.css";
import AssistFaq from "../assistFaq/AssistFaq";
import AssistSmpl from "../assistSmpl/AssistSmpl";
import Inputs from "./comp_Inputs";

import RegistCheckedPng from "../../../../img/icon_tab_regist.png";
import RegistNotCheckedPng from "../../../../img/icon_tab_regist_gray.png";
import RegistExCheckedPng from "../../../../img/icon_tab_registEx.png";
import RegistExNotCheckedPng from "../../../../img/icon_tab_registEx_gray.png";
import FaqCheckedPng from "../../../../img/icon_tab_faq.png";
import FaqNotCheckedPng from "../../../../img/icon_tab_faq_gray.png";

const TabMenu = memo((props: any) => {
  // 助け隊登録データ
  const {
    supporterData,
    setSupporterData,
    inputMode,
    setInputMode,
    location,
    modeRegist,
    setModeRegist,
  } = props;

  return (
    <>
      {inputMode === "助け隊登録" && (
        <div className={`${css.headerTab} ${css.gfs_headerTab}`}>
          <ul className={css.gfs_ul_space}>
            <li
              className={`${css.headerTab_menuIcon} ${
                modeRegist === "REGIST" ? css.active : ""
              }`}
              onClick={() => {
                setModeRegist("REGIST");
              }}
            >
              <span>登録</span>
              <img
                src={
                  modeRegist === "REGIST"
                    ? RegistCheckedPng
                    : RegistNotCheckedPng
                }
                alt=""
              />
            </li>
            <li
              className={`${css.headerTab_menuIcon} ${
                modeRegist === "SAMPLE" ? css.active : ""
              }`}
              onClick={() => {
                setModeRegist("SAMPLE");
              }}
            >
              <span>登録例</span>
              <img
                src={
                  modeRegist === "SAMPLE"
                    ? RegistExCheckedPng
                    : RegistExNotCheckedPng
                }
                alt=""
              />
            </li>
            <li
              className={`${css.headerTab_menuIcon} ${
                modeRegist === "FAQ" ? css.active : ""
              }`}
              onClick={() => {
                setModeRegist("FAQ");
              }}
            >
              <span>FAQ</span>
              <img
                src={modeRegist === "FAQ" ? FaqCheckedPng : FaqNotCheckedPng}
                alt=""
              />
            </li>
          </ul>
        </div>
      )}
      <div className={css.coopbbs_back}>
        <div className={css.coopbbs_block_wrap}>
          {modeRegist === "REGIST" && (
            <Inputs
              supporterData={supporterData}
              setSupporterData={setSupporterData}
              inputMode={inputMode}
              setInputMode={setInputMode}
              location={location}
            />
          )}
          {modeRegist === "SAMPLE" && <AssistSmpl />}
          {modeRegist === "FAQ" && <AssistFaq />}
        </div>
      </div>
    </>
  );
});

export default TabMenu;
