import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import css from "./PersonOrJuridical.module.css";
import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";
import type { ConditionCheckNgWord } from "../../components/apiAccess/master/useCheckNGWord";
import { useCheckNGWord } from "../../components/apiAccess/master/useCheckNGWord";
import ErrorModalOverWordCountPersonOrJuridical from "../../components/ErrorModal/ErrorModalOverWordCountPersonOrJuridical";
import ErrorModalSensitiveWordPersonOrJuridical from "../../components/ErrorModal/ErrorModalSensitiveWordPersonOrJuridical";
import ErrorModalHiraganaCheckPersonOrJuridical from "../../components/ErrorModal/ErrorModalHiraganaCheckPersonOrJuridical";

interface PersonOrJuridicalProps {
  supporterData: SupporterSetData;
  setSupporterData: Dispatch<SetStateAction<SupporterSetData>>;
  setInputMode: Dispatch<SetStateAction<string>>;
}

// 個人・法人
const PersonOrJuridical = memo((props: PersonOrJuridicalProps) => {
  const { supporterData, setSupporterData, setInputMode } = props;

  // 仮設定値
  const [alliliation, setAlliliation] = useState<string>(
    supporterData.alliliation
  );
  const [corporateName, setCorporateName] = useState<string>(
    supporterData.corporate_name
  );
  const [corporateKana, setCorporateKana] = useState<string>(
    supporterData.corporate_kana
  );

  const refCorporateName = useRef<HTMLInputElement>(null!);

  const {
    resultCheckNgWord,
    checkNgWord,
    isLoadingCheckNgWord,
    isErrorCheckNgWord,
  } = useCheckNGWord();

  const [showFlagOverWord, setShowFlagOverWord] = useState<boolean>(false);
  const [showModalSensitiveWord, setShowModalSensitiveWord] =
    useState<boolean>(false);
  const [showModalHiraganaCheck, setShowModalHiraganaCheck] =
    useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // つぎへボタンがタップされたとき
  const handlerOnClickNext = () => {
    if (alliliation === "1") {
      // 法人のとき
      if (corporateName.length > 100 || corporateKana.length > 100) {
        // 文字数チェックダイアログ表示
        setShowFlagOverWord(true);
      } else if (!/^[ぁ-ん]+$/g.test(corporateKana)) {
        // ひらがなチェックダイアログ表示
        setShowModalHiraganaCheck(true);
      } else {
        // NGワードをチェック
        const condition: ConditionCheckNgWord = {
          user_id: supporterData.user_id,
          description: corporateName + corporateKana,
        };
        checkNgWord(condition);
      }
    } else {
      // 個人のとき
      setSupporterData((prev: SupporterSetData) => ({
        ...prev,
        alliliation: "0",
      }));
      setInputMode("助け隊登録");
    }
  };

  // NGワードチェック結果を受信したとき
  useEffect(() => {
    if (resultCheckNgWord === undefined || resultCheckNgWord === null) return;

    if (resultCheckNgWord.result_code === "0") {
      // 正常処理が行われたとき
      setSupporterData((prev: SupporterSetData) => ({
        ...prev,
        alliliation: "1",
        corporate_name: corporateName,
        corporate_kana: corporateKana,
      }));
      setInputMode("助け隊登録");
    } else if (
      resultCheckNgWord.result_code === "1" ||
      resultCheckNgWord.result_code === "9"
    ) {
      // バックエンドで異常処理が発生したとき
      console.log(resultCheckNgWord.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultCheckNgWord.message);
    } else if (resultCheckNgWord.result_code === "2") {
      // NGワードが検出されたとき
      setShowModalSensitiveWord(true);
    }
  }, [resultCheckNgWord]);

  // つぎへボタン
  const SendButton = () => {
    if (alliliation === "0") {
      return (
        <div
          className={`${css.coopbbsButton_wrap} ${css.column}`}
          onClick={handlerOnClickNext}
        >
          <div
            className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
          >
            つぎへ
          </div>
        </div>
      );
    } else if (alliliation === "1" && corporateName && corporateKana) {
      return (
        <div
          className={`${css.coopbbsButton_wrap} ${css.column}`}
          onClick={handlerOnClickNext}
        >
          <div
            className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
          >
            つぎへ
          </div>
        </div>
      );
    } else {
      return (
        <div className={`${css.coopbbsButton_wrap} ${css.column}`}>
          <div
            className={`${css.coopbbsButton} ${css.color_disable} ${css.size_l}`}
          >
            つぎへ
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className={`${css.content_inner} ${css.article_margin}`}>
        <div className={css.subtitle}>
          「個人」または「法人」か教えてください。
        </div>
        <hr />
        <div className={css.registInputboxWrap}>
          <div className={css["registTextbox_title-wrap"]}>
            <span className={css.title}>個人・法人</span>
          </div>
          <div className={css["radioButton_flex-wrap"]}>
            <input
              id="registCorp0"
              type="radio"
              value="registPersonal"
              name="registCorp"
            />
            <label
              className={
                alliliation === "0" ? css.gfs_button_selected : css.radioButton
              }
              onClick={() => {
                setAlliliation((prev) => "0");
              }}
            >
              個人
            </label>
            <input
              id="registCorp1"
              type="radio"
              value="registCorp"
              name="registCorp"
            />
            <label
              className={
                alliliation === "1" ? css.gfs_button_selected : css.radioButton
              }
              onClick={() => {
                setAlliliation((prev) => "1");
              }}
            >
              法人
            </label>
          </div>
          <div
            className={
              alliliation === "1"
                ? css.gfs_juridical_inputed_visible
                : css.gfs_juridical_inputed_invisible
            }
          >
            <div className={css.registInputboxWrap}>
              <div className={css.registTextbox}>
                <div className={css["registTextbox_title-wrap"]}>
                  <div>
                    <span className={css.title}>法人名</span>
                  </div>
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setCorporateName((prev) => e.target.value);
                  }}
                  className={css.registInputText}
                  placeholder=""
                  value={corporateName}
                  ref={refCorporateName}
                />
              </div>
              <div className={css.registTextbox}>
                <div className={css["registTextbox_title-wrap"]}>
                  <div>
                    <span className={css.title}>法人名（ふりがな）</span>
                  </div>
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setCorporateKana((prev) => e.target.value);
                  }}
                  className={css.registInputText}
                  placeholder=""
                  value={corporateKana}
                />
              </div>
            </div>
          </div>
        </div>
        <SendButton />
      </div>
      <ErrorModalOverWordCountPersonOrJuridical
        showFlag={showFlagOverWord}
        setShowModalOverWord={setShowFlagOverWord}
      />
      <ErrorModalSensitiveWordPersonOrJuridical
        showFlag={showModalSensitiveWord}
        setShowModalSensitiveWord={setShowModalSensitiveWord}
      />
      <ErrorModalHiraganaCheckPersonOrJuridical
        showFlag={showModalHiraganaCheck}
        setShowModalSensitiveWord={setShowModalHiraganaCheck}
      />
    </>
  );
});

export default PersonOrJuridical;
