const dayOfWeekStrJP = (props: any) => {
  let yearStr = Number(props.slice(0, 4));
  let monthStr = Number(props.slice(5, 7));
  let dayStr = Number(props.slice(8, 10));
  // console.log(yearStr);
  // console.log(monthStr);
  // console.log(dayStr);
  let jsMonth = monthStr - 1;
  let dayOfWeekStrJP = ["日", "月", "火", "水", "木", "金", "土"];
  let date = new Date(yearStr, jsMonth, dayStr);
  // returnの内容を修正することで、助けられる日時のreturnを変更可
  return dayOfWeekStrJP[date.getDay()];
};

export default dayOfWeekStrJP;
