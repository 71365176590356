import React, { memo } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import css from "./comp_PartsRoute.module.css";
import { ReactComponent as IconClickable } from "../../../../img/iconClickable.svg";

const PartsRoute = memo((props: any) => {
  // 助け隊登録データ
  const { supporterData } = props;
  const { route_from, route_to } = supporterData;

  if (route_from) {
    return (
      <Card>
        <Card.Header className={css.gfs_card_title}>
          <div className={`${css.registPanel_title} ${css.regist}`}>
            行き先ルート・目的地
            <div className={css.gfs_right_pointer}>
              <IconClickable style={{ width: "20px", height: "15px" }} />
            </div>
          </div>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item className={css.gfs_inputedText}>
            <div>
              <span className={css["registPanel_label-s"]}>出発</span>
            </div>
            <div>{route_from}</div>
          </ListGroup.Item>
          <ListGroup.Item className={css.gfs_inputedText}>
            <div>
              <span className={css["registPanel_label-s"]}>到着</span>
            </div>
            <div>{route_to}</div>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    );
  } else {
    return (
      <div className={css.gfs_title_button}>
        <div className={`${css.registPanel_title} ${css.regist}`}>
          行き先ルート・目的地
          <div className={css.gfs_right_pointer}>
            <IconClickable style={{ width: "20px", height: "15px" }} />
          </div>
        </div>
      </div>
    );
  }
});

export default PartsRoute;
