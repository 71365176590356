import React, { Dispatch, SetStateAction, memo } from "react";
import css from "./comp_ButtonCategory.module.css";

interface ButtonCategoryProps {
  categoryName: string;
  category: string[];
  setCategory: Dispatch<SetStateAction<string[]>>;
  selected: boolean;
}

const ButtonCategory = memo((props: ButtonCategoryProps) => {
  const { categoryName, category, setCategory, selected } = props;

  // カテゴリーボタンがタップされたとき
  const changeState = () => {
    if (!selected) {
      // 未選択の場合
      setCategory((prev: string[]) => prev.concat(categoryName));
    } else {
      // 選択中の場合
      const titles: string[] = category.filter(function (inputedWord: string) {
        return inputedWord !== categoryName;
      });
      setCategory((prev: string[]) => titles);
    }
  };

  return (
    <>
      <div className={css.gfs_category_frame}>
        <div
          className={
            selected ? css.gfs_category_box_checked : css.gfs_category_box
          }
          onClick={changeState}
          data-selected={false}
        >
          {categoryName}
        </div>
      </div>
    </>
  );
});

export default ButtonCategory;
