import React, { memo, useContext, useEffect, useRef, useState } from "react";
import css from "./AssistRegist.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import TabMenu from "./comp_TabMenu";
import { ReactComponent as BackArrow } from "../../../../img/backArrow.svg";
import {
  ConditionGetSupporter,
  useGetSupporter,
} from "../../components/apiAccess/thread/useGetSupporter";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import type { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";
import type {
  InputMode,
  RegistClass,
} from "../../components/commonTypes/types";

const AssistRegist = memo((props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);
  // 助け隊登録／更新データの初期値
  const [supporterData, setSupporterData] = useState<SupporterSetData>({
    id: 0,
    user_id: "",
    title: "",
    description: "",
    area_choice: [],
    area_sentense: "",
    supporter_date: "",
    begin_time: "",
    end_time: "",
    alliliation: "2",
    category: [],
    target: [],
    appoint: [],
    corporate_name: "",
    corporate_kana: "",
    route_from: "",
    route_to: "",
  });

  useEffect(() => {
    if (userInfo === undefined || userInfo === null) return;

    setSupporterData((prev) => ({
      ...prev,
      user_id: userInfo?.id,
    }));
  }, [userInfo]);

  // 助け隊取得
  const {
    resultGetSupporter,
    getSupporter,
    isLoadingSupporter,
    isErrorGetSupporter,
  } = useGetSupporter();

  // 登録、登録例、FAQ
  const [modeRegist, setModeRegist] = useState<RegistClass>(
    // "REGIST"
    location.state.modeRegist
  );

  // 入力モード
  const [inputMode, setInputMode] = useState<InputMode>("助け隊登録");

  // 戻るがタップされたとき
  const handlerOnClickBackArrow = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (inputMode === "助け隊登録") {
      if (location && location.state && location.state.fromPage) {
        // 助け隊のレコードIDを受け取った場合は遷移元に戻る
        navigate(location.state.fromPage, {
          state: {
            modeFirst: location.state.modeFirst,
            modeSecond: {
              code: location.state.modeSecond.code,
              selectedId: location.state.recordId,
            },
          },
        });
      } else {
        navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
      }
    } else {
      setInputMode("助け隊登録");
    }
  };

  // 遷移元からユーザー情報を取得したとき
  useEffect(() => {
    if (userInfo === undefined || userInfo === null) return;

    if (location && location.state && location.state.recordId) {
      // 助け隊のレコードIDを受け取った場合は更新モードになり、助け隊を取得する
      const condition: ConditionGetSupporter = {
        user_id: userInfo.id,
        id: location.state.recordId,
      };
      getSupporter(condition);
    } else {
      // 助け隊登録モード向けにユーザーIDをセットする
      // supporterData.user_id = userInfo.id;
    }
  }, [userInfo]);

  // 助け隊登録データを取得したとき
  useEffect(() => {
    if (resultGetSupporter === undefined || resultGetSupporter === null) return;

    const { result } = resultGetSupporter;

    let sDate: string;
    if (result[0].supporter_date) {
      const date = result[0].supporter_date;
      const y = date.substring(0, 4);
      const m = ("0" + date.substring(5, date.indexOf("月"))).slice(-2);
      const d = (
        "0" + date.substring(date.indexOf("月") + 1, date.indexOf("日"))
      ).slice(-2);

      sDate = y + "-" + m + "-" + d;
    }

    const supportDt: SupporterSetData = {
      id: result[0].id,
      user_id: result[0].supporter_id,
      title: result[0].title,
      description: result[0].description,
      area_choice: result[0].area_choice,
      area_sentense: result[0].area_sentense,
      supporter_date: sDate!,
      begin_time: result[0].begin_time,
      end_time: result[0].end_time,
      alliliation: result[0].alliliation,
      category: result[0].category,
      target: result[0].target,
      appoint: result[0].appoint,
      corporate_name: result[0].corporate_name,
      corporate_kana: result[0].corporate_kana,
      route_from: result[0].route_from,
      route_to: result[0].route_to,
    };
    setSupporterData(supportDt);
  }, [resultGetSupporter]);

  // 登録画面内の各設定画面に遷移するごとに上部スクロールする
  useEffect(() => {
    // @ts-ignore
    document.getElementById("inputTitle").scrollIntoView();
  }, [inputMode]);

  return (
    <>
      <div className={css.gfs_background}>
        <div className={css.gfs_content_inner}>
          <div
            className={`${css.pageTitle} ${css["pageTitle_color-regist"]}`}
            id="inputTitle"
          >
            <div onClick={handlerOnClickBackArrow} className={css.backArrow}>
              <BackArrow />
            </div>
            <div>{inputMode}</div>
          </div>
        </div>

        <TabMenu
          supporterData={supporterData}
          setSupporterData={setSupporterData}
          inputMode={inputMode}
          setInputMode={setInputMode}
          location={location}
          modeRegist={modeRegist}
          setModeRegist={setModeRegist}
        />
      </div>
    </>
  );
});

export default AssistRegist;
