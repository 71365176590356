import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useState,
} from "react";
import css from "./Category.module.css";
// import ListTarget from "./Component_ListTarget";
// import ListAppoint from "./Component_ListAppoint";
import { CategoryData } from "./Data_Category";
import ButtonCategory from "./comp_ButtonCategory";
import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";

interface CategoryProps {
  supporterData: SupporterSetData;
  setSupporterData: Dispatch<SetStateAction<SupporterSetData>>;
  setInputMode: Dispatch<SetStateAction<string>>;
}

// カテゴリー
const Category = memo((props: CategoryProps) => {
  const { supporterData, setSupporterData, setInputMode } = props;

  // 仮設定値
  const [category, setCategory] = useState<string[]>(supporterData.category);

  const categoryList: string[] = CategoryData.category;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOnClickNext = () => {
    setSupporterData((prev: SupporterSetData) => ({
      ...prev,
      category: category,
    }));

    setInputMode("助け隊登録");
  };

  const ListCategory = () => {
    return (
      <>
        {categoryList.map((cat: string) => {
          let selected: boolean = false;

          if (category.includes(cat)) {
            selected = true;
          }

          return (
            <div className={css.gfs_category_frame} key={cat}>
              <ButtonCategory
                categoryName={cat}
                category={category}
                setCategory={setCategory}
                selected={selected}
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className={css.registInputboxWrap}>
        <div>
          <div className={css["registTextbox_title-wrap"]}>
            <div>
              <span className={css.title}>カテゴリー</span>
              <span className={css.require}>（必須）</span>
            </div>
          </div>
          <div className={css["tagCloud-wrap"]}>
            <ListCategory />
          </div>
        </div>
        {/* <div>
          <div className={css['registTextbox_title-wrap']}>
            <div>
              <span className={css.title}>助けられる対象</span>
            </div>
          </div>
          <div className={css['tagCloud-wrap']}>
            <ListTarget supporterData={supporterData} />
          </div>
        </div>
        <div>
          <div className={css['registTextbox_title-wrap']}>
            <div>
              <span className={css.title}>待ち合わせ場所</span>
            </div>
          </div>
          <div className={css['tagCloud-wrap']}>
            <ListAppoint supporterData={supporterData} />
          </div>
        </div> */}
      </div>

      {category.length < 1 ? (
        <div>
          <div className={`${css.coopbbsButton_wrap} ${css.column}`}>
            <div
              className={`${css.coopbbsButton} ${css.color_disable} ${css.size_l}`}
            >
              つぎへ
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={`${css.coopbbsButton_wrap} ${css.column}`}
            onClick={handleOnClickNext}
          >
            <div
              className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
            >
              つぎへ
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default Category;
