import { useContext, useEffect } from "react";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import QrCodeMakerFaci from "../../components/qrCodeMaker/QrCodeMakerFaci";

import type { SearchCondition } from "../../components/apiAccess/passport/useGetPassport";
import { useGetPassport } from "../../components/apiAccess/passport/useGetPassport";

const QrGen = (props: any) => {
  const { eventId, qrCodeUpdateFlag } = props;
  const { responseData, getPassport, isSending, isError } = useGetPassport();

  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  useEffect(() => {
    if (userInfo === undefined || userInfo === null) {
      return;
    }

    const condition: SearchCondition = {
      user_id: userInfo.id,
      event_id: eventId,
    };

    getPassport(condition);
  }, [userInfo, qrCodeUpdateFlag]);

  console.log("toggleValue3 = " + qrCodeUpdateFlag);
  return (
    <>
      {!isSending && responseData?.passport && (
        <QrCodeMakerFaci code={responseData?.passport} />
      )}
    </>
  );
};

export default QrGen;
