import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  memo,
} from "react";
import css from "./ActivityArea.module.css";

import KanaColum from "./comp_KanaColum";
import Towns from "./comp_Towns";
import { useGetTown } from "../../components/apiAccess/master/useGetTown";
import FreewordInputForm from "./comp_FreewordInputForm";
import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";
import ErrorModalSensitiveWord from "../../components/ErrorModal/ErrorModalSensitiveWord";
import { useCheckNGWord } from "../../components/apiAccess/master/useCheckNGWord";
import { SelectMode, TownNameList } from "./types";
import ErrorModalOverWordCountTwoHundred from "../../components/ErrorModal/ErrorModalOverWordCountTwoHundred";

interface ActivityAreaProps {
  supporterData: SupporterSetData;
  setSupporterData: Dispatch<SetStateAction<SupporterSetData>>;
  setInputMode: Dispatch<SetStateAction<string>>;
}

// 活動エリア
const ActivityArea = memo((props: ActivityAreaProps) => {
  const { supporterData, setSupporterData, setInputMode } = props;

  // 仮設定値
  const [areaChoice, setAreaChoice] = useState<string[]>(
    supporterData.area_choice
  );
  const [areaSentense, setAreaSentense] = useState<string>(
    supporterData.area_sentense
  );

  // 住所一覧取得のカスタムフック
  const { resultGetTown, getTown, isLoadingTown, isErrorGetTown } =
    useGetTown();
  // 「前橋市全域」、「町名を選択」、「未選択」の状態
  const [selectMode, setSelectMode] = useState<SelectMode>("none");
  // 町名一覧
  const [townNameList, setTownNameList] = useState<TownNameList>();
  // モーダル「不適切な単語〜」の表示／非表示
  const [showModalSensitiveWord, setShowModalSensitiveWord] =
    useState<boolean>(false);
  // モーダル「文字数制限〜」の表示／非表示
  const [showFlagOverWord, setShowFlagOverWord] = useState<boolean>(false);

  // NGワードチェックのカスタムフック
  const {
    resultCheckNgWord,
    checkNgWord,
    isLoadingCheckNgWord,
    isErrorCheckNgWord,
  } = useCheckNGWord();

  // const [townNames, setTownNames] = useState(townNameList.a_gyo);
  const [townNames, setTownNames] = useState<string[]>([]);

  // 初回実行
  useEffect(() => {
    // 住所一覧取得
    getTown();

    if (areaChoice.length === 1 && areaChoice[0] === "前橋市全域") {
      setSelectMode("entireCity");
    } else if (areaChoice.length > 0) {
      setSelectMode("town");
    }
  }, []);

  // 住所一覧取得結果を受信したとき
  useEffect(() => {
    if (resultGetTown === undefined || resultGetTown === null) return;

    const townNameList: TownNameList = {
      a_gyo: [],
      ka_gyo: [],
      sa_gyo: [],
      ta_gyo: [],
      na_gyo: [],
      ha_gyo: [],
      ma_gyo: [],
      ya_gyo: [],
      ra_gyo: [],
      wa_gyo: [],
    };

    resultGetTown.result.forEach((town) => {
      if (/[あいうえお]/.test(town.headchar)) {
        townNameList.a_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[かきくけこ]/.test(town.headchar)) {
        townNameList.ka_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[さしすせそ]/.test(town.headchar)) {
        townNameList.sa_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[かきくけこ]/.test(town.headchar)) {
        townNameList.ka_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[さしすせそ]/.test(town.headchar)) {
        townNameList.sa_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[たちつてと]/.test(town.headchar)) {
        townNameList.ta_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[なにぬねの]/.test(town.headchar)) {
        townNameList.na_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[はひふへほ]/.test(town.headchar)) {
        townNameList.ha_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[まみむめも]/.test(town.headchar)) {
        townNameList.ma_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[やゆよ]/.test(town.headchar)) {
        townNameList.ya_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[らりるれろ]/.test(town.headchar)) {
        townNameList.ra_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      } else if (/[わをん]/.test(town.headchar)) {
        townNameList.wa_gyo.push(`${town.townname}（${town.townname_rubi}）`);
      }
    });
    setTownNameList(townNameList);
  }, [resultGetTown]);

  //  町名選択を反映する
  const setTowns = () => {
    if (selectMode === "entireCity") {
      setSupporterData((prev: SupporterSetData) => ({
        ...prev,
        area_choice: ["前橋市全域"],
      }));
    } else {
      setSupporterData((prev: SupporterSetData) => ({
        ...prev,
        area_choice: areaChoice,
      }));
    }
  };

  // 「つぎへ」がタップされたとき
  const handlerOnClickNext = () => {
    if (areaSentense.length > 0) {
      // フリーワードが入力されているとき

      // 文字数超過チェック実施
      if (areaSentense.length > 200) {
        setShowFlagOverWord(true);
        return;
      }

      // NGワードチェック
      checkNgWord({
        user_id: supporterData.user_id,
        description: areaSentense,
      });
    } else {
      // フリーワードが入力されていないとき
      setTowns();
      setInputMode("助け隊登録");
    }
  };

  // NGワードチェックの結果を受信したとき
  useEffect(() => {
    if (resultCheckNgWord === undefined || resultCheckNgWord === null) return;

    if (resultCheckNgWord.result_code === "0") {
      setSupporterData((prev: SupporterSetData) => ({
        ...prev,
        area_sentense: areaSentense,
      }));
      setTowns();
      setInputMode("助け隊登録");
    } else if (
      resultCheckNgWord.result_code === "1" ||
      resultCheckNgWord.result_code === "9"
    ) {
      console.log(resultCheckNgWord.message);
    } else if (resultCheckNgWord.result_code === "2") {
      setShowModalSensitiveWord(true);
    }
  }, [resultCheckNgWord]);

  return (
    <>
      <div className={css.registInputboxWrap}>
        <div>
          <div className={css["registTextbox_title-wrap"]}>
            <span className={css.title}>エリア選択</span>
            <span className={css.require}>（必須）</span>
            <div className={css.requireText}></div>
          </div>
          <div className={css["radioButton_flex-wrap"]}>
            <input
              id="selectArea0"
              type="radio"
              value="allAreaMaebashi"
              name="selectArea"
            />
            <label
              className={`${css.radioButton} ${
                selectMode === "entireCity" ? css.gfs_radioButton_checked : ""
              }`}
              onClick={() => setSelectMode("entireCity")}
            >
              前橋市全域
            </label>
            <input
              id="selectArea1"
              type="radio"
              value="selectionSubCity"
              name="selectArea"
            />
            <label
              className={`${css.radioButton} ${
                selectMode === "town" ? css.gfs_radioButton_checked : ""
              }`}
              onClick={() => {
                if (selectMode === "entireCity") {
                  setAreaChoice((prev) => []);
                }
                setSelectMode("town");
              }}
            >
              町名を選択
            </label>
          </div>
        </div>
        {selectMode === "town" && townNameList && (
          <>
            <KanaColum
              townNameList={townNameList!}
              townNames={townNames}
              setTownNames={setTownNames}
              selectMode={selectMode}
            />
            <Towns
              townNames={townNames}
              areaChoice={areaChoice}
              setAreaChoice={setAreaChoice}
            />
            <div>
              <div>選択された町名を表示</div>
              <b>{areaChoice.join("、")}</b>
            </div>
          </>
        )}
        <FreewordInputForm
          areaSentense={areaSentense}
          setAreaSentense={setAreaSentense}
        />
        <div className={css.gfs_error_messages_invisible}>
          <div className={css.gfs_error_messages_freeword}>
            文字数制限を超えています。200文字以内で入力してください
          </div>
        </div>
        {selectMode === "entireCity" ||
        (selectMode === "town" && areaChoice.length > 0) ? (
          <div
            className={`${css.coopbbsButton_wrap} ${css.column}`}
            onClick={handlerOnClickNext}
          >
            <div
              className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
            >
              つぎへ
            </div>
          </div>
        ) : (
          <div className={`${css.coopbbsButton_wrap} ${css.column}`}>
            <div
              className={`${css.coopbbsButton} ${css.color_disable} ${css.size_l}`}
            >
              つぎへ
            </div>
          </div>
        )}
      </div>
      <ErrorModalOverWordCountTwoHundred
        showFlag={showFlagOverWord}
        setShowModalOverWord={setShowFlagOverWord}
      />
      <ErrorModalSensitiveWord
        showFlag={showModalSensitiveWord}
        setShowModalSensitiveWord={setShowModalSensitiveWord}
      />
    </>
  );
});

export default ActivityArea;
