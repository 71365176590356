import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MatchList from "./comp_MatchList";
import CloseList from "./comp_CloseList";
import OpenList from "./comp_OpenList";
import OpenOffer from "./comp_OpenOffer";
import Assist from "../../components/assistDetail/Assist";
import DialogConfirm from "../../components/dialogConfirm/DialogConfirm";
import { initialConfirm } from "../../components/dialogConfirm/initials";
import type { ModeFirst, ModeSecond } from "../../components/commonTypes/types";
import type { Confirm } from "../../components/dialogConfirm/types";

import { UserInfoContext } from "../../components/providers/userInfoProvider";
import {
  ConditionCancel,
  useCancelSupporter,
} from "../../components/apiAccess/thread/useCancelSupporter";
import type { ConditionIssueThanks } from "../../components/apiAccess/thread/useIssueThanks";
import { useIssueThanks } from "../../components/apiAccess/thread/useIssueThanks";
import {
  ConditionDeclineThanks,
  useDeclineThanks,
} from "../../components/apiAccess/thread/useDeclineThanks";

import css from "./MyAssistList.module.css";

// 自分の助け隊一覧
const MyAssistList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [modeFirst, setModeFirst] = useState<ModeFirst>(
    location.state.modeFirst
  );
  const [modeSecond, setModeSecond] = useState<ModeSecond>({
    code: "LIST",
    selectedId: 0,
    fromCode: "LIST",
    fromSelectedId: 0,
  });
  const [confirm, setConfirm] = useState<Confirm>(initialConfirm);

  // 助け隊登録キャンセル
  const {
    resultCancelSupporterRegist,
    sendCancelSupporterRegist,
    isLoadingCancelSupporterRegist,
    isErrorCancelSupporterRegist,
  } = useCancelSupporter();

  const {
    resultIssueThanks,
    sendIssueThanks,
    isLoadingIssueThanks,
    isErrorIssueThanks,
  } = useIssueThanks();

  const {
    resultDeclineThanks,
    sendDeclineThanks,
    isLoadingDeclineThanks,
    isErrorDeclineThanks,
  } = useDeclineThanks();

  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // 助け隊登録キャンセルの結果を受けたとき
  useEffect(() => {
    if (resultCancelSupporterRegist?.result_code === "0") {
      setConfirm(initialConfirm);

      setModeFirst((prev: ModeFirst) => ({ ...prev, code: "CLOSE" }));
      setModeSecond((prev: ModeSecond) => ({ ...prev, code: "LIST" }));
    } else if (resultCancelSupporterRegist?.result_code === "1") {
      console.log(resultCancelSupporterRegist?.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultCancelSupporterRegist?.message);
    }
  }, [resultCancelSupporterRegist]);

  // ありが隊許諾の結果を受けたとき
  useEffect(() => {
    if (
      resultIssueThanks?.result_code === "0" ||
      resultIssueThanks?.result_code === null
    ) {
      setConfirm(initialConfirm);

      setModeFirst((prev: ModeFirst) => ({ ...prev, code: "MATCH" }));
      setModeSecond((prev: ModeSecond) => ({ ...prev, code: "LIST" }));
    } else if (resultIssueThanks?.result_code === "1") {
      console.log(resultIssueThanks?.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultIssueThanks?.message);
    }
  }, [resultIssueThanks]);

  // ありが隊辞退の結果を受けたとき
  useEffect(() => {
    if (resultDeclineThanks?.result_code === "0") {
      setConfirm(initialConfirm);

      setModeFirst((prev: ModeFirst) => ({ ...prev, code: "OPEN" }));
      setModeSecond((prev: ModeSecond) => ({ ...prev, code: "OFFER" }));
    } else if (resultDeclineThanks?.result_code === "1") {
      console.log(resultDeclineThanks?.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultDeclineThanks?.message);
    }
  }, [resultDeclineThanks]);

  // ダイアログの「YES/NO」を受けたとき
  useEffect(() => {
    if (confirm.answer === "YES" && modeFirst.code === "OPEN") {
      if (modeSecond.code === "LIST" || modeSecond.code === "DETAIL") {
        // 助け隊登録のキャンセル
        const condition: ConditionCancel = {
          id: confirm.recordId!,
          user_id: userInfo?.id!,
        };
        sendCancelSupporterRegist(condition);
      } else if (modeSecond.code === "OFFER") {
        if (confirm.type === "SUPPORT") {
          // ありが隊許諾
          const condition: ConditionIssueThanks = {
            id: confirm.recordId,
            user_id: userInfo?.id!,
            request_user_id: confirm?.userId!,
          };
          sendIssueThanks(condition);
        } else if (confirm.type === "REFUSAL") {
          // ありが隊辞退
          const condition: ConditionDeclineThanks = {
            id: confirm.recordId,
            user_id: userInfo?.id!,
            request_user_id: confirm?.userId!,
          };
          sendDeclineThanks(condition);
        }
      }
    } else if (confirm.answer === "NO") {
      setConfirm(initialConfirm);
    }
  }, [confirm]);

  useEffect(() => {
    if (modeFirst.code === "OPEN" && modeSecond.code === "EDIT") {
      // 修正画面に遷移する
      navigate(process.env.REACT_APP_PATH_PREFIX + "assistRegist", {
        state: {
          myId: userInfo?.id,
          recordId: modeSecond.fromSelectedId,
          fromPage: process.env.REACT_APP_PATH_PREFIX + "myAssistList",
          modeFirst: {
            class: "ASSIST",
            code: "OPEN",
          },
          modeSecond: {
            code: "DETAIL",
          },
          modeRegist: "REGIST",
        },
      });
    }
  }, [modeFirst.code, modeSecond.code]);

  return (
    <>
      <div className={css.coopbbs_back}>
        {modeFirst.code === "OPEN" && modeSecond.code === "LIST" && (
          <OpenList
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
            setConfirm={setConfirm}
          />
        )}
        {modeFirst.code === "OPEN" && modeSecond.code === "OFFER" && (
          <OpenOffer
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
            confirm={confirm}
            setConfirm={setConfirm}
          />
        )}
        {modeFirst.code === "MATCH" && modeSecond.code === "LIST" && (
          <MatchList
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
          />
        )}
        {modeFirst.code === "CLOSE" && modeSecond.code === "LIST" && (
          <CloseList
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
          />
        )}
        {modeSecond.code === "DETAIL" && (
          <Assist
            userId={userInfo?.id}
            selectedId={modeSecond.selectedId}
            modeFirst={modeFirst}
            setModeFirst={setModeFirst}
            modeSecond={modeSecond}
            setModeSecond={setModeSecond}
            setConfirm={setConfirm}
          />
        )}
      </div>
      {confirm.isVisible && (
        <DialogConfirm confirm={confirm} setConfirm={setConfirm} />
      )}
    </>
  );
};

export default MyAssistList;
