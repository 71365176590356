import css from "./comp_OpenItems.module.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconComment from "../../../../img/icon_comment.svg";
import iconIntroduction from "../../../../img/icon_introduction.svg";
import {
  ModeSecond,
  SupporterListWithVisible,
  SupporterWithVisible,
} from "../../components/commonTypes/types";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Confirm } from "../../components/dialogConfirm/types";

const OthesAssistMeOpenItems = (props: any) => {
  const { supporterList, setSupporterList, setModeSecond, setConfirm } = props;

  const navigate = useNavigate();
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // タイトルがタップされたとき
  const handlerOnClickTitle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.recordid?.split("_")?.[0];
    const mode: ModeSecond = {
      code: "DETAIL",
      selectedId: Number(selectedId),
      fromCode: "LIST",
      fromSelectedId: Number(e.currentTarget.dataset.recordid),
    };
    setModeSecond(mode);
  };

  // 「助け隊の紹介文」がタップされたとき
  const handlerOnClickIntro = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const recordid: string = e.currentTarget.dataset.recordid!;
    if (recordid) {
      const copyList = supporterList.result.slice(
        0,
        supporterList.result.length
      );

      copyList.map((value: SupporterWithVisible) => {
        if (value.id === recordid) {
          value.isVisibleIntro = !value.isVisibleIntro;
        }
      });

      setSupporterList((prev: SupporterListWithVisible) => ({
        ...prev,
        list: copyList,
      }));
    }
  };

  // 「助け隊へ送ったコメント」がタップされたとき
  const handlerOnClickComment = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const recordid: string = e.currentTarget.dataset.recordid!;
    if (recordid) {
      const copyList = supporterList.result.slice(
        0,
        supporterList.result.length
      );

      copyList.map((value: SupporterWithVisible) => {
        if (value.id === recordid) {
          value.isVisibleComment = !value.isVisibleComment;
        }
      });
      setSupporterList((prev: SupporterWithVisible) => ({
        ...prev,
        list: copyList,
      }));
    }
  };

  // 「申し込みを取りやめる」がタップされたとき
  const handlerOnClickCancel = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.recordid?.split("_")?.[0];
    const cnf: Confirm = {
      isVisible: true,
      type: "OFFER_CANCEL",
      answer: "UNDONE",
      recordId: Number(selectedId),
    };
    setConfirm(cnf);
  };

  console.dir(supporterList.result);
  return (
    <div className={css.boardPanel_container}>
      <>
        {supporterList &&
          supporterList.result &&
          supporterList.result.length > 0 &&
          supporterList.result.map((data: SupporterWithVisible) => {
            return (
              <div className={css.boardPanel_wrap} key={data.id}>
                <div
                  className={`${css.boardPanel_title} ${css.myrequest} ${css.clickable}`}
                  onClick={handlerOnClickTitle}
                  data-recordid={data.id}
                >
                  {data.title}
                  <div className={css.gfs_arrow_position}>
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </div>
                </div>
                <div className={css.boardPanel_2column}>
                  <span className={css["boardPanel_date-label"]}>日時</span>
                  <div className={css.boardPanel_date}>
                    <span>{data.supporter_date}</span>
                    <span>
                      {data.begin_time}〜{data.end_time}
                    </span>
                  </div>
                </div>
                <hr className={css.boardPanel_divider} />
                <div className={css.boardPanel_profileAction}>
                  <div className={css.boardPanel_nameProfile}>
                    {/* <div className={css.boardPanel_nameIcon}>
                        <img
                          src={"data:image/jpg;base64," + data.image}
                          width="50"
                          height="50"
                          alt=""
                        />
                      </div> */}
                    <span>{data.nickname}</span>
                  </div>
                  <div
                    className={`${css.boardPanel_accordionDetail} ${css.myrequest}`}
                  >
                    <div
                      className={`${css.boardPanel_accordionLabel} ${css["js-accordion"]}`}
                      // data-index={index}
                      data-recordid={data.id}
                      onClick={handlerOnClickIntro}
                    >
                      <img src={iconIntroduction} width="30" alt="" />
                      <span>助け隊の紹介文</span>
                    </div>
                    <div
                      className={`${css["boardPanel_accordionDetail-text"]} ${
                        data.isVisibleIntro ? css.open : ""
                      }`}
                    >
                      <div className={css["boardPanel_accordionDetail-item"]}>
                        <div>{data.introduction}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${css.boardPanel_accordionDetail} ${css.myrequest}`}
                  >
                    <div
                      className={`${css.boardPanel_accordionLabel} ${css["js-accordion"]}`}
                      // data-index={index}
                      data-recordid={data.id}
                      onClick={handlerOnClickComment}
                    >
                      <img src={iconComment} width="30" alt="" />
                      <span>助け隊へ送ったコメント</span>
                    </div>
                    <div
                      className={`${css["boardPanel_accordionDetail-text"]} ${
                        data.isVisibleComment ? css.open : ""
                      }`}
                    >
                      <div className={css["boardPanel_accordionDetail-item"]}>
                        <div>{data.comment}</div>
                        <span
                          className={css["boardPanel_accordionDetail-sendDate"]}
                        >
                          送信日
                          <br />
                          {data.request_datetime}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className={css.boardPanel_divider} />
                <div
                  className={`${css.boardPanel_item} ${css.clickable}`}
                  onClick={handlerOnClickCancel}
                  data-recordid={data.id}
                >
                  <div className={css["boardPanel_action-cancel"]}>
                    申し込みを取りやめる
                  </div>
                </div>
              </div>
            );
          })}
      </>
    </div>
  );
};

export default OthesAssistMeOpenItems;
