import React, { useState } from "react";
import css from "./AssistSmpl.module.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { ReactComponent as IconClickable } from "../../../../img/iconClickable.svg";
import rightArrow_pink from "../../../../img/rightArrow-pink.svg";
import leftArrow_pink from "../../../../img/leftArrow-pink.svg";
import { DummyData_smpl } from "./DummyData_smpl";

const smplData = DummyData_smpl;
function AssistSmpl() {
  const [smplNum, setSmplNum] = useState(0);
  const nextSmpl = () => {
    let counter = smplNum;
    counter++;
    if (counter > smplData.length - 1) {
      counter = 0;
    }
    setSmplNum(counter);
  };
  const backSmpl = () => {
    let counter = smplNum;
    counter--;
    if (counter < 0) {
      counter = smplData.length - 1;
    }
    setSmplNum(counter);
  };
  const PersonOrJuridical = () => {
    if (smplData[smplNum].alliliation === "個人") {
      return (
        <div>
          <Card className={css.gfs_assistcard}>
            <Card.Header className={css.gfs_card_title}>
              <div className={`${css.registPanel_title} ${css.regist}`}>
                法人・個人<span className={css.require}>（必須）</span>
                <div className={css.gfs_right_pointer}>
                  <IconClickable style={{ width: "20px", height: "15px" }} />
                </div>
              </div>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item className={css.gfs_inputedText}>
                <div>{smplData[smplNum].alliliation}</div>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
      );
    } else {
      return (
        <div>
          <Card className={css.gfs_assistcard}>
            <Card.Header className={css.gfs_card_title}>
              <div className={`${css.registPanel_title} ${css.regist}`}>
                法人・個人<span className={css.require}>（必須）</span>
                <div className={css.gfs_right_pointer}>
                  <IconClickable style={{ width: "20px", height: "15px" }} />
                </div>
              </div>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item className={css.gfs_inputedText}>
                {smplData[smplNum].alliliation}
              </ListGroup.Item>
              <ListGroup.Item className={css.gfs_inputedText}>
                <div>
                  <span className={css["registPanel_label-s"]}>会社名</span>
                </div>
                <div>{smplData[smplNum].corporatename}</div>
              </ListGroup.Item>
              <ListGroup.Item className={css.gfs_inputedText}>
                <div>
                  <span className={css["registPanel_label-s"]}>
                    会社名（ひらがな）
                  </span>
                </div>
                <div>{smplData[smplNum].corporatename_kana}</div>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <div className={css["registPanels_swipe-wrap"]}>
          <div className={css.registPanels_swipe}>
            <div onClick={backSmpl}>
              <img src={leftArrow_pink} alt="" />
            </div>
            <span className={css["registPanels_swipe-title"]}>
              {smplData[smplNum].smpl_title}
            </span>
            <div onClick={nextSmpl}>
              <img src={rightArrow_pink} alt="" />
            </div>
          </div>
          <hr className={css.divider} />
        </div>
        <div className={`${css.registTextbox} ${css.gfs_registTextbox}`}>
          <div className={css["registTextbox_title-wrap"]}>
            <div>
              <span className={css.title}>タイトル</span>
              <span className={css.require}>（必須）</span>
            </div>
            <div className={css.requireText}>30文字以内</div>
          </div>
          <input
            className={css.gfs_textarea_title}
            value={smplData[smplNum].title}
            readOnly={true}
          />
        </div>
        <div className={css["registTextbox_title-wrap"]}>
          <div>
            <span className={css.title}>内容と詳細</span>
            <span className={css.require}>（必須）</span>
          </div>
          <div className={css.requireText}>500文字以内</div>
        </div>
        <textarea
          className={css.gfs_textarea_sentence}
          value={smplData[smplNum].detail}
          readOnly={true}
        />
      </div>
      <div>
        <div>
          <Card className={css.gfs_assistcard}>
            <Card.Header className={css.gfs_card_title}>
              <div className={`${css.registPanel_title} ${css.regist}`}>
                活動エリア<span className={css.require}>（必須）</span>
                <div className={css.gfs_right_pointer}>
                  <IconClickable style={{ width: "20px", height: "15px" }} />
                </div>
              </div>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div className={css.gfs_inputedText}>
                  {smplData[smplNum].area_choice}
                </div>
                <div className={css.gfs_inputedText}>
                  {smplData[smplNum].area_sentense}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>

        <div>
          <Card className={css.gfs_assistcard}>
            <Card.Header className={css.gfs_card_title}>
              <div className={`${css.registPanel_title} ${css.regist}`}>
                日時<span className={css.require}>（必須）</span>
                <div className={css.gfs_right_pointer}>
                  <IconClickable style={{ width: "20px", height: "15px" }} />
                </div>
              </div>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item className={css.gfs_inputedText}>
                {smplData[smplNum].date}　{smplData[smplNum].time_from}〜
                {smplData[smplNum].time_to}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>

        <div>
          <Card className={css.gfs_assistcard}>
            <Card.Header className={css.gfs_card_title}>
              <div className={`${css.registPanel_title} ${css.regist}`}>
                カテゴリー<span className={css.require}>（必須）</span>
                <div className={css.gfs_right_pointer}>
                  <IconClickable style={{ width: "20px", height: "15px" }} />
                </div>
              </div>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item className={css.gfs_inputedText}>
                {smplData[smplNum].category}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>

        <PersonOrJuridical />

        <div>
          <Card className={css.gfs_assistcard}>
            <Card.Header className={css.gfs_card_title}>
              <div className={`${css.registPanel_title} ${css.regist}`}>
                行き先ルート・目的地
                <div className={css.gfs_right_pointer}>
                  <IconClickable style={{ width: "20px", height: "15px" }} />
                </div>
              </div>
            </Card.Header>
            {smplData[smplNum].root_from !== "" && (
              <ListGroup variant="flush">
                <ListGroup.Item className={css.gfs_inputedText}>
                  <div>
                    <span className={css["registPanel_label-s"]}>出発</span>
                  </div>
                  <div>{smplData[smplNum].root_from}</div>
                </ListGroup.Item>
                <ListGroup.Item className={css.gfs_inputedText}>
                  <div>
                    <span className={css["registPanel_label-s"]}>到着</span>
                  </div>
                  <div>{smplData[smplNum].root_to}</div>
                </ListGroup.Item>
              </ListGroup>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AssistSmpl;
