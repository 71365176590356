import { useContext, useEffect, useRef, useState } from "react";
import css from "./comp_CloseList.module.css";

import selectboxArrow from "../../../../img/selectboxArrow.svg";

import type { ConditionSearchThanks } from "../../components/apiAccess/thread/searchThanksDataAccess";
import { searchThanks } from "../../components/apiAccess/thread/searchThanksDataAccess";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import AssistTitle from "../../components/assistTitle/AssistTitle";
import InfiniteScroll from "react-infinite-scroller";
import OthersAssistMeCloseItems from "./comp_CloseItems";
import type {
  SupporterListWithVisible,
  SupporterWithVisible,
} from "../../components/commonTypes/types";

// 「みんなの助け隊」クローズ
const CloseList = (props: any) => {
  const { modeFirst, setModeFirst, modeSecond, setModeSecond } = props;

  const refOrder = useRef<HTMLSelectElement>(null!);

  // 現在のページ
  const refPage = useRef<number>(0);
  // 読み込むページの有無
  const refHasMore = useRef<boolean>(true);
  // データ取得中フラグ
  const refIsLoading = useRef<boolean>(false);
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // ありが隊の取得条件
  const [condition, setCondition] = useState<ConditionSearchThanks>({
    user_id: userInfo?.id!,
    owner_class: 0,
    matching_class: 3,
    id: 0,
    order_column: 0,
    order: 0,
    page: 0,
  });

  // ありが隊取得前の初期値
  const [supporterList, setSupporterList] = useState<SupporterListWithVisible>({
    result_code: "",
    message: "",
    result: [],
    count: 0,
    page: 0,
    page_total: 0,
  });

  // 無限スクロールコンテナの高さ
  const [containerHeight, setContainerHeight] = useState({});

  // 初回レンダリング実行時 クローズ
  useEffect(() => {
    console.log("「自分のありが隊」クローズ 初回レンダリング実行");

    // 無限スクロールコンテナの高さを設定
    setContainerHeight({
      height: window.innerHeight + "px",
    });
  }, []);

  // ありが隊取得
  const fetch = (cnd: ConditionSearchThanks) => {
    refIsLoading.current = true;

    searchThanks(cnd)
      .then((response: any) => {
        if (response == null || response.data == null) return;

        let tempList: Array<SupporterWithVisible> = [];
        response.data.result.forEach((data: any) => {
          const tempCard: SupporterWithVisible = {
            ...data,
            isVisibleIntro: false,
          };
          tempList.push(tempCard);
          refIsLoading.current = false;
        });

        if (response.data.page === "1") {
          // データを入れ替える
          const supportListWithVisible: SupporterListWithVisible = {
            ...response.data,
            result: tempList,
            page: 1,
          };
          setSupporterList(supportListWithVisible);
        } else {
          // 取得済みデータと結合する
          setSupporterList((prev: SupporterListWithVisible) => ({
            ...prev,
            result: prev.result.concat(response.data.result),
            page: response.data.page,
          }));
        }

        if (response.data.page === response.data.page_total) {
          refHasMore.current = false;
        } else {
          refHasMore.current = true;
        }
        refIsLoading.current = false;
      })
      .catch((e) => {
        console.dir(e);
        process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
      });
  };

  // 1ページ単位でデータを取得する
  const loadSupport = async () => {
    console.log("called: loadSupport funtcion.");
    if (refIsLoading.current) return;

    if (userInfo === undefined || userInfo === null) {
      return;
    }

    refPage.current++;
    const cnd: ConditionSearchThanks = {
      ...condition,
      page: refPage.current,
    };
    fetch(cnd);
  };

  // 「新しい登録順」／「古い登録順」が変わったとき
  const handlerOnChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();

    let order: number = 0;
    if (e.currentTarget.value === "old") {
      order = 1;
    }

    setCondition((prev: ConditionSearchThanks) => ({
      ...prev,
      order: order,
    }));

    refPage.current = 1;
    const cnd: ConditionSearchThanks = {
      ...condition,
      page: refPage.current,
      order: order,
    };
    fetch(cnd);
  };

  return (
    <>
      <div className={css.boardPanel_outer_container} style={containerHeight}>
        <AssistTitle
          modeFirst={modeFirst}
          setModeFirst={setModeFirst}
          modeSecond={modeSecond}
          setModeSecond={setModeSecond}
        />
        <InfiniteScroll
          loadMore={loadSupport}
          hasMore={refHasMore.current}
          useWindow={false}
        >
          <div className={`${css.selectBoxWrap} ${css.adjust_mt}`}>
            <select
              onChange={handlerOnChangeSelect}
              style={{ backgroundImage: `url(${selectboxArrow})` }}
              disabled={refIsLoading.current}
              ref={refOrder}
            >
              <option value="new">新しい順</option>
              <option value="old">古い順</option>
            </select>
          </div>
          <OthersAssistMeCloseItems
            supporterList={supporterList}
            setSupporterList={setSupporterList}
            setModeSecond={setModeSecond}
          />
        </InfiniteScroll>
      </div>
    </>
  );
};

export default CloseList;
