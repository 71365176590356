import React, { useState, useEffect } from "react";
// import "./mebuku_assistRegist.css";
import css from "./comp_Inputs.module.css";
import { useNavigate } from "react-router-dom";
import { useSetSupporter } from "../../components/apiAccess/thread/useSetSupporter";
import PartsActivityArea from "./comp_PartsActivityArea";
import PartsDateTime from "./comp_PartsDateTime";
import PartsCategory from "./comp_PartsCategory";
import PartsRoute from "./comp_PartsRoute";
import PartsAlliliation from "./comp_PartsAlliliation";
import TextForm from "./comp_TextForm";
import ActivityArea from "../activityArea/ActivityArea";
import DateTime from "../dateTime/DateTime";
import Category from "../category/Category";
import DestRoute from "../destRoute/DestRoute";
import PersonOrJuridical from "../personOrJuridical/PersonOrJuridical";
import ErrorModalOverWordCountAssistRegist from "../../components/ErrorModal/ErrorModalOverWordCountAssistRegist";
import ErrorModalSensitiveWordAssistRegist from "../../components/ErrorModal/ErrorModalSensitiveWordAssistRegist";

function Inputs(props: any) {
  // 助け隊登録データ
  const { supporterData, setSupporterData, inputMode, setInputMode, location } =
    props;
  // 助け隊登録
  const {
    resultSetSupporter,
    setSupporter,
    isSendingSupporter,
    isErrorSetSupporter,
  } = useSetSupporter();

  const [showErrorModalOverWord, setShowErrorModalOverWord] =
    useState<boolean>(false);
  const [showErrorModalSensitiveWord, setShowErrorModalSensitiveWord] =
    useState<boolean>(false);

  const [enableRegist, setEnableRegist] = useState<boolean>(false);

  // let registButton = true;
  // let registButtonClickable = "coopbbsButton gfs_color_gray size_l";

  const navigate = useNavigate();

  // 「登録する」ボタンがタップされたとき
  const assistRegist = () => {
    console.dir(supporterData);
    const { title, description } = supporterData;
    // タイトルと内容の文字数チェック
    if (title.length > 30 || description.length > 500) {
      // 文字数超えのダイアログを表示
      setShowErrorModalOverWord(true);
    } else {
      console.dir(supporterData);
      // 助け隊登録
      setSupporter(supporterData);
    }
  };

  // 助け隊登録結果を受けたとき
  useEffect(() => {
    if (resultSetSupporter === undefined || resultSetSupporter === null) return;

    if (resultSetSupporter.result_code === "0") {
      if (location && location.state && location.state.fromPage) {
        // 助け隊のレコードIDを受け取った場合は遷移元に戻る
        navigate(location.state.fromPage, {
          state: {
            modeFirst: location.state.modeFirst,
            modeSecond: {
              code: location.state.modeSecond.code,
              selectedId: location.state.recordId,
            },
          },
        });
      } else {
        navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
      }
    } else if (
      resultSetSupporter.result_code === "1" ||
      resultSetSupporter.result_code === "9"
    ) {
      // 登録失敗の場合はアラート表示
      console.log(resultSetSupporter.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSetSupporter.message);
    } else if (resultSetSupporter.result_code === "2") {
      // NGワードのダイアログ表示
      setShowErrorModalSensitiveWord(true);
    }
  }, [resultSetSupporter]);

  useEffect(() => {
    if (
      supporterData.title &&
      supporterData.description &&
      supporterData.area_choice &&
      supporterData.supporter_date &&
      supporterData.begin_time &&
      supporterData.end_time &&
      supporterData.category.length > 0 &&
      (supporterData.alliliation === "0" || supporterData.alliliation === "1")
    ) {
      console.log("登録ボタン有効");
      setEnableRegist(true);
    } else {
      console.log("登録ボタン無効");
      setEnableRegist(false);
    }
  }, [supporterData]);

  return (
    <>
      {inputMode === "助け隊登録" && (
        <>
          <div className={css.registPanels_flex}>
            <TextForm
              supporterData={supporterData}
              setSupporterData={setSupporterData}
            />
            <div
              onClick={() => {
                setInputMode("活動エリア");
              }}
            >
              <PartsActivityArea supporterData={supporterData} />
            </div>
            <div
              onClick={() => {
                setInputMode("日時");
              }}
            >
              <PartsDateTime supporterData={supporterData} />
            </div>
            <div
              onClick={() => {
                setInputMode("カテゴリー");
              }}
            >
              <PartsCategory supporterData={supporterData} />
            </div>
            <div
              onClick={() => {
                setInputMode("個人・法人");
              }}
            >
              <PartsAlliliation supporterData={supporterData} />
            </div>
            <div
              onClick={() => {
                setInputMode("行き先・ルート");
              }}
            >
              <PartsRoute supporterData={supporterData} />
            </div>
          </div>
          <div
            className={`${css.coopbbsButton_wrap} ${css.column}`}
            onClick={assistRegist}
          >
            <div
              className={`${css.coopbbsButton} ${
                enableRegist ? css.color_pink : css.gfs_color_gray
              } ${css.size_l}`}
            >
              登録する
            </div>
          </div>
        </>
      )}
      {inputMode === "活動エリア" && (
        <ActivityArea
          supporterData={supporterData}
          setSupporterData={setSupporterData}
          setInputMode={setInputMode}
        />
      )}
      {inputMode === "日時" && (
        <DateTime
          supporterData={supporterData}
          setSupporterData={setSupporterData}
          setInputMode={setInputMode}
        />
      )}
      {inputMode === "カテゴリー" && (
        <Category
          supporterData={supporterData}
          setSupporterData={setSupporterData}
          setInputMode={setInputMode}
        />
      )}
      {inputMode === "個人・法人" && (
        <PersonOrJuridical
          supporterData={supporterData}
          setSupporterData={setSupporterData}
          setInputMode={setInputMode}
        />
      )}
      {inputMode === "行き先・ルート" && (
        <DestRoute
          supporterData={supporterData}
          setSupporterData={setSupporterData}
          setInputMode={setInputMode}
        />
      )}
      <ErrorModalOverWordCountAssistRegist
        showFlag={showErrorModalOverWord}
        setShowModalOverWord={setShowErrorModalOverWord}
      />
      <ErrorModalSensitiveWordAssistRegist
        showFlag={showErrorModalSensitiveWord}
        setShowModalSensitiveWord={setShowErrorModalSensitiveWord}
      />
    </>
  );
}

export default Inputs;
