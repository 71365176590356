import React, { memo } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import css from "./comp_PartsAlliliation.module.css";
import { ReactComponent as IconClickable } from "../../../../img/iconClickable.svg";

const PartsAlliliation = memo((props: any) => {
  // 助け隊登録データ
  const { supporterData } = props;
  const { alliliation, corporate_name, corporate_kana } = supporterData;

  if (alliliation === "0") {
    return (
      <Card>
        <Card.Header className={css.gfs_card_title}>
          <div className={`${css.registPanel_title} ${css.regist}`}>
            法人・個人<span className={css.require}>（必須）</span>
            <div className={css.gfs_right_pointer}>
              <IconClickable style={{ width: "20px", height: "15px" }} />
            </div>
          </div>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item className={css.gfs_inputedText}>
            <div>個人</div>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    );
  } else if (alliliation === "1") {
    return (
      <Card>
        <Card.Header className={css.gfs_card_title}>
          <div className={`${css.registPanel_title} ${css.regist}`}>
            法人・個人<span className={css.require}>（必須）</span>
            <div className={css.gfs_right_pointer}>
              <IconClickable style={{ width: "20px", height: "15px" }} />
            </div>
          </div>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item className={css.gfs_inputedText}>
            <div>法人</div>
          </ListGroup.Item>
          <ListGroup.Item className={css.gfs_inputedText}>
            <div>
              <span className={css["registPanel_label-s"]}>法人名</span>
            </div>
            <div>{corporate_name}</div>
          </ListGroup.Item>
          <ListGroup.Item className={css.gfs_inputedText}>
            <div>
              <span className={css["registPanel_label-s"]}>
                法人名（ひらがな）
              </span>
            </div>
            <div>{corporate_kana}</div>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    );
  } else {
    return (
      <div className={css.gfs_title_button}>
        <div className={`${css.registPanel_title} ${css.regist}`}>
          法人・個人<span className={css.require}>（必須）</span>
          <div className={css.gfs_right_pointer}>
            <IconClickable style={{ width: "20px", height: "15px" }} />
          </div>
        </div>
      </div>
    );
  }
});

export default PartsAlliliation;
