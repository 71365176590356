import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import EventView from "./comp_EventView";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import checkParameter from "../../components/providers/CheckUserInfo";

import type {
  Condition,
  MebukuEvent,
  Res,
} from "../../components/apiAccess/event/getEventAllDataAccess";
import { getEventAll } from "../../components/apiAccess/event/getEventAllDataAccess";
import { initialRes } from "../../components/apiAccess/event/getEventAllDataAccess";
import {
  ProfiledData,
  useGetProfile,
} from "../../components/apiAccess/profile/useGetProfile";
import {
  AccountInfo,
  useSetAccount,
} from "../../components/apiAccess/account/useSetAccount";
import { useExecuteActivate } from "../../components/apiAccess/event/useExecuteActivate";
import type { ConditionActivate } from "../../components/apiAccess/event/useExecuteActivate";
import type { UserInfoType } from "../../components/providers/types";
import css from "./Event.module.css";

function Event() {
  // 現在のページ
  const refPage = useRef<number>(0);
  // 読み込むページの有無
  const refHasMore = useRef<boolean>(true);
  // データ取得中フラグ
  const refIsLoading = useRef<boolean>(false);
  // レスポンス
  const [res, setRes] = useState<Res>(initialRes);
  // イベントリスト
  const [eventList, setEventList] = useState<MebukuEvent[]>();
  // クエリーパラメータを取得する
  const { search } = useLocation();
  // ログインユーザ情報
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  // ログインユーザ確定フラグ
  const [userFixed, setUserFixed] = useState<boolean>(false);
  // 参加するイベントID
  const [joinEventId, setJoinEventId] = useState<string>("");
  // プロフィール取得
  const { resultGetProfile, getProfile, isLoadingProfile, isErrorGetProrile } =
    useGetProfile();
  // プロフィール設定
  const { resultSetAccount, setAccount, isSendingAccount, isErrorSetAccount } =
    useSetAccount();
  // 新規ユーザか否か
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);
  // イベントに参加するカスタムフック
  const {
    resultActivate,
    executeActivate,
    isSendingActivate,
    isErrorActivate,
  } = useExecuteActivate();

  // 初回実行
  useEffect(() => {
    const queryParam = new URLSearchParams(search);
    const user = checkParameter(queryParam);

    if (
      user === null ||
      user === undefined ||
      user.id === null ||
      user.id === ""
    ) {
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert("パラメータが不足しています");
      console.log("ユーザー情報不足のため処理中断");
      return;
    } else {
      setUserInfo(user);
    }
    // プロフィール取得
    getProfile(user.id);
  }, []);

  // プロフィール取得結果が返ってきたとき
  useEffect(() => {
    if (resultGetProfile === undefined) return;
    if (resultGetProfile.result_code === "0") {
      // 正常のとき
      const user: UserInfoType = {
        id_kind: resultGetProfile.result.id_type,
        id: resultGetProfile.result.user_id,
        nickname: resultGetProfile.result.nickname,
        mail: resultGetProfile.result.mail_address,
        version: resultGetProfile.result.version,
        client_id: resultGetProfile.result.client_id,
      };
      setUserInfo(user);
      setUserFixed(true);

      console.log("ユーザー登録あり");
      // ユーザ登録済み
    } else if (
      resultGetProfile.result_code === "1" ||
      resultGetProfile.result_code === "9"
    ) {
      // 異常のとき
      console.log(resultGetProfile.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultGetProfile.message);

      // ユーザ未登録。新規登録する。
      setIsFirstTime(true);
      registUpdateUser();
      registCoopbbs();
    }
  }, [resultGetProfile]);

  // ユーザー情報の登録／更新
  const registUpdateUser = () => {
    const account: AccountInfo = {
      user_id: userInfo?.id!,
      id_type: userInfo?.id_kind!,
      nickname: userInfo?.nickname!,
      mail_address: userInfo?.mail!,
      version: userInfo?.version!,
      client_id: userInfo?.client_id!,
    };
    setAccount(account);
  };

  // ユーザー情報の登録／更新結果を受信したとき
  useEffect(() => {
    if (resultSetAccount === undefined || resultSetAccount === null) return;

    if (resultSetAccount.result_code === "0") {
      console.log("ユーザー情報の登録／更新 完了");
      if (isFirstTime) {
        // プロフィール取得
        getProfile(userInfo?.id!);
      }
    } else {
      console.log("ユーザー情報の登録／更新 失敗");
      console.log(resultSetAccount.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSetAccount.message);
    }
  }, [resultSetAccount]);

  // 助け合いに登録
  const registCoopbbs = () => {
    const conditionActivate: ConditionActivate = {
      account_id: userInfo?.id!,
      activation_cd: "",
      event_id: "2", // 助け合いのイベントID
    };
    executeActivate(conditionActivate);
  };

  // 助け合いに登録の結果を受信したとき
  useEffect(() => {
    if (resultActivate === null || resultActivate === undefined) return;

    if (resultActivate.result_code === "0") {
      console.log("助け合いに登録成功");
    }
  }, [resultActivate]);

  // 「このイベントに参加する」が成功したとき
  useEffect(() => {
    if (joinEventId === "") return;
    setJoinEventId("");

    setEventList((prev) =>
      prev?.map((obj) =>
        obj.event_id === joinEventId
          ? {
              ...obj,
              event_join_flg: "1",
            }
          : obj
      )
    );
  }, [joinEventId]);

  // 1ページ単位でデータを取得する
  const loadEvents = async () => {
    if (refIsLoading.current) return;

    if (userInfo === undefined || userInfo === null) {
      return;
    }

    if (!userFixed) {
      return;
    }

    refPage.current++;
    const cnd: Condition = {
      user_id: userInfo!.id,
      system_day: new Date().toLocaleString(),
      page: refPage.current,
    };

    refIsLoading.current = true;

    getEventAll(cnd)
      .then((response: any) => {
        console.dir(response.data);
        if (response.data.page === 1) {
          setRes(response.data);
          setEventList(response.data.result);
        } else {
          const tempResult: MebukuEvent[] = res.result.concat(
            response.data.result
          );
          setRes((prev) => ({
            ...prev,
            result: tempResult,
            page: response.data.page,
          }));
          setEventList(tempResult);
        }

        if (response.data.page === response.data.page_total) {
          refHasMore.current = false;
        }
        refIsLoading.current = false;
      })
      .catch((e) => {
        console.dir(e);
        process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
      });
  };

  return (
    <article className={`${css.content_all} ${css.event_back}`}>
      <div className={`${css.content_inner} ${css.article_margin}`}>
        <div className={css.event_block_wrap}>
          <div className={css.top_explain}>
            <span>
              イベントでは、現在参加可能なイベントの表示・参加申請を行うことができます。
            </span>
          </div>
          <div className={css.event_wrap}>
            <InfiniteScroll loadMore={loadEvents} hasMore={refHasMore.current}>
              <EventView
                eventList={eventList}
                setJoinEventId={setJoinEventId}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </article>
  );
}

export default Event;
