import React, { useContext, useEffect } from "react";
import {
  ConditionActivate,
  useExecuteActivate,
} from "../../components/apiAccess/event/useExecuteActivate";
import { UserInfoContext } from "../../components/providers/userInfoProvider";

import type { MebukuEvent } from "../../components/apiAccess/event/getEventAllDataAccess";

import css from "./Event.module.css";
import { Card } from "react-bootstrap";
import { EventPicture } from "./comp_EventPicture";

const EventView = (props: any) => {
  const { eventList, setJoinEventId } = props;
  const {
    resultActivate,
    executeActivate,
    isSendingActivate,
    isErrorActivate,
  } = useExecuteActivate();

  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  const handlerOnClickTitle = (e: any) => {
    const joinFlg = e.currentTarget.dataset.joinflg;
    if (joinFlg !== "0") {
      return;
    }

    if (userInfo === undefined || userInfo === null) {
      return;
    }
    const userId = userInfo.id;
    const eventId = e.currentTarget.dataset.eventid;
    const conditionActivate: ConditionActivate = {
      account_id: userId,
      activation_cd: "",
      event_id: eventId,
    };

    executeActivate(conditionActivate);
  };

  useEffect(() => {
    if (resultActivate?.result_code === "0") {
      setJoinEventId(resultActivate.event_id);
    }
  }, [resultActivate]);

  return (
    <>
      {eventList &&
        eventList.length > 0 &&
        eventList.event_name !== "" &&
        eventList.map((data: MebukuEvent) => {
          if (data.event_id === "2") {
            return null;
          } else {
            return (
              <div className={css.event_block} key={data.event_id}>
                <Card className={`${css.text_center} ${css["text-center"]}`}>
                  <Card.Header
                    className={
                      data.event_join_flg === "0"
                        ? css.event_part_state_blue
                        : css.event_part_state_green
                    }
                    onClick={handlerOnClickTitle}
                    data-joinflg={data.event_join_flg}
                    data-eventid={data.event_id}
                  >
                    {data.event_join_flg === "0"
                      ? "このイベントに参加する"
                      : "このイベントに参加中"}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title className={css.flex}>
                      <div className={css.event_title}>{data.event_name}</div>
                      <div className={css.event_logo}>
                        <EventPicture name={data.event_icon} />
                      </div>
                    </Card.Title>
                    <Card.Text className={css.event_summary}>
                      {data.event_desc_text}
                    </Card.Text>
                    <div className={css.event_detail}>
                      <div className={css.item_name}>開催日時</div>
                      <div className={css.item_name_text}>
                        {data.event_desc_date}
                      </div>
                      <hr />
                      <div className="item_name">参加料金</div>
                      <div className="item_name_text">
                        {data.event_desc_charge}
                      </div>
                      <hr />
                      <div className="item_name">開催場所</div>
                      <div className="item_name_text">
                        {data.event_desc_place}
                      </div>
                      <hr />
                      <div className="item_name">公式サイト</div>
                      <div className="item_name_text">
                        <a target="_blank" href={data.event_desc_url}>
                          {data.event_desc_url}
                        </a>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          }
        })}
    </>
  );
};

export default EventView;
