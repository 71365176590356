import React, { useState, useEffect } from "react";
import axios from "axios";

/*
const MAEBASHI_BOUNDS: google.maps.LatLngBoundsLiteral = {
  north: 36.5625, //  36°33′45″, 36.562500
  south: 36.31611, //  36°18′58″, 36.316110
  west: 139.00194, // 139°00′07″, 139.001940
  east: 139.23, // 139°13′48″, 139.230000
};
*/

// type Prediction = {
//   predictions: google.maps.places.AutocompletePrediction[] | null;
//   status: google.maps.places.PlacesServiceStatus;
// };

// 入力ワードから住所を検索
const Search = (props: any) => {
  console.log("Search is called.");
  const {
    searchWord,
    setSelectedPlace,
    service,
    apiKey,
    isResearch,
    setIsResearch,
  } = props;
  const [composition, setComposition] = useState(false);

  const deleteItems = () => {
    const results = document.getElementById("results") as HTMLDivElement;
    while (results.lastChild) {
      results.removeChild(results.lastChild);
    }
  };

  const showItems = () => {
    console.log("showItems is called.");
    const resultBox = document.getElementById("resultBox") as HTMLDivElement;
    resultBox.style.display = "block";
  };

  const hideItems = () => {
    const resultBox = document.getElementById("resultBox") as HTMLDivElement;
    resultBox.style.display = "none";
  };

  // service.getPlacePredictions に渡すコールバック関数
  const displaySuggestions = function (
    predictions: google.maps.places.AutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
  ) {
    if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
      console.log("status:" + status);
      process.env.REACT_APP_ALERT_ENABLE === "true" && alert(status);
      return;
    }

    if (predictions.length === 1 && isResearch) {
      // 検索結果が１件の場合
      setIsResearch(false);

      let addrArr: string[] = predictions[0].description.split(/[、, ]/);
      if (searchWord === addrArr[1]) {
        console.log("住所一致");
        const placeId = predictions[0].place_id;
        const geoUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;

        axios.get(geoUrl).then((res) => {
          const resultGeoCoding = res.data;
          const location: any = resultGeoCoding.results[0].geometry.location;
          console.log(`geoCoding lat: ${location.lat} lng: ${location.lng}`);

          setSelectedPlace({
            name: searchWord,
            addr: searchWord,
            lat: location.lat,
            lng: location.lng,
            placeId: placeId,
          });
        });
      }
    } else {
      // 検索結果が複数の場合
      deleteItems();
      showItems();

      const handlerOnClick = (e: React.PointerEvent<HTMLDivElement>) => {
        const { name, addr, placeId } = e.currentTarget.dataset;
        console.log("item clicked. " + name + " " + addr);
        const geoUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;

        axios.get(geoUrl).then((res) => {
          const resultGeoCoding = res.data;
          const location: any = resultGeoCoding.results[0].geometry.location;
          console.log(`geoCoding lat: ${location.lat} lng: ${location.lng}`);

          setSelectedPlace({
            name: name,
            addr: addr,
            lat: location.lat,
            lng: location.lng,
            placeId: placeId,
          });
        });

        hideItems();
        deleteItems();
      };

      const results = document.getElementById("results") as HTMLDivElement;
      predictions.forEach((prediction) => {
        const name: string = prediction.structured_formatting.main_text;
        let addrArr: string[] =
          prediction.structured_formatting.secondary_text.split(/[、, ]/);

        const addr: string = addrArr[1];
        const item = document.createElement("div");
        item.dataset.placeId = prediction.place_id;
        item.dataset.name = name;
        item.dataset.addr = addr;
        item.style.cssText = "padding: 5px";

        item.appendChild(document.createTextNode(name));

        const itemChild = document.createElement("div");
        itemChild.appendChild(document.createTextNode(addr));
        itemChild.style.cssText =
          "font-size: 12px; padding: 5px 5px 8px 30px; color: rgb(35, 35, 137)";
        item.appendChild(itemChild);

        // @ts-ignore
        item.addEventListener("click", handlerOnClick);
        item.style.fontSize = "14px";
        results.appendChild(item);
      });
    }
  };

  // 検索ワードが変化したとき
  useEffect(() => {
    if (service && searchWord && searchWord.trim() != "" && !composition) {
      const regexpSymbol =
        /[~`!@#\$%\^&\*\(\)\-\_=\+\{\}\[\]\\\|;:'",<\.>\/\?]/g;
      const regexpChar = /[a-zA-Z]/;

      let valueEdit = searchWord.replace(regexpSymbol, " ").trim();
      // console.log("valueEdit=" + valueEdit);

      if (
        (valueEdit.length === 1 && !valueEdit.match(regexpChar)) ||
        valueEdit.length > 1
      ) {
        service.getPlacePredictions(
          {
            input: valueEdit + " 群馬県前橋市",
          },
          displaySuggestions
        );
      }
    }
  }, [searchWord]);

  const styleResultBox = {
    border: "black",
    minHeight: "30px",
    borderStyle: "solid",
    borderWidth: "thin",
    margin: "10px",
    display: "none",
    width: "95%",
    backgroundColor: "#fff",
  };

  return (
    <div id="resultBox" style={styleResultBox}>
      <div id="results"></div>
    </div>
  );
};

export default Search;

export const reverseGeocoding = (props: any): void => {
  const { center, setValue } = props;
  const geocoder = new google.maps.Geocoder();

  geocoder
    .geocode({ location: center })
    .then((response: google.maps.GeocoderResponse) => {
      if (response.results[0]) {
        console.dir(response.results[0]);
        const formattedAddress = response.results[0].formatted_address;
        let addrArr: string[] = formattedAddress.split(/[、, /]/);
        const addr = addrArr[2];
        console.log("Reverse Geocoded addr: " + addr);
        setValue(addr);
        return addr;
      } else {
        window.alert("No results found");
      }
    })
    .catch((e) => console.log("Geocoder failed due to: " + e));
};
