import React, { memo } from "react";
import css from "./comp_TextForm.module.css";
import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";

const TextForm = memo((props: any) => {
  // 助け隊登録データ
  const { supporterData, setSupporterData } = props;

  return (
    <>
      <div className={css.registPanels_flex}>
        <div className={css.registTextbox}>
          <div className={css["registTextbox_title-wrap"]}>
            <div>
              <span className={css.title}>タイトル</span>
              <span className={css.require}>（必須）</span>
            </div>
            <div className={css.requireText}>30文字以内</div>
          </div>
          <textarea
            className={css.gfs_textarea_title}
            value={supporterData.title}
            onChange={(e) =>
              setSupporterData((prev: SupporterSetData) => ({
                ...prev,
                title: e.target.value,
              }))
            }
          />
        </div>
        <div className={css.registTextbox}>
          <div className={css["registTextbox_title-wrap"]}>
            <div>
              <span className={css.title}>内容と詳細</span>
              <span className={css.require}>（必須）</span>
            </div>
            <div className={css.requireText}>500文字以内</div>
          </div>
          <textarea
            className={css.gfs_textarea_sentence}
            value={supporterData.description}
            onChange={(e) =>
              setSupporterData((prev: SupporterSetData) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
        </div>
      </div>
    </>
  );
});

export default TextForm;
