import axios from "axios";
import { useState } from "react";
import GetApiUrl from "../getApiUrl";

// 助け隊登録のキャンセル
export const useCancelSupporter = () => {
    const [resultCancelSupporterRegist, setResultSupporterRegist] = useState<ResultData | undefined>();
    const [isLoadingCancelSupporterRegist, setIsLoadingSupporterRegist] = useState<boolean>(false);
    const [isErrorCancelSupporterRegist, setIsErrorSupporterRegist] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const sendCancelSupporterRegist = async (props: ConditionCancel) => {
        console.dir(props);
        setIsLoadingSupporterRegist(true);
        setIsErrorSupporterRegist(false);

        assistSvr.post("/thread/cancelSupporter", {
            ...props
        }).then((response) => {
            console.dir(response.data);
            setResultSupporterRegist(response.data);
        }).catch((e) => {
            setIsErrorSupporterRegist(true);
            process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
        }).finally(() => {
            setIsLoadingSupporterRegist(false);
        })
    };
    return { resultCancelSupporterRegist, sendCancelSupporterRegist, isLoadingCancelSupporterRegist, isErrorCancelSupporterRegist };
};

// 助け隊キャンセル
export type ConditionCancel = {
    id: number;       // 助け隊登録ID
    user_id: string;  // ログインユーザのめぶくID
}

export type ResultData = {
    result_code: string;    // 処理結果コード
    message: string;        // エラーメッセージ
};
