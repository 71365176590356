import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

// ■カスタムフック：めぶくEyeのJoin履歴取得用
export const useGetMebukuEyeAll = () => {
  // 取得結果を保存する変数
  const [resultGetMebukuEyeAll, setResultGetMebukuEyeAll] =
    useState<ResultData | undefined>();
  // 現在のデータ取得状態フラグ
  const [isLoadingMebukuEyeAll, setIsLoadingMebukuEyeAll] =
    useState<boolean>(false);
  // データ取得時のエラー発生フラグ
  const [isErrorGetMebukuEyeAll, setIsErrorGetMebukuEyeAll] =
    useState<boolean>(false);
  // 非同期通信用のオブジェクト取得
  const assistSvr = axios.create({
    baseURL: GetApiUrl(),
  });

  // API経由でめぶくEyeのJoin履歴を取得する関数
  const getMebukuEyeAll = useCallback(async (user_id: string) => {
    console.dir(user_id);
    setIsLoadingMebukuEyeAll(true);
    setIsErrorGetMebukuEyeAll(false);

    // APIへリクエスト開始
    await assistSvr
      .get(`/ext/join?user_id=${user_id}`)
      .then((response) => {
        console.dir(response.data);
        setResultGetMebukuEyeAll(response.data);
      })
      .catch((e) => {
        setIsErrorGetMebukuEyeAll(true);
        console.dir(e);
        process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
      })
      .finally(() => {
        setIsLoadingMebukuEyeAll(false);
      });
  }, []);
  return {
    resultGetMebukuEyeAll,
    getMebukuEyeAll,
    isLoadingMebukuEyeAll,
    isErrorGetMebukuEyeAll
  };
};

// 取得するめぶくEYEのJoin履歴（単体）のデータ型
export type MebukuEyeData = {
  id: string;
  joined_at: string;
  area: string;
  description: string;
};

// APIのレスポンスデータ型
export type ResultData = {
  result_code: string; // 値が"0"であれば成功
  message: string;
  result: MebukuEyeData[]; // 取得しためぶくEYEのJoin履歴を格納
  count: string;
};
