import { useContext, useEffect, useState } from "react";
import type { Confirm } from "../../components/dialogConfirm/types";
import css from "./comp_OpenOffer.module.css";

import type {
  ConditionSearchThanks,
  Thanks,
  thanksList,
} from "../../components/apiAccess/thread/searchThanksDataAccess";
import { searchThanks } from "../../components/apiAccess/thread/searchThanksDataAccess";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import AssistTitle from "../../components/assistTitle/AssistTitle";

const OpenOffer = (props: any) => {
  const {
    modeFirst,
    setModeFirst,
    modeSecond,
    setModeSecond,
    confirm,
    setConfirm,
  } = props;
  // ありが隊
  const [cardList, setCardList] = useState<thanksList>();
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // 初回レンダリング実行後
  useEffect(() => {
    console.log(
      "初回レンダリング実行後 ありが隊 : confirm.selectedId=" +
        confirm.selectedId
    );
    const condition: ConditionSearchThanks = {
      user_id: userInfo?.id!,
      owner_class: 1,
      matching_class: 1,
      id: modeSecond.selectedId,
      order_column: 0,
      order: 0,
      page: 1,
    };

    // ありが隊取得
    searchThanks(condition).then((response: any) => {
      console.dir(response.data);
      setCardList(response.data);
    });
  }, [confirm]);

  // 「この人を助ける」をタップしたとき
  const handlerOnClickSupport = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.offerid?.split("_")?.[0];
    const cnf: Confirm = {
      isVisible: true,
      type: "SUPPORT",
      answer: "UNDONE",
      recordId: Number(selectedId),
      userId: e.currentTarget.dataset.userid,
    };
    setConfirm(cnf);
  };

  // 「今回はお断りする」をタップしたとき
  const handlerOnClickRefusal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const selectedId = e.currentTarget.dataset.offerid?.split("_")?.[0];
    const cnf: Confirm = {
      isVisible: true,
      type: "REFUSAL",
      answer: "UNDONE",
      recordId: Number(selectedId),
      userId: e.currentTarget.dataset.userid,
    };
    setConfirm(cnf);
  };

  return (
    <div className={css.coopbbs_back}>
      <AssistTitle
        modeFirst={modeFirst}
        setModeFirst={setModeFirst}
        modeSecond={modeSecond}
        setModeSecond={setModeSecond}
      />
      <div className={css.coopbbs_block_wrap}>
        <div className={css.introductionTitle}>ありが隊紹介</div>
        {cardList &&
          cardList.result.length > 0 &&
          cardList.result.map((card: Thanks) => {
            return (
              <div className={css.requesterIntro} key={card.id}>
                <div className={css["requester_nameProfile"]}>
                  {/* {!card.isRead && (
                  <span className={css["requester-unread"]}>未読</span>
                )} */}
                  {/* <div className={css["requester_nameIcon"]}>
                  <img
                    src={"data:image/jpg;base64," + card.image}
                    width="80"
                    height="80"
                    alt=""
                  />
                </div> */}
                  <span>{card.nickname}</span>
                </div>
                <div className={css.introductionText}>{card.introduction}</div>
                <div className={css.commentToSupporter}>
                  <div className={css["commentToSupporter-text"]}>
                    <span className={css["commentToSupporter-sublabel"]}>
                      助け隊へのコメント
                    </span>
                    <div className={`${css.mg_t_20} ${css.mg_b_10}`}>
                      {card.comment}
                    </div>
                    <span className={css["commentToSupporter-sendDate"]}>
                      送信日
                      <br />
                      {card.request_datetime}
                    </span>
                  </div>
                </div>
                <div className={`${css["coopbbsButton_wrap"]} ${css.column}`}>
                  <div
                    className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l} ${css.clickable}`}
                    onClick={handlerOnClickSupport}
                    data-offerid={card.id}
                    data-userid={card.supporter_id}
                  >
                    この人を助ける
                  </div>

                  <div
                    className={`${css.coopbbsButton} ${css.color_white} ${css.size_l} ${css.clickable}`}
                    onClick={handlerOnClickRefusal}
                    data-offerid={card.id}
                    data-userid={card.supporter_id}
                  >
                    今回はお断りする
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default OpenOffer;
