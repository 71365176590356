import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useGetSupporter = () => {
    const [resultGetSupporter, setResultGetSupporter] = useState<ResultData | undefined>();
    const [isLoadingSupporter, setIsLoadingSupporter] = useState<boolean>(false);
    const [isErrorGetSupporter, setIsErrorGetSupporter] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const getSupporter = useCallback(async (props: any) => {
        console.dir(props);
        const { user_id, id } = props;
        setIsLoadingSupporter(true);
        setIsErrorGetSupporter(false);

        const url = `/thread/getSupporter?user_id=${user_id}&id=${id}`;
        assistSvr
            .get(url)
            .then((response) => {
                console.dir(response.data);
                setResultGetSupporter(response.data);
            })
            .catch((e) => {
                setIsErrorGetSupporter(true);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsLoadingSupporter(false);
            });
    }, []);

    return { resultGetSupporter, getSupporter, isLoadingSupporter, isErrorGetSupporter };
};


// 助け隊登録情報の取得条件
export type ConditionGetSupporter = {
    user_id: string;  // ログインユーザのめぶくID
    id: number;       // 助け隊登録ID
}

export type SupporterGetData = {
    id: number;                 // レコードID
    supporter_id: string;       // 対象のめぶくID	
    nickname: string;           // 対象のニックネーム
    introduction: string;       // 対象の自己紹介	
    title: string;              // タイトル	
    description: string;        // 内容		
    area_choice: string[];      // 選択町名（町名複数か「前橋市全域」のいずれか）
    area_sentense: string;      // 活動地域	
    supporter_date: string;     // YYYY年M月D日（曜）
    begin_time: string;         // 開始時刻（hh:mm）
    end_time: string;           // 終了時刻（hh:mm）
    alliliation: string;        // 0：個人　1：法人	
    category: string[];         // カテゴリー	
    target: string[];           // 助けられる対象	
    appoint: string[];          // 待ち合わせ場所	
    corporate_name: string;     // 法人名	
    corporate_kana: string;     // 法人名（ふりがな）
    route_from: string;         // 出発地
    route_to: string;           // 到着地	
    number_peoples: string;     // 未読件数
    created_at: string;         // 登録日時（フォーマット： YYYY年 M月D日（曜）hh:mm）
};

export type ResultData = {
    result_code: string;        // 0:正常　1:異常　9:システムエラー
    message: string;            // エラーメッセージ
    result: SupporterGetData[];  // サポーター詳細 
};
