import { UserInfoType } from "./types";

const checkParameter = (param: URLSearchParams) => {
    const id: string = param.get("id")!;
    const id_kind: string = param.get("id_kind")!;
    const nickname: string = param.get("nickname")!;
    const mail: string = param.get("mail")!;
    const version: string = param.get("version")!;
    const client_id: string = param.get("client_id")!;

    console.log("id: " + id);
    console.log("id_kind: " + id_kind);
    console.log("nickname: " + nickname);
    console.log("mail: " + mail);
    console.log("version: " + version);
    console.log("client_id: " + client_id);

    if (
        id === null ||
        id_kind === null ||
        nickname === null ||
        version === null) {
        return null;
    }

    const user: UserInfoType = {
        id_kind: id_kind,
        id: id,
        nickname: nickname,
        mail: (mail === null) ? "" : mail,
        version: version,
        client_id: (client_id === null) ? "" : client_id,
    };
    return user;
}

export default checkParameter;