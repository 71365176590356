import css from "./comp_AssistDetail.module.css";

const AssistDetail = (props: any) => {
  const { detail, userId } = props;

  return (
    <>
      {detail && (
        <div className={css.detailPanel_wrap}>
          <div
            className={`${css.detailPanel_title} ${
              userId === detail.supporter_id ? css.my_help : css.myrequest
            }`}
          >
            {detail?.title}
          </div>
          <div className={css.detailPanel_block}>
            <div className={css.detailPanel_content}>{detail?.description}</div>
          </div>
          <hr className={css.detailPanel_hr} />
          <div className={css.detailPanel_block}>
            <div className={css.detailPanel_item}>活動エリア</div>
            <div className={css.detailPanel_content}>
              {detail?.area_sentense}
              <br />
              {detail?.area_choice.join("、")}
            </div>
          </div>
          <hr className={css.detailPanel_hr} />
          <div className={css.detailPanel_block}>
            <div className={css.detailPanel_item}>日時</div>
            <div className={css.detailPanel_content}>
              {detail?.supporter_date}&emsp;{detail?.begin_time}
              <span>〜</span>
              {detail?.end_time}
            </div>
          </div>
          <hr className={css.detailPanel_hr} />
          <div className={css.detailPanel_block}>
            <div className={css.detailPanel_item}>カテゴリー</div>
            <div className={css.detailPanel_content}>
              {detail?.category.join("、")}
            </div>
          </div>
          <hr className={css.detailPanel_hr} />
          <div className={css.detailPanel_block}>
            <div className={css.detailPanel_item}>個人・法人</div>
            <div className={css.detailPanel_content}>
              {detail?.alliliation === "0" && <>個人</>}
              {detail?.alliliation === "1" && (
                <>
                  <>法人</>
                  <br />
                  {detail?.corporate_name}
                  <br />
                  {detail?.corporate_kana}
                </>
              )}
            </div>
          </div>
          <hr className={css.detailPanel_hr} />
          <div className={css.detailPanel_block}>
            <div className={css.detailPanel_item}>行き先ルート・目的地</div>
            <div className={css.detailPanel_content}>
              <span>出発</span>
              <br />
              {detail?.route_from}
              <br />
              <span>到着</span>
              <br />
              {detail?.route_to}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssistDetail;
