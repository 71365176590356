import React, { memo, SetStateAction, Dispatch, useEffect } from "react";
import css from "./comp_KanaColum.module.css";
// import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";
import { SelectMode, TownNameList } from "./types";

interface KanaColumProps {
  townNameList: TownNameList;
  townNames: string[];
  setTownNames: Dispatch<SetStateAction<string[]>>;
  // areaChoice: string[];
  // setAreaChoice: Dispatch<SetStateAction<string[]>>;
  // supporterData: SupporterSetData;
  // setSupporterData: Dispatch<SetStateAction<SupporterSetData>>;
  selectMode: SelectMode;
}

const KanaColum = memo((props: KanaColumProps) => {
  const { townNameList, townNames, setTownNames, selectMode } = props;

  useEffect(() => {
    setTownNames(townNameList.a_gyo);
  }, []);

  return (
    <>
      <div
        className={`${
          selectMode === "entireCity"
            ? "gfs_town_name_not_select"
            : "gfs_town_name_select"
        }`}
      >
        <div className={css["registTextbox_title-wrap"]}>
          <div className={css.requireText}>複数選択可</div>
        </div>
        <div className={css["registInput_kanaColumn-wrap"]}>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.a_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.a_gyo);
            }}
          >
            あ行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.ka_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.ka_gyo);
            }}
          >
            か行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.sa_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.sa_gyo);
            }}
          >
            さ行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.ta_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.ta_gyo);
            }}
          >
            た行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.na_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.na_gyo);
            }}
          >
            な行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.ha_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.ha_gyo);
            }}
          >
            は行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.ma_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.ma_gyo);
            }}
          >
            ま行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.ya_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.ya_gyo);
            }}
          >
            や行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.ra_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.ra_gyo);
            }}
          >
            ら行
          </span>
          <span
            className={`${css.registInput_kanaColumn} ${
              townNames === townNameList.wa_gyo ? css.current : ""
            }`}
            onClick={() => {
              setTownNames(townNameList.wa_gyo);
            }}
          >
            わ行
          </span>
        </div>
      </div>
    </>
  );
});

export default KanaColum;
