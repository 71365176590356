import axios from "axios";
import GetApiUrl from "../getApiUrl";

const assistSvr = axios.create({
    baseURL: GetApiUrl(),
});

export const getJoinHist = async (props: any) => {
    const { account_id, page } = props
    console.log("called: getJoinHist page= " + page);
    console.dir(props);

    const url = `/passport/getJoinHist?account_id=${account_id}&page=${page}`;
    return await assistSvr.get(url).then((response) => {
        console.dir(response.data);
        return response;
    });
}

export type ConditionGetJoinHist = {
    account_id: string;
    page: number;
}

export type Join = {
    account_id: string;
    event_id: string;
    event_name: string;
    from_account_id: string;
    from_nickname: string;
    to_account_id: string;
    to_nickname: string;
    join_class: string;
    description: string;
}

export type LifeLog = {
    joined_at: string;
    join: Join[]
};

export type Res = {
    result_code: string;
    message: string;
    result: LifeLog[];
    count: number;
    page: number;
    page_total: number;
};

export const initialRes = {
    result_code: "",
    message: "",
    result: [],
    count: 0,
    page: 0,
    page_total: 0
};