import { useEffect, useState } from "react";
import type { Confirm, ConfirmType } from "../dialogConfirm/types";
import type { ModeSecond } from "../commonTypes/types";
import AssistDetail from "./comp_AssistDetail";

import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconStatusMsgSmile from "../../../../img/icon_status_msg_smile.svg";
import iconModify from "../../../../img/icon_modify.svg";
import iconCancel from "../../../../img/icon_cancel.svg";
import css from "./Assist.module.css";
import type {
  ConditionGetSupporter,
  SupporterGetData,
} from "../apiAccess/thread/useGetSupporter";
import { useGetSupporter } from "../apiAccess/thread/useGetSupporter";
import AssistTitle from "../assistTitle/AssistTitle";

const Assist = (props: any) => {
  const {
    userId,
    selectedId,
    modeFirst,
    setModeFirst,
    modeSecond,
    setModeSecond,
    setConfirm,
  } = props;

  const {
    resultGetSupporter,
    getSupporter,
    isLoadingSupporter,
    isErrorGetSupporter,
  } = useGetSupporter();

  const [detail, setDetail] = useState<SupporterGetData>();

  // 初回レンダリング実行後
  useEffect(() => {
    const condition: ConditionGetSupporter = {
      user_id: userId,
      id: selectedId,
    };
    // 助け隊を取得
    getSupporter(condition);
  }, []);

  // 助け隊が取得できたとき
  useEffect(() => {
    if (resultGetSupporter === undefined || resultGetSupporter === null) return;

    if (resultGetSupporter.result_code === "0") {
      setDetail(resultGetSupporter.result[0]);
    } else if (
      resultGetSupporter.result_code === "1" ||
      resultGetSupporter.result_code === "9"
    ) {
      console.log(resultGetSupporter.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultGetSupporter.message);
    }
  }, [resultGetSupporter]);

  // 「この申し込みを修正する」がタップされた時
  const handlerOnClickEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const mode: ModeSecond = {
      code: "EDIT",
      selectedId: selectedId,
      fromCode: "DETAIL",
      fromSelectedId: selectedId,
    };
    setModeSecond(mode);
  };

  //  「この登録を取りやめる」／「この申し込みを取りやめる」がタップされた時
  const handlerOnClickCancel = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    let type: ConfirmType = "REGIST_CANCEL";
    if (modeFirst.class === "REQUEST") {
      type = "OFFER_CANCEL";
    }

    const cnf: Confirm = {
      isVisible: true,
      type: type,
      answer: "UNDONE",
      recordId: selectedId,
    };
    setConfirm(cnf);
  };

  // 「申し込んたありが隊」がタップされた時
  const handlerOnClickOffer = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const mode: ModeSecond = {
      code: "OFFER",
      selectedId: selectedId,
      fromCode: "DETAIL",
      fromSelectedId: selectedId,
    };
    setModeSecond(mode);
  };

  return (
    <>
      <div className={css.coopbbs_back}>
        <AssistTitle
          modeFirst={modeFirst}
          setModeFirst={setModeFirst}
          modeSecond={modeSecond}
          setModeSecond={setModeSecond}
        />
        <div className={css.boardPanel_container}>
          {!isLoadingSupporter &&
            modeFirst.code === "OPEN" &&
            userId === detail?.supporter_id && (
              <>
                <div
                  className={`${css.button_action} ${css.clickable}`}
                  onClick={handlerOnClickOffer}
                >
                  <div className={css.button_action_label}>
                    <img src={iconStatusMsgSmile} alt="" />
                    <span>申し込んだありが隊</span>
                  </div>
                  <div
                    className={`${
                      Number(detail?.number_peoples) > 0
                        ? css["boardPanel_status-numtrue"]
                        : css["boardPanel_status-numzero"]
                    }`}
                  >
                    {detail?.number_peoples}
                    <span className="text_12">名</span>
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={css.faAngleRight}
                  />
                </div>
              </>
            )}

          {!isLoadingSupporter && (
            <AssistDetail
              detail={detail}
              // isLoading={isLoadingSupporter}
              userId={userId}
            />
          )}

          {!isLoadingSupporter && modeFirst.code === "OPEN" && (
            <>
              {userId === detail?.supporter_id && (
                <div
                  className={`${css.button_action} ${css.clickable}`}
                  onClick={handlerOnClickEdit}
                >
                  <div className={css.button_action_label}>
                    <img src={iconModify} alt="" />
                    <span>内容を修正する</span>
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={css.faAngleRight}
                  />
                </div>
              )}
              <div
                className={`${css.button_action} ${css.clickable}`}
                onClick={handlerOnClickCancel}
              >
                <div className={css.button_action_label}>
                  <img src={iconCancel} alt="" />
                  <span>
                    {modeFirst.class === "ASSIST"
                      ? "この登録を取りやめる"
                      : "この申し込みを取りやめる"}
                  </span>
                </div>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={css.faAngleRight}
                />
              </div>
            </>
          )}

          {!isLoadingSupporter && (
            <div className={css.time_stamp}>
              登録日
              <br />
              {detail?.created_at}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Assist;
