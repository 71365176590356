import { Dispatch, SetStateAction, memo } from "react";
import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";
import css from "./comp_FreewordInputForm.module.css";

interface FreewordInputFormProps {
  areaSentense: string;
  setAreaSentense: Dispatch<SetStateAction<string>>;
}
const FreewordInputForm = memo((props: FreewordInputFormProps) => {
  const { areaSentense, setAreaSentense } = props;
  return (
    <div>
      <div className={css["registTextbox_title-wrap"]}>
        <div>
          <span className={css.title}>フリーワード</span>
        </div>
        <div className={css.requireText}>200文字以内</div>
      </div>
      <div>
        <div className={css.registTextbox}>
          <textarea
            style={{ height: 150 }}
            className=""
            placeholder="「ほぼ前橋市全域」や、「千代田町から車で30分以内」など、活動できるエリアをフリーワードで記入してください。"
            onChange={(event) =>
              setAreaSentense((prev: string) => event.target.value)
            }
            value={areaSentense}
          ></textarea>
        </div>
      </div>
    </div>
  );
});

export default FreewordInputForm;
