export const CategoryData = {
  category: [
    "日常の代行",
    "譲ります",
    "付き添い",
    "ペット",
    "趣味・習い事",
    "生活の相談",
    "コミュニケーション",
    "その他",
  ],
  target: ["社会人", "中学・高校生", "大学生", "お年寄り", "その他"],
  appoint: [
    "カフェ・喫茶店",
    "公園",
    "駅周辺・駅内",
    "公共施設",
    "その他屋内施設",
    "野外施設",
  ],
};
