import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import type { Confirm } from "../../components/dialogConfirm/types";
import { initialConfirm } from "../../components/dialogConfirm/initials";
import DialogConfirm from "../../components/dialogConfirm/DialogConfirm";

import backArrow from "../../../../img/backArrow.svg";
import nextArrowWhite from "../../../../img/nextArrow-white.svg";
import leftArrowPink from "../../../../img/leftArrow-pink.svg";
import css from "./OthersAssist.module.css";

import type { ConditionRequest } from "../../components/apiAccess/thread/useRequestThanks";
import { useRequestThanks } from "../../components/apiAccess/thread/useRequestThanks";
import type {
  ConditionGetSupporter,
  SupporterGetData,
} from "../../components/apiAccess/thread/useGetSupporter";
import { useGetSupporter } from "../../components/apiAccess/thread/useGetSupporter";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import ErrorModalOverWordCountFiveHundred from "../../components/ErrorModal/ErrorModalOverWordCountFiveHundred";
import ErrorModalSensitiveWordAssistRegist from "../../components/ErrorModal/ErrorModalSensitiveWordAssistRegist";
import ErrorModalSensitiveWord from "../../components/ErrorModal/ErrorModalSensitiveWord";
import { useCheckNGWord } from "../../components/apiAccess/master/useCheckNGWord";

const OthersAssist = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [id] = useState<number>(location.state.id as number);

  // 助け隊取得
  const {
    resultGetSupporter,
    getSupporter,
    isLoadingSupporter,
    isErrorGetSupporter,
  } = useGetSupporter();
  const { resultRequest, sendRequest, isLoadingRequest, isErrorRequest } =
    useRequestThanks();
  const [confirm, setConfirm] = useState<Confirm>(initialConfirm);
  const refMessage = useRef<HTMLTextAreaElement>(null!);
  // const [isOverChars, setIsOverChars] = useState<boolean>(false);
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);
  // 文字数超えのモーダル
  const [showErrorModalOverWord, setShowErrorModalOverWord] =
    useState<boolean>(false);
  // NGワードのモーダル
  const [showErrorModalSensitiveWord, setShowErrorModalSensitiveWord] =
    useState<boolean>(false);
  // NGワードチェックのカスタムフック
  const {
    resultCheckNgWord,
    checkNgWord,
    isLoadingCheckNgWord,
    isErrorCheckNgWord,
  } = useCheckNGWord();

  // 助け隊データ
  const [supporter, setSupporter] = useState<SupporterGetData>();

  useEffect(() => {
    const condition: ConditionGetSupporter = {
      user_id: userInfo?.id!,
      id: id,
    };

    getSupporter(condition);
  }, []);

  // 助け隊データ
  useEffect(() => {
    if (resultGetSupporter === undefined || resultGetSupporter === null) return;

    if (
      resultGetSupporter.result_code === "0" &&
      resultGetSupporter.result.length > 0
    ) {
      setSupporter(resultGetSupporter.result[0]);
    }
  }, [resultGetSupporter]);

  // 戻る or 「一覧に戻る」がタップされたとき
  const handlerOnClickBackArrow = () => {
    // navigate("../othersAssistList");
    navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistList");
  };

  // 「ありが隊として申し込む」がタップされたとき
  const handlerOnClickOffer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const comment = refMessage.current.value;
    // 文字数チェック
    if (comment.length > 500) {
      setShowErrorModalOverWord(true);
      return;
    }

    // NGワードチェック
    checkNgWord({
      user_id: userInfo?.id!,
      description: comment,
    });
  };

  // NGワードチェックの結果を受信したとき
  useEffect(() => {
    if (resultCheckNgWord === undefined || resultCheckNgWord === null) return;

    if (resultCheckNgWord.result_code === "0") {
      // ありが隊申し込みのダイアログを表示する;
      const cnf: Confirm = {
        isVisible: true,
        type: "REQUEST",
        answer: "UNDONE",
        recordId: id,
      };
      setConfirm(cnf);
    } else if (
      resultCheckNgWord.result_code === "1" ||
      resultCheckNgWord.result_code === "9"
    ) {
      console.log(resultCheckNgWord.message);
    } else if (resultCheckNgWord.result_code === "2") {
      // NGワードダイアログを表示する
      setShowErrorModalSensitiveWord(true);
    }
  }, [resultCheckNgWord]);

  // ダイアログの「YES/NO」を受けたとき
  useEffect(() => {
    if (confirm.answer === "YES") {
      const comment = refMessage.current.value;

      const condition: ConditionRequest = {
        user_id: userInfo?.id!,
        id: resultGetSupporter?.result[0].id!,
        comment: comment,
      };
      // ありが隊リクエストを送信する
      sendRequest(condition);
    } else if (confirm.answer === "NO") {
      setConfirm(initialConfirm);
    }
  }, [confirm]);

  // ありがたいリクエストの送信結果を受信したとき
  useEffect(() => {
    if (resultRequest === undefined || resultRequest === null) return;

    if (resultRequest && resultRequest.result_code === "0") {
      // 自分のありがたい一覧
      navigate(process.env.REACT_APP_PATH_PREFIX + "othersAssistMe", {
        state: {
          modeFirst: {
            class: "REQUEST",
            code: "OPEN",
          },
        },
      });
    } else if (
      resultRequest.result_code === "1" ||
      resultRequest.result_code === "9"
    ) {
      console.log(resultRequest.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultRequest.message);
    }
    setConfirm(initialConfirm);
  }, [resultRequest]);

  return (
    <>
      <article className={`${css.content_all} ${css.event_back}`}>
        <div className={`${css.content_inner} ${css.article_margin}`}>
          <div
            className={`${css.pageTitle} ${css["pageTitle_color-everyones"]}`}
          >
            <div className={css.backArrow} onClick={handlerOnClickBackArrow}>
              <img src={backArrow} width="23" alt="" />
            </div>
            みんなの助け隊一覧
          </div>
          <>
            {isLoadingSupporter && <div style={{ padding: "20px" }}>.</div>}
            {!isLoadingSupporter &&
              resultGetSupporter &&
              resultGetSupporter.result[0] && (
                <div className={css.coopbbs_block_wrap}>
                  <div className={css.introductionTitle}>助け隊紹介</div>
                  <div className={css.requesterIntro}>
                    <div className={css.requester_nameProfile}>
                      <span>{resultGetSupporter?.result[0].nickname}</span>
                    </div>
                    <div className={css.introductionText}>
                      {resultGetSupporter?.result[0].introduction}
                    </div>
                  </div>
                  <div className={css.introductionTitle_help}>助け隊内容</div>
                  <div className={`${css.detailPanel_wrap} ${css.help}`}>
                    <div className={css.detailPanel_title}>
                      {resultGetSupporter?.result[0].title}
                    </div>
                    <div className={css.detailPanel_block}>
                      <div className={css.detailPanel_content}>
                        {resultGetSupporter?.result[0].description}
                      </div>
                    </div>
                    <hr className={css.detailPanel_hr} />
                    <div className={css.detailPanel_block}>
                      <div className={css.detailPanel_item}>活動エリア</div>
                      <div className={css.detailPanel_content}>
                        {resultGetSupporter?.result[0].area_sentense}
                        <br />
                        {resultGetSupporter?.result[0].area_choice.join("、")}
                      </div>
                    </div>
                    <hr className={css.detailPanel_hr} />
                    <div className={css.detailPanel_block}>
                      <div className={css.detailPanel_item}>日時</div>
                      <div className={css.detailPanel_content}>
                        {resultGetSupporter?.result[0].supporter_date}{" "}
                        {resultGetSupporter?.result[0].begin_time}〜
                        {resultGetSupporter?.result[0].end_time}
                      </div>
                    </div>
                    <hr className={css.detailPanel_hr} />
                    <div className={css.detailPanel_block}>
                      <div className={css.detailPanel_item}>カテゴリー</div>
                      <div className={css.detailPanel_content}>
                        {resultGetSupporter?.result[0].category.join("、")}
                      </div>
                    </div>
                    <hr className={css.detailPanel_hr} />
                    <div className={css.detailPanel_block}>
                      <div className={css.detailPanel_item}>個人・法人</div>
                      <div className={css.detailPanel_content}>
                        {resultGetSupporter?.result[0].alliliation === "0" && (
                          <>個人</>
                        )}
                        {resultGetSupporter?.result[0].alliliation === "1" && (
                          <>
                            <>法人</>
                            <br />
                            {resultGetSupporter.result[0].corporate_name}
                            <br />
                            {resultGetSupporter.result[0].corporate_kana}
                          </>
                        )}
                      </div>
                    </div>
                    <hr className={css.detailPanel_hr} />
                    <div className={css.detailPanel_block}>
                      <div className={css.detailPanel_item}>
                        行き先ルート・目的地
                      </div>
                      <div className={css.detailPanel_content}>
                        <span>出発</span>
                        <br />
                        {resultGetSupporter?.result[0].route_from}
                        <br />
                        <span>到着</span>
                        <br />
                        {resultGetSupporter?.result[0].route_to}
                      </div>
                    </div>
                  </div>
                  <div className={css.time_stamp}>
                    登録日
                    <br />
                    {resultGetSupporter?.result[0].created_at}
                  </div>
                  <div className={css.introductionTitle_help}>
                    ありが隊としてコメントを送れます
                  </div>
                  <div className={css.registTextbox}>
                    <div className={css["registTextbox_title-wrap"]}>
                      <span className={css.requireText}>500文字以内</span>
                    </div>
                    <textarea
                      rows={10}
                      cols={30}
                      className={css.borderNormal}
                      placeholder="助け隊への質問、ありが隊としてお願いしたい理由、内容、場所、時間などを記入してください。助け隊がより詳しい情報が必要な場合は、チャットで連絡がきます。"
                      ref={refMessage}
                    ></textarea>
                  </div>
                  <div className={css.mg_t_30}></div>
                  <div className={`${css.coopbbsButton_wrap} ${css.column}`}>
                    <button
                      className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
                      onClick={handlerOnClickOffer}
                    >
                      ありが隊として申し込む
                      <img
                        src={nextArrowWhite}
                        className={css.coopbbsButton_arrow_right}
                        width="23"
                        alt=""
                      />
                    </button>
                    <button
                      className={`${css.coopbbsButton} ${css.color_white} ${css.size_l}`}
                      onClick={handlerOnClickBackArrow}
                    >
                      <img
                        src={leftArrowPink}
                        className={css.coopbbsButton_arrow_left}
                        width="23"
                        alt=""
                      />
                      一覧に戻る
                    </button>
                  </div>
                </div>
              )}
          </>
        </div>
      </article>

      {/* ダイアログ */}
      {confirm.isVisible && (
        <DialogConfirm confirm={confirm} setConfirm={setConfirm} />
      )}
      <ErrorModalOverWordCountFiveHundred
        showFlag={showErrorModalOverWord}
        setShowModalOverWord={setShowErrorModalOverWord}
      />
      <ErrorModalSensitiveWord
        showFlag={showErrorModalSensitiveWord}
        setShowModalSensitiveWord={setShowErrorModalSensitiveWord}
      />
    </>
  );
};

export default OthersAssist;
