import { useContext, useEffect, useRef, useState } from "react";
import css from "./comp_OpenList.module.css";

import {
  initialSupportList,
  searchSupportList,
} from "../../components/apiAccess/thread/searchSupporterDataAccess";
import type {
  ConditionSupportList,
  Support,
  SupportList,
} from "../../components/apiAccess/thread/searchSupporterDataAccess";

import { UserInfoContext } from "../../components/providers/userInfoProvider";
import MyAssistOpenItems from "./comp_OpenItems";
import InfiniteScroll from "react-infinite-scroller";
import AssistTitle from "../../components/assistTitle/AssistTitle";

// 自分の助け隊一覧（オープン）
const OpenList = (props: any) => {
  const { modeFirst, setModeFirst, modeSecond, setModeSecond, setConfirm } =
    props;
  // 助け隊一覧データ
  const [supportList, setSupportList] =
    useState<SupportList>(initialSupportList);
  // 現在のページ
  const refPage = useRef<number>(0);
  // 読み込むページの有無
  const refHasMore = useRef<boolean>(true);
  // データ取得中フラグ
  const refIsLoading = useRef<boolean>(false);
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  const [condition, setCondition] = useState<ConditionSupportList>({
    user_id: userInfo?.id!,
    owner_class: 0,
    // support_date: "",
    // time_from: "",
    // category: [],
    // target: [],
    // appoint: [],
    // alliliation: 0,
    page: 1,
    recruitment_flag: 0,
  });

  // 無限スクロールコンテナの高さ
  const [containerHeight, setContainerHeight] = useState({});

  // 初回レンダリング実行時 オープン
  useEffect(() => {
    console.log("「自分の助け隊」オープン 初回レンダリング実行");
    console.log("window.innerHeight = " + window.innerHeight);

    // 無限スクロールコンテナの高さを設定
    setContainerHeight({
      height: window.innerHeight + "px",
    });
  }, []);

  // 助け隊取得
  const fetch = (cnd: ConditionSupportList) => {
    refIsLoading.current = true;

    searchSupportList(cnd)
      .then((response: any) => {
        if (response.data.page === "1") {
          setSupportList(response.data);
        } else {
          const tempList: Support[] = supportList?.result.concat(
            response.data.result
          );
          setSupportList((prev) => ({
            ...prev,
            result: tempList,
            page: response.data.page,
          }));
        }

        if (response.data.page === response.data.page_total) {
          refHasMore.current = false;
        }
        refIsLoading.current = false;
      })
      .catch((e) => {
        console.dir(e);
        process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
      });
  };

  // 1ページ単位でデータを取得する
  const loadSupport = async () => {
    console.log("called: loadSupport funtcion.");
    if (refIsLoading.current) return;

    if (userInfo === undefined || userInfo === null) {
      return;
    }

    refPage.current++;
    const cnd: ConditionSupportList = {
      ...condition,
      page: refPage.current,
    };
    fetch(cnd);
  };

  return (
    <>
      <div className={css.boardPanel_outer_container} style={containerHeight}>
        <AssistTitle
          modeFirst={modeFirst}
          setModeFirst={setModeFirst}
          modeSecond={modeSecond}
          setModeSecond={setModeSecond}
        />
        <InfiniteScroll
          loadMore={loadSupport}
          hasMore={refHasMore.current}
          useWindow={false}
        >
          <MyAssistOpenItems
            list={supportList}
            setModeSecond={setModeSecond}
            setConfirm={setConfirm}
          />
        </InfiniteScroll>
      </div>
    </>
  );
};

export default OpenList;
